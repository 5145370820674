const colors = {
    mediSecondaryGray: "#e9edf0",
    mediLightGray: "#d7dde2",
    mediDarkGray: "#333f48",
    mediGray: "#48586a",
    mediMagenta: "#e6007e",
    mediMagentaDisabled: "#e6b8d1",
    error: "#ff0000",
    mediRed: "#fe1f1f",
    mediGreen: "#8ffe16",
    mediBlue: "#91b1be",
    warning: "#ed8b00",
    success: "#8ea40d",
    white: "#ffffff"

};

export default colors;
