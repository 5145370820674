import * as React from "react";
import {useEffect, useState} from "react";
import * as styleBreadcrumbs from "../../../../shared/breadcrumbs/breadcrumbs_root.style";
import {Container} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../../../../app/hook";
import {AppDispatch} from "../../../../app/redux_store";
import {getProductionOrder} from "../redux/configurations_productionorder.thunks";
import {StringParam, useQueryParam} from "use-query-params";
import { useNavigate } from "react-router-dom";
import {setConfigurationId} from "../redux/configurations_productionorder.slice";
import {useTranslation} from "react-i18next";
import {
    BackToConfigurationOverviewButton,
    ConfigurationProductionOrderContentContainer
} from "./configuration_productionorder.style";
import {
    LoadingOverlayComponent,
    LoadingOverlayContainer
} from "../../../../shared/components/loading_overlay/loading_overlay.component";
import ErrorDialog from "../../../../shared/components/error_dialog/error_dialog.component";
import {ErrorDialogButtons} from "shared/components/error_dialog/error_dialog.helper";
import {useApiError} from "shared/provider/api_error/api_error.provider";
import { JsonViewer } from "@textea/json-viewer";

interface ConfigurationProductionOrderComponentProps {
    breadcrumbComponent: React.ReactNode;
}

const ConfigurationProductionOrderComponent: React.FC<ConfigurationProductionOrderComponentProps> = ({breadcrumbComponent}) => {
    const state = useAppSelector((state) => state.configuration_productionorder);
    const configurationMode = useAppSelector((state) => state.configuration_mode);
    const dispatch: AppDispatch = useAppDispatch();
    const [configurationId] = useQueryParam("orderconfigurationid", StringParam);
    const [displayErrorDialog, setDisplayErrorDialog] = useState({isVisible: false, errorMessage: ""});
    const [isLoading, setIsLoading] = useState(true);
    const {apiError} = useApiError();
    const navigate = useNavigate();

    const showError = (message: string) => {
        setDisplayErrorDialog({
            isVisible: true,
            errorMessage: message
        });
    };

    const hideError = () => {
        setDisplayErrorDialog({
            isVisible: false,
            errorMessage: "",
        });
    };

    useEffect(() => {
        dispatch(setConfigurationId(configurationId));
    }, [configurationId]);

    useEffect(() => {
        if (state.query.getProductionOrder.status !== "pending")
            dispatch(getProductionOrder());
    }, [state.actualData.configurationId]);

    function handleBackToOverviewButtonClick() {
        const windowReference = window.location.href;
        const currentUrl = windowReference.replace(window.location.href, "")
        const url = `${currentUrl}${configurationMode.basePath}/overview`;
        navigate(url);
    }

    useEffect(() => {
        if (state.query.getProductionOrder.status === "error") {
            showError(state.query.getProductionOrder.message);
            setIsLoading(true);
        } else if (state.query.getProductionOrder.status === "pending" || state.query.getProductionOrder.status === "idle") {
            setIsLoading(true);
        } else if (state.query.getProductionOrder.status === "success") {
            setIsLoading(false);
        }
    }, [state.query.getProductionOrder.status]);

    const {t} = useTranslation();

    return (
        <LoadingOverlayContainer>
            {displayErrorDialog.isVisible ? (
                <ErrorDialog
                    message={displayErrorDialog.errorMessage}
                    apiError={apiError}
                    buttons={ErrorDialogButtons.getButtonsByError(apiError, () => {
                        dispatch(hideError);
                    })}
                />
            ) : (
                <>
                    {isLoading ? (
                        <LoadingOverlayComponent/>
                    ) : (
                        <>
                            <styleBreadcrumbs.BreadcrumbArea>
                                <Container>
                                    {breadcrumbComponent}
                                </Container>
                            </styleBreadcrumbs.BreadcrumbArea>
                            <ConfigurationProductionOrderContentContainer>
                                {
                                    <>
                                        <BackToConfigurationOverviewButton key="backToConfigurationOverview"
                                                                           onClick={handleBackToOverviewButtonClick}>
                                            {t("ConfigurationProductionOrderBackButtonLabel")}
                                        </BackToConfigurationOverviewButton>
                                        <JsonViewer displayDataTypes={false} value={state.loadedData.productionOrder}/>
                                    </>
                                }
                            </ConfigurationProductionOrderContentContainer>
                        </>
                    )}
                </>
            )}
        </LoadingOverlayContainer>
    );
};

export default ConfigurationProductionOrderComponent;
