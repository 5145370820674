import React, {useState} from "react";
import theme from "../../style/theme";
import {LoadingIndicatorComponent} from "../loading_indicator/loading_indicator.component";
import FormControl from "@mui/material/FormControl";
import {Autocomplete, TextField} from "@mui/material";

interface DropdownProps {
    name: string;
    // eslint-disable-next-line
    data: Array<any>;
    displayedProperties: Array<string>;
    isRequired?: boolean;
    isLoading?: boolean;
    isReadonly?: boolean;
    // eslint-disable-next-line
    onSelect: (selectedItem: any) => void;
    // eslint-disable-next-line
    selectedValue?: any;
    // eslint-disable-next-line
    renderEntry?: (entry: any) => string;
    style?: React.CSSProperties;
    // eslint-disable-next-line
    equalityComparer?: (option: any, value: any) => boolean;
}

export const DropdownComponent: React.FC<DropdownProps> = ({
                                                               data,
                                                               displayedProperties,
                                                               name,
                                                               onSelect,
                                                               isLoading,
                                                               isReadonly,
                                                               isRequired,
                                                               selectedValue,
                                                               renderEntry,
                                                               style,
                                                               equalityComparer
                                                           }) => {

    const [inputValue, setInputValue] = useState("");

    if (isLoading) {
        return (
            <TextField
                variant="standard"
                name={name}
                label={name}
                required={isRequired}
                disabled={true}
                InputProps={{
                    endAdornment: (
                        <LoadingIndicatorComponent size="1rem"/>
                    )
                }}/>
        );
    }

    return (
        <FormControl
            variant="standard"
            style={{minWidth: theme.form.minWidth, ...style}}
            required={isRequired}>
            <Autocomplete
                noOptionsText={"Keine Auswahl verfügbar"}
                id={name}
                disabled={isReadonly}
                disableClearable
                value={selectedValue}
                onChange={(event, selectedItem) => {
                    onSelect(selectedItem);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                options={data}
                getOptionLabel={(option) => {
                    if (renderEntry !== undefined) {
                        return renderEntry(option);
                    } else {
                        return displayedProperties
                            .map((property) => option[property])
                            .filter(entry => entry !== null && entry !== undefined)
                            .join(" ");
                    }
                }
                }
                renderInput={(params) => {
                    return (
                        <TextField
                            variant="standard"
                            {...params}
                            label={name}
                            margin="none"
                            required={isRequired}/>
                    );
                }}
                isOptionEqualToValue={equalityComparer}
            />

        </FormControl>
    );
};
