import React, {useEffect, useState} from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import theme from "../../style/theme";

export interface RadioGroupProps {
    name: string;
    // eslint-disable-next-line
    data: Array<any>;
    displayedProperties: Array<string>;
    valueProperty: string;
    isRequired?: boolean;
    // eslint-disable-next-line
    selectedItemData?: number;
    // eslint-disable-next-line
    onSelect: (selectedItem: any) => void;
}

export const RadioBoxComponent: React.FC<RadioGroupProps> = ({
                                                                 data,
                                                                 displayedProperties,
                                                                 valueProperty,
                                                                 name,
                                                                 onSelect,
                                                                 isRequired,
                                                                 selectedItemData,
                                                             }) => {
    const [selectedIndex, setSelectedIndex] = useState(
        data.indexOf(selectedItemData)
    );

    useEffect(() => {
        const index = data.findIndex(item => item.data === selectedItemData);
        setSelectedIndex(index);
    }, [selectedItemData, data]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const index = Number((e.target as HTMLInputElement).value);
        setSelectedIndex(index);
        onSelect(data[index][valueProperty]);
    };

    return (
        <FormControl
            variant="standard"
            component="fieldset"
            style={{minWidth: theme.form.minWidth}}
            required={isRequired}>
            <FormLabel>{name}</FormLabel>
            <RadioGroup
                aria-describedby={`${name}_description`}
                row={true}
                aria-label={name}
                name={name}
                value={`${selectedIndex}`}
                onChange={handleChange}
            >
                {data.map((entry, index) => (
                    <FormControlLabel
                        key={index}
                        value={`${index}`}
                        control={<Radio/>}
                        label={displayedProperties.map((property) => entry[property])}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};
