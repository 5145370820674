import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import IOrderConfigurationState from "models/configurations/configurationstate";
import {LoadingState} from "models/loading_state";
import {hasValue} from "services/validation.service";
import {getOrderConfigurationState, getMeasurementDataSheet, resetReadonly, sendMeasurementDataSheet, verify} from "./actionsmenu.thunk";
import {PassOrderConfigurationProvider} from "shared/provider/orderconfiguration/orderconfiguration.provider";

export interface ActionsMenuState {
    loadedData: {
        configurationId: string,
        orderConfigurationState: IOrderConfigurationState
    },
    query: {
        getOrderConfigurationState: LoadingState,
        getMeasurementDataSheet: LoadingState
    },
    command: {
        resetReadonly: LoadingState,
        verify: LoadingState,
        sendMeasurementDataSheet: LoadingState
    }
}

const initialState: ActionsMenuState = {
    loadedData: {
        configurationId: null,
        orderConfigurationState: null,
    },
    query: {
        getOrderConfigurationState: {
            status: "idle",
            canExecute: false
        },
        getMeasurementDataSheet: {
            status: "idle",
            canExecute: false
        },
    },
    command: {
        resetReadonly: {
            status: "idle",
            canExecute: false
        },
        verify: {
            status: "idle",
            canExecute: false
        },        
        sendMeasurementDataSheet: {
            status: "idle",
            canExecute: false
        }
    }
};

export const ActionsMenuSlice = createSlice({
    name: "actionsmenu",
    initialState,
    reducers: {
        setConfiguration: (state, action: PayloadAction<string>) => {
            state.loadedData.configurationId = action.payload;
            state.query.getOrderConfigurationState.canExecute = hasValue(action.payload);
            state.query.getMeasurementDataSheet.canExecute = hasValue(action.payload);
            state.command.sendMeasurementDataSheet.canExecute = hasValue(action.payload);
            state.command.resetReadonly.canExecute = hasValue(action.payload);

            // todo check
            state.command.verify.canExecute = hasValue(action.payload);
        },
    }, extraReducers: (builder) => {
        //getOrderConfigurationState
        builder.addCase(getOrderConfigurationState.pending, (state) => {
            state.query.getOrderConfigurationState.status = "pending";
        })
        .addCase(getOrderConfigurationState.rejected, (state) => {
            state.loadedData.orderConfigurationState = null;
        })
        .addCase(getOrderConfigurationState.fulfilled, (state, action) => {
            state.loadedData.orderConfigurationState = action.payload.getData();
            state.query.getOrderConfigurationState.status = "success";
        })
        // getMeasurementDataSheet
        .addCase(getMeasurementDataSheet.pending, (state) => {
            state.query.getMeasurementDataSheet.status = "pending";
            state.query.getMeasurementDataSheet.canExecute = false;
        })
        .addCase(getMeasurementDataSheet.rejected, (state) => {
            state.query.getMeasurementDataSheet.status = "error";
            state.query.getMeasurementDataSheet.canExecute = true;
        })
        .addCase(getMeasurementDataSheet.fulfilled, (state) => {
            state.query.getMeasurementDataSheet.status = "success";
            state.query.getMeasurementDataSheet.canExecute = true;
        })
        // verify
        .addCase(verify.pending, (state) => {
            state.command.resetReadonly.status = "pending";
            state.command.resetReadonly.canExecute = false;
        })
        .addCase(verify.rejected, (state) => {
            state.command.resetReadonly.status = "error";
            state.command.resetReadonly.canExecute = true;
        })
        .addCase(verify.fulfilled, (state) => {
            state.command.resetReadonly.status = "success";
            state.command.resetReadonly.canExecute = true;
            PassOrderConfigurationProvider.setReadonly(false);
        })        
        // resetReadonly
        .addCase(resetReadonly.pending, (state) => {
            state.command.resetReadonly.status = "pending";
            state.command.resetReadonly.canExecute = false;
        })
        .addCase(resetReadonly.rejected, (state) => {
            state.command.resetReadonly.status = "error";
            state.command.resetReadonly.canExecute = true;
        })
        .addCase(resetReadonly.fulfilled, (state) => {
            state.command.resetReadonly.status = "success";
            state.command.resetReadonly.canExecute = true;
            PassOrderConfigurationProvider.setReadonly(false);
        })
        // sendMeasurementDataSheet
        .addCase(sendMeasurementDataSheet.pending, (state) => {
            state.command.sendMeasurementDataSheet.status = "pending";
            state.command.sendMeasurementDataSheet.canExecute = false;
        })
        .addCase(sendMeasurementDataSheet.rejected, (state) => {
            state.command.sendMeasurementDataSheet.status = "error";
            state.command.sendMeasurementDataSheet.canExecute = true;
        })
        .addCase(sendMeasurementDataSheet.fulfilled, (state) => {
            state.command.sendMeasurementDataSheet.status = "success";
            state.command.sendMeasurementDataSheet.canExecute = true;
        });
    }
});

export const {
    setConfiguration
} = ActionsMenuSlice.actions;
export default ActionsMenuSlice.reducer;
