import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ConfigurationStepEnum} from "configurations/shared/configuration_editor/models/configuration_step.enum";
import {ConfigurationCreateState} from "./create.model";
import {validateState} from "./create.reducer";
import {createConfiguration, getConfiguration, gotoNext, skipCreateConfiguration} from "./create.thunks";
import IConfigurationCreateModel from "models/configurations/configuration_create";
import {hasValue} from "services/validation.service";
import {StepDescriptor} from "configurations/shared/configuration_editor/models/step_descriptor.model";
import IConfigurationId from "models/configurations/configurationid";
import {setErrorInState} from "shared/networking/error_handling";
import {determineTargetStep} from "configurations/shared/configuration_editor/redux/editor.reducer";

const initialState: ConfigurationCreateState = {
    loadedData: {
        stepState: {
            currentStep: ConfigurationStepEnum.Create,
            configurationId: null,
        },
        isNavigating: false,
        isLoading: true
    },
    actualData: {
        baseData: {
            erpTenant: "TST",
            erpGroupCompanyNumber: 101,
            customerNumber: "10000",
            erpItemId: "",
            erpItemPosition: 1,
            commission: null,
        }
    },
    command: {
        create: {
            status: "idle",
            canExecute: false
        }
    },
    query: {
        get: {
            status: "idle",
            canExecute: false
        }
    }
};

export const configurationCreateSlice = createSlice({
    name: "configuration/create",
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
        },
        resetGoNext: (state) => {
            state.command.create.status = "idle";
            state.loadedData.stepState.targetStep = null;
            state.command.create.goNextAutomatically = null;
        },
        setIsNavigate: (state) => {
            state.loadedData.isNavigating = true;
        },
        setErpTenant: (state, action: PayloadAction<string>) => {
            state.actualData.baseData.erpTenant = action.payload;
            state.command.create.canExecute = validateState(state);
        },
        setErpGroupCompanyNumber: (state, action: PayloadAction<number>) => {
            state.actualData.baseData.erpGroupCompanyNumber = action.payload;
            state.command.create.canExecute = validateState(state);
        },
        setCustomerNumber: (state, action: PayloadAction<string>) => {
            state.actualData.baseData.customerNumber = action.payload;
            state.command.create.canExecute = validateState(state);
        },
        setDeliveryAddressNumber: (state, action: PayloadAction<number>) => {
            state.actualData.baseData.deliveryAddressNumber = action.payload;
            state.command.create.canExecute = validateState(state);
        },
        setErpItemId: (state, action: PayloadAction<string>) => {
            state.actualData.baseData.erpItemId = action.payload;
            state.command.create.canExecute = validateState(state);
        },
        setErpItemPosition: (state, action: PayloadAction<number>) => {
            state.actualData.baseData.erpItemPosition = action.payload;
            state.command.create.canExecute = validateState(state);
        },
        setData: (state, action: PayloadAction<IConfigurationCreateModel & { sku: string, commission: string }>) => {
            state.actualData.baseData.erpTenant = action.payload.erpTenant;
            state.actualData.baseData.erpGroupCompanyNumber = action.payload.erpGroupCompanyNumber;
            state.actualData.baseData.erpItemId = action.payload.erpItemId;
            state.actualData.baseData.erpItemPosition = action.payload.erpItemPosition;
            state.actualData.baseData.customerNumber = action.payload.customerNumber;
            state.actualData.baseData.deliveryAddressNumber = action.payload.deliveryAddressNumber;
            state.actualData.baseData.commission = action.payload.commission;
            state.actualData.baseData.mtmNumber = action.payload.mtmNumber;

            state.actualData.sku = action.payload.sku;

            state.command.create.canExecute = validateState(state);
            state.command.create.goNextAutomatically = true;
        },
        setConfiguration: (state, action: PayloadAction<string>) => {
            state.actualData.configurationId = action.payload;
            state.query.get.canExecute = hasValue(action.payload);
        },
        setInitializationDone: (state) => {
            state.loadedData.isLoading = false;
        }
    }, extraReducers: (builder) => {
        builder
            // createConfiguration
            .addCase(createConfiguration.rejected, (state, action) => {
                setErrorInState(state.command.create, action);
            })
            .addCase(createConfiguration.fulfilled, (state, action) => {

                const data: IConfigurationId = action.payload.getData();
                const targetStep: StepDescriptor = determineTargetStep(data, state.actualData.sku);

                // remember id
                state.loadedData.stepState.configurationId = data.orderConfigurationId;

                state.loadedData.stepState.targetStep = targetStep;
                state.command.create.status = "success";
            })
            // skipCreateConfiguration
            .addCase(skipCreateConfiguration.fulfilled, (state) => {
                state.command.create.status = "success";
            })

            // gotoNext
            .addCase(gotoNext.pending, (state) => {
                state.command.create.status = "pending";
                state.command.create.canExecute = false;
            })

            // get
            .addCase(getConfiguration.pending, (state) => {
                state.query.get.status = "pending";
                state.query.get.canExecute = false;
            })
            .addCase(getConfiguration.rejected, (state, action) => {
                setErrorInState(state.query.get, action);
            })
            .addCase(getConfiguration.fulfilled, (state, action) => {
                const data: IConfigurationId = action.payload.getData();
                const targetStep: StepDescriptor = determineTargetStep(data, state.actualData.sku);

                // remember id
                state.loadedData.stepState.configurationId = data.orderConfigurationId;

                state.loadedData.stepState.targetStep = targetStep;
                state.query.get.status = "success";
            });
    }
});

export const {
    resetState,
    resetGoNext,
    setIsNavigate,
    setErpTenant,
    setErpGroupCompanyNumber,
    setCustomerNumber,
    setDeliveryAddressNumber,
    setErpItemId,
    setErpItemPosition,
    setData,
    setConfiguration,
    setInitializationDone
} = configurationCreateSlice.actions;

export default configurationCreateSlice.reducer;
