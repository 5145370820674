import React from "react";
import {CircularProgress} from "@mui/material";

interface ILoadingIndicatorProps {
    size?: string | number;
}

export const LoadingIndicatorComponent: React.FC<ILoadingIndicatorProps> = ({
                                                                                size
                                                                            }) => {
    return size === null ? <CircularProgress color="primary"/> : <CircularProgress color="primary" size={size}/>;
};
