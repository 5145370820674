import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getFilter, getFilterGroup, getProduct, restoreFilter} from "./filter.thunk";
import {FilterState, ResetAfterRestore, SetFilter} from "./filter.model";
import {Filter, FilterGroupResponse} from "../../../measurements/models/responses";
import {setErrorInState} from "shared/networking/error_handling";

const initialState: FilterState = {
    filterId: "",
    filterGroups: [],
    isSelectionUpdated: false,
    isSelectionAutomaticallyUpdated: false,
    productSKUs: null,
    isFilteringFinished: false,
    isFilteringSuccessful: false,
    isRestored: false,
    query: {
        get: {
            status: "idle",
            canExecute: false
        }
    },
    filterGroupResponsibleForUpdate: ""
};

export const filterSlice = createSlice({
    name: "filter",
    initialState,
    reducers: {
        setIsFilteringSuccessful: (state, action: PayloadAction<boolean>) => {
            state.isFilteringSuccessful = action.payload;
        },
        setSelectedValue: (state, action: PayloadAction<SetFilter>) => {
            const groupIndex = state.filterGroups.findIndex(group => group.filterGroupId === action.payload.filterGroupId);
            const optionIndex = state.filterGroups[groupIndex].filterGroupOptions.findIndex(option => option.type === action.payload.type);
            const previouslySelectedValue = state.filterGroups[groupIndex].filterGroupOptions[optionIndex].selectedValue;

            state.filterGroups[groupIndex].filterGroupOptions[optionIndex].selectedValue = action.payload.selectedValue;

            if (state.filterGroups[groupIndex].isStepByStep) {
                // If a previous selection has been changed in a step by step filtering:
                // Reset all selections of later filterGroupOptions since the available options might have changed
                for (let i = optionIndex + 1; i < state.filterGroups[groupIndex].filterGroupOptions.length; i++) {
                    state.filterGroups[groupIndex].filterGroupOptions[i].filterValues = [];
                    state.filterGroups[groupIndex].filterGroupOptions[i].selectedValue = "";
                }

                // Reset all filterGroupOptions in filterGroups after the current one since these selections may no longer be valid
                for (let i = groupIndex + 1; i < state.filterGroups.length; i++) {
                    for (let j = 0; j < state.filterGroups[i].filterGroupOptions.length; j++) {
                        state.filterGroups[i].filterGroupOptions[j].filterValues = [];
                        state.filterGroups[i].filterGroupOptions[j].selectedValue = "";
                    }
                }
            } else if (previouslySelectedValue !== "" && previouslySelectedValue !== action.payload.selectedValue) {
                //if a previous selection (not the initial selection) has been changed in a none step by step filtering: all selections of other filterGroupOptions need to be reset
                state.filterGroups[groupIndex].filterGroupOptions.forEach((option, index) => {
                    if (index !== optionIndex) {
                        option.filterValues = [];
                        option.selectedValue = "";
                    }
                });
            }
            state.filterGroupResponsibleForUpdate = action.payload.filterGroupId;
            state.isSelectionUpdated = true;
        },
        resetSelectionsOnChangeAfterRestore: (state, action: PayloadAction<ResetAfterRestore>) => {
            const groupIndex = state.filterGroups.findIndex(group => group.filterGroupId === action.payload.filterGroupId);
            const optionIndex = state.filterGroups[groupIndex].filterGroupOptions.findIndex(option => option.type === action.payload.type);

            // Reset selections for the expanded element and later selections
            for (let i = optionIndex; i < state.filterGroups[groupIndex].filterGroupOptions.length; i++) {
                state.filterGroups[groupIndex].filterGroupOptions[i].filterValues = [];
                state.filterGroups[groupIndex].filterGroupOptions[i].selectedValue = "";
            }

            for (let i = groupIndex + 1; i < state.filterGroups.length; i++) {
                for (let j = 0; j < state.filterGroups[i].filterGroupOptions.length; j++) {
                    state.filterGroups[i].filterGroupOptions[j].filterValues = [];
                    state.filterGroups[i].filterGroupOptions[j].selectedValue = "";
                }
            }
            state.filterGroupResponsibleForUpdate = action.payload.filterGroupId;
            state.isSelectionUpdated = true;
        },
        resetState: (state) => {
            return {...initialState, filterId: state.filterId};
        },
        resetProductSKUs: (state) => {
            return {...state, productSKUs: null};
        }
    }, extraReducers: (builder) => {
        builder
            // getFilter
            .addCase(getFilter.pending, (state) => {
                state.query.get.status = "pending";
            })
            .addCase(getFilter.rejected, (state, action) => {
                setErrorInState(state.query.get, action);
            })
            .addCase(getFilter.fulfilled, (state, action) => {
                const filterDataResponse = action.payload.getData() as Filter;
                state.filterId = filterDataResponse.id;
                // getFilter should be called from the filter managing component to initially determine how many filter groups there will be by calling the /api/filterconfiguration/filters endpoint
                // This endpoint returns the overall structure of the whole filter definition without any details
                // Initially all groups of a filter definition are mapped to the state.filterGroups.
                // Since this is only the structure of the whole filter without concrete details there are no filterValues at this point.
                // The details of a filterGroup will be set in getFilterGroup once the component responsible for a given group is initially called
                state.filterGroups = filterDataResponse.filterGroups.map((group) => ({
                    displayName: group.name,
                    filterGroupId: group.id,
                    isStepByStep: group.stepByStep,
                    filterGroupOptions: group.filterGroupOptions.sort((a, b) => a.optionIndex - b.optionIndex).map((filterGroupOption) => ({
                        displayName: "",
                        filterValues: [],
                        type: filterGroupOption.type,
                        selectedValue: "",
                        optionIndex: filterGroupOption.optionIndex,
                        optionId: filterGroupOption.id
                    })),
                }));
                state.query.get.status = "success";
            })

            // getFilterGroup
            .addCase(getFilterGroup.pending, (state) => {
                state.query.get.status = "pending";
                state.isSelectionAutomaticallyUpdated = false;
                state.isSelectionUpdated = false;
            })
            .addCase(getFilterGroup.rejected, (state, action) => {
                setErrorInState(state.query.get, action);
                state.isSelectionAutomaticallyUpdated = false;
                state.isSelectionUpdated = false;
            })
            .addCase(getFilterGroup.fulfilled, (state, action) => {
                const {filterGroupResponse} = action.payload;
                const filterGroupResponseData = filterGroupResponse.getData() as FilterGroupResponse;
                // This flag is used to automatically dispatch another getFilterGroup. This is the case if a filterGroupOptions-Array only has one possible filterValue.
                // This one filterValue is automatically selected and the selection is sent to the backend without user interaction
                let isAutomaticallyUpdated = false;

                // All filterGroups from the response are mapped to the state
                filterGroupResponseData.filterGroups.forEach(element => {
                    state.filterGroups.forEach(filterGroup => {
                        const filterGroupOptionToUpdateIndex = filterGroup.filterGroupOptions.findIndex(
                            (option) => option.optionId === element.id
                        );

                        if (filterGroupOptionToUpdateIndex >= 0 && filterGroup.filterGroupOptions[filterGroupOptionToUpdateIndex].selectedValue === "") {
                            filterGroup.filterGroupOptions[filterGroupOptionToUpdateIndex].displayName = element.displayName;
                            filterGroup.filterGroupOptions[filterGroupOptionToUpdateIndex].filterValues = element.filterValues.sort((a, b) => a.displayName.localeCompare(b.displayName));

                            if (filterGroup.filterGroupOptions[filterGroupOptionToUpdateIndex].filterValues.length === 1) {
                                filterGroup.filterGroupOptions[filterGroupOptionToUpdateIndex].selectedValue = filterGroup.filterGroupOptions[filterGroupOptionToUpdateIndex].filterValues[0].value;
                                isAutomaticallyUpdated = true;
                            } else if (filterGroup.filterGroupOptions[filterGroupOptionToUpdateIndex].filterValues.length === 0) {
                                filterGroup.filterGroupOptions[filterGroupOptionToUpdateIndex].selectedValue = null;
                                isAutomaticallyUpdated = true;
                            }
                        }
                    });
                });

                state.isFilteringFinished = filterGroupResponseData.filterGroups.length === 0 || filterGroupResponseData.productCount === 1;
                state.filterGroupResponsibleForUpdate = action.payload.groupId;
                state.isSelectionAutomaticallyUpdated = isAutomaticallyUpdated;
                state.isSelectionUpdated = isAutomaticallyUpdated;
                state.query.get.status = isAutomaticallyUpdated ? "pending" : "success";
            })

            // getProduct
            .addCase(getProduct.fulfilled, (state, action) => {
                if (action.payload.getData().data.length > 0) {
                    state.productSKUs = action.payload.getData().data;
                } else {
                    state.productSKUs = [];
                }
            })


            // restoreFilter
            .addCase(restoreFilter.pending, (state) => {
                state.query.get.status = "pending";
            })
            .addCase(restoreFilter.rejected, (state) => {
                state.query.get.status = "idle";
            })
            // restoreFilter
            .addCase(restoreFilter.fulfilled, (state, action) => {
                const response = action.payload.getData();
                if (response) {

                    response.filterGroups.forEach((group) => {
                        const existingGroup = state.filterGroups.find((existingGroup) => existingGroup.filterGroupOptions.some(option => option.optionId === group.id));

                        if (existingGroup) {
                            existingGroup.filterGroupOptions.forEach((option) => {
                                if (option.optionId === group.id) {
                                    option.filterValues = [group.filterValues[0]];
                                    option.selectedValue = group.filterValues[0].value;
                                    option.displayName = group.displayName;
                                }
                            });
                        }
                        state.filterGroupResponsibleForUpdate = existingGroup.filterGroupId;

                    });
                    state.isRestored = true;
                    state.isFilteringFinished = true;
                    state.query.get.status = "success";
                }
            });
    }
});

export const {
    setSelectedValue,
    resetSelectionsOnChangeAfterRestore,
    resetState,
    resetProductSKUs,
    setIsFilteringSuccessful,
} = filterSlice.actions;

export default filterSlice.reducer;
