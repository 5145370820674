import React, {useEffect, useState} from "react";
import {AppDispatch} from "app/redux_store";
import {useAppDispatch, useAppSelector} from "app/hook";
import {
    ConfigurationStepperComponent
} from "configurations/shared/configuration_editor/components/configuration_stepper.component";
import {
    ConfigurationNavigationComponent
} from "configurations/shared/configuration_editor/components/configuration_navigation.component";
import {TextBoxComponent} from "shared/shared";
import {
    resetGoNext,
    resetState,
    setConfiguration,
    setCustomerNumber,
    setData,
    setDeliveryAddressNumber,
    setErpGroupCompanyNumber,
    setErpItemId,
    setErpItemPosition,
    setErpTenant,
    setInitializationDone,
    setIsNavigate
} from "./redux/create.slice";
import {getConfiguration, gotoNext} from "./redux/create.thunks";
import {useTranslation} from "react-i18next";
import {Grid} from "@mui/material";
import * as style from "shared/style/form";
import {hasValue} from "services/validation.service";
import {StepDescriptor} from "configurations/shared/configuration_editor/models/step_descriptor.model";
import {INavigationParams} from "configurations/shared/configuration_editor/models/navigation_params.model";
import {ContentContainer} from "shared/components/container/ContentContainer";
import {ConfigurationMode} from "../../../configurationMode.model";
import ErrorDialog from "../../../../../shared/components/error_dialog/error_dialog.component";
import {removeCreateStep, resetStepperState} from "../../redux/stepper.slice";
import {resetState as resetCustomerDataState} from "../../redux/customerdata.slice";
import {LoadingOverlayComponent} from "shared/components/loading_overlay/loading_overlay.component";
import {ErrorDialogButtons} from "shared/components/error_dialog/error_dialog.helper";
import {useApiError} from "shared/provider/api_error/api_error.provider";
import { ConfigurationStepEnum } from "../../models/configuration_step.enum";

interface CreateProps {
    onNavigate: (next: StepDescriptor, params?: INavigationParams[]) => void;
    configurationState: ConfigurationStepEnum;
}

const CreateComponent = (props: CreateProps) => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.configuration_create);
    const configurationMode = useAppSelector((state) => state.configuration_mode).mode;
    const {t} = useTranslation();
    const {apiError} = useApiError();
    const [displayErrorDialog, setDisplayErrorDialog] =
        useState<{ isVisible: boolean; errorMessage: string }>({
            isVisible: false,
            errorMessage: "",
        });

    const showError = (message: string) => {
        setDisplayErrorDialog({
            isVisible: true,
            errorMessage: message
        });
    };

    const hideError = () => {
        setDisplayErrorDialog({
            isVisible: false,
            errorMessage: "",
        });
    };

    useEffect(() => {
        return () => {
            dispatch(resetState());
        }; // on exit component
    }, [dispatch]);

    useEffect(() => {

        dispatch(resetCustomerDataState());
        dispatch(resetStepperState());

        const urlParams = new URLSearchParams(window.location.search);

        const erpTenant = urlParams.get("erpTenant");
        const erpGroupCompanyNo = parseInt(urlParams.get("erpGroupCompanyNo"));
        const customerNo = urlParams.get("customerNo");
        const deliveryAddressNo = Number(urlParams.get("deliveryAddressNo"));
        const erpItemId = urlParams.get("erpItemId");
        const erpItemPosition = parseInt(urlParams.get("erpItemPosition"));
        const commission = urlParams.get("commission");
        const sku = urlParams.get("sku");
        const configurationId = urlParams.get("orderconfigurationid");
        const mtmNumber = urlParams.get("mtmNo");

        if (hasValue(erpTenant) && hasValue(erpGroupCompanyNo) && hasValue(customerNo) && hasValue(erpItemId) && hasValue(erpItemPosition)) {
            dispatch(setData({
                erpTenant: erpTenant,
                erpGroupCompanyNumber: erpGroupCompanyNo,
                customerNumber: customerNo,
                deliveryAddressNumber: deliveryAddressNo,
                erpItemId: erpItemId,
                erpItemPosition: erpItemPosition,
                sku: sku,
                commission: commission,
                mtmNumber: mtmNumber,
            }));
        } else if (hasValue(configurationId)) {
            dispatch(setConfiguration(configurationId));
        } else {
            dispatch(setInitializationDone());
        }
    }, []);

    useEffect(() => {
        if (state.command.create.status === "success") {
            const target = state.loadedData.stepState.targetStep;
            dispatch(setIsNavigate());
            props.onNavigate(target, [
                {
                    name: "orderconfigurationid",
                    value: state.loadedData.stepState.configurationId,
                },
            ]);
            dispatch(resetGoNext());
        }
        if (state.command.create.status === "error") {
            dispatch(resetGoNext());
            showError(state.command.create.message);
        }
    }, [state.command.create.status]);

    useEffect(() => {
        if (state.command.create.goNextAutomatically)
            dispatch(gotoNext());
    }, [state.command.create.goNextAutomatically]);

    useEffect(() => {
        if (state.query.get.canExecute) {
            dispatch(getConfiguration());
        }
    }, [state.query.get.canExecute]);

    useEffect(() => {
        if (state.query.get.status === "success") {
            const target = state.loadedData.stepState.targetStep;
            dispatch(setIsNavigate());
            props.onNavigate(target, [
                {
                    name: "orderconfigurationid",
                    value: state.loadedData.stepState.configurationId,
                },
            ]);
            dispatch(resetGoNext());
        }
        if (state.command.create.status === "error") {
            dispatch(resetGoNext());
            showError(state.query.get.message);
        }
    }, [state.query.get.status]);

    const handleErpTenantChange = (e: string) => {
        dispatch(setErpTenant(e));
    };

    const handleErpGroupCompanyNumberChange = (e: string) => {
        dispatch(setErpGroupCompanyNumber(parseInt(e)));
    };

    const handleCustomerNumberChange = (e: string) => {
        dispatch(setCustomerNumber(e));
    };

    const handleDeliveryAddressNumberChange = (e: string) => {
        dispatch(setDeliveryAddressNumber(parseInt(e || "0")));
    };

    const handleErpItemIdChange = (e: string) => {
        dispatch(setErpItemId(e));
    };

    const handleErpItemPositionChange = (e: string) => {
        dispatch(setErpItemPosition(parseInt(e)));
    };

    return (
        <>
            <ConfigurationStepperComponent orderConfigurationStateStep={props.configurationState}/>
            <ContentContainer>
                {displayErrorDialog.isVisible && (
                    <ErrorDialog
                        message={displayErrorDialog.errorMessage}
                        apiError={apiError}
                        buttons={ErrorDialogButtons.getButtonsByError(apiError, () => {
                            dispatch(hideError);
                        })}
                    />
                )}

                {state.loadedData.isLoading ? (<LoadingOverlayComponent/>) :
                    (<><Grid
                        container
                        direction="column"
                        justifyContent="center"
                    >
                        <style.FormRow>
                            <TextBoxComponent
                                name={t("COMMON:ErpTenant")}
                                onChange={handleErpTenantChange}
                                value={state.actualData.baseData.erpTenant}
                                isRequired={true}
                                maxLength={10}
                                isAutoFocus={!state.actualData.baseData.erpTenant}
                                tabIndex={0}
                            />
                            <TextBoxComponent
                                name={t("COMMON:GroupCompany")}
                                onChange={handleErpGroupCompanyNumberChange}
                                value={state.actualData.baseData.erpGroupCompanyNumber.toString()}
                                isRequired={true}
                                min={1}
                                max={999}
                                isNumeric={true}
                                isAutoFocus={!state.actualData.baseData.erpGroupCompanyNumber}
                                tabIndex={1}
                            />
                            <TextBoxComponent
                                name={t("COMMON:CustomerNumber")}
                                onChange={handleCustomerNumberChange}
                                value={state.actualData.baseData.customerNumber}
                                isRequired={true}
                                maxLength={20}
                                isAutoFocus={!state.actualData.baseData.customerNumber}
                                tabIndex={2}
                            />
                            <TextBoxComponent
                                name={t("COMMON:DeliveryAddressNumber")}
                                onChange={handleDeliveryAddressNumberChange}
                                value={state.actualData.baseData.deliveryAddressNumber?.toString()}
                                isRequired={false}
                                min={0}
                                max={999}
                                isNumeric={true}
                                tabIndex={3}
                            />
                        </style.FormRow>
                        <style.FormRow>
                            <TextBoxComponent
                                name={configurationMode === ConfigurationMode.OrderConfiguration ? t("COMMON:ErpOrderNumber") : t("COMMON:ErpQuotationNumber")}
                                onChange={handleErpItemIdChange}
                                value={state.actualData.baseData.erpItemId?.toString()}
                                isRequired={true}
                                maxLength={16}
                                isAutoFocus={!hasValue(state.actualData.baseData.erpItemId)}
                                tabIndex={4}
                            />
                            <TextBoxComponent
                                name={configurationMode === ConfigurationMode.OrderConfiguration ? t("COMMON:ErpOrderPosition") : t("COMMON:ErpQuotationPosition")}
                                onChange={handleErpItemPositionChange}
                                value={state.actualData.baseData.erpItemPosition.toString()}
                                isRequired={true}
                                min={1}
                                max={999}
                                isNumeric={true}
                                isAutoFocus={!state.actualData.baseData.erpItemPosition}
                                tabIndex={5}
                            />
                        </style.FormRow>
                    </Grid>
                        <ConfigurationNavigationComponent
                            next={() => {
                                dispatch(gotoNext());
                                dispatch(removeCreateStep());
                            }}
                            canNext={state.command.create.canExecute}/></>)}
            </ContentContainer>
        </>
    );
};

export default CreateComponent;
