import HttpService from "../shared/networking/http_service";
import {ApiResult} from "../shared/networking/api_result";
import {IConfigurationEvent, IConfigurationEvents} from "../models/configurations/configuration_events";
import {getConfig} from "./config.service";

interface GetEventsFilterParams {
    id?: string;
    page?: number;
    size?: number;
}

class ConfigurationsEventsServiceAPI extends HttpService {
    public async getEvents(filters: GetEventsFilterParams): Promise<ApiResult<IConfigurationEvents>> {
        return this.get<IConfigurationEvents>(`/api/eventlog/byorderconfiguration/${filters.id}?page=${filters.page}&size=${filters.size}`);
    }

    public async getEvent(eventId: string): Promise<ApiResult<IConfigurationEvent>> {
        return this.get<IConfigurationEvent>(`/api/eventlog/${eventId}`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const Configurations_eventsService = new ConfigurationsEventsServiceAPI(TimeoutMilliseconds);

export default Configurations_eventsService;
