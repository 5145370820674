import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {StepperState} from "./stepper.model";
import {ConfigurationStepEnum, ConfigurationStepValues} from "../models/configuration_step.enum";
import {ProductionType} from "../../../../models/production_type.enum";


const initialState: StepperState = {
    allSteps: ConfigurationStepValues,
    previousStep: null,
    currentStep: null,
    nextStep: null
};

export const stepperSlice = createSlice({
    name: "stepper",
    initialState,
    reducers: {
        resetStepperState: (state) => {
            state.allSteps = [...initialState.allSteps];
            state.previousStep = null;
            state.currentStep = initialState.allSteps[0];
            state.nextStep = initialState.allSteps[1];
        },
        updateStepperState: (state, action: PayloadAction<ConfigurationStepEnum>) => {
            const filteredSteps = state.allSteps.filter(step => step.value !== ConfigurationStepEnum.Create);
            state.allSteps = filteredSteps;
            const currentIndex = filteredSteps.findIndex(step => step.value === action.payload);
            state.previousStep = currentIndex > 0 ? filteredSteps[currentIndex - 1] : null;
            state.currentStep = filteredSteps[currentIndex];
            state.nextStep = currentIndex < filteredSteps.length - 1 ? filteredSteps[currentIndex + 1] : null;

        },
        updateAvailableSteps: (state, action: PayloadAction<ProductionType>) => {
            let filteredSteps = state.allSteps;
            if (action.payload === ProductionType.Serial) {
                filteredSteps = state.allSteps.filter(step => step.value !== ConfigurationStepEnum.Measurements);

            } else if (action.payload === ProductionType.MadeToMeasure && !state.allSteps.some(step => step.value === ConfigurationStepEnum.Measurements)) {
                filteredSteps = initialState.allSteps.filter(step => step.value !== ConfigurationStepEnum.Create);
            }
            state.allSteps = filteredSteps;
            const currentIndex = filteredSteps.findIndex(step => step.value === state.currentStep.value);
            state.previousStep = currentIndex > 0 ? filteredSteps[currentIndex - 1] : null;
            state.nextStep = currentIndex < state.allSteps.length - 1 ? filteredSteps[currentIndex + 1] : null;
        },
        removeCreateStep: (state) => {
            state.allSteps = state.allSteps.filter(step => step.value !== ConfigurationStepEnum.Create);
        }
    }
});

export const {
    resetStepperState,
    updateStepperState,
    updateAvailableSteps,
    removeCreateStep
} = stepperSlice.actions;

export default stepperSlice.reducer;
