import React from "react";
import {NotFoundComponent} from "../shared";
import {HomeComponent} from "../../home/home.component";
import {OrderConfigurationsComponent} from "configurations/order/orderconfigurations.component";
import {AdminComponent} from "../../admin/admin.component";
import {QuotationConfigurationsComponent} from "../../configurations/quotation/quotationconfigurations.component";
import {RedirectSalesItemComponent} from "sales/redirectsalesitem.component";
import { Route, Routes, Navigate  } from "react-router-dom";

const AppRouterComponent = () => {
    return (
        <Routes>       
            <Route index element={<Navigate replace to="/home" />}/>  
            <Route element={<HomeComponent />} path="/home"/>
            <Route element={<AdminComponent />} path="/admin/*"/>

            <Route element={<OrderConfigurationsComponent />} path="/orderconfigurations/*"/>
            <Route element={<RedirectSalesItemComponent targetBasePath="salesorders/orderconfiguration/create" />} path="/salesorder/*"/>
            <Route element={<RedirectSalesItemComponent targetBasePath="salesorders/orderconfiguration/create" />} path="/order/*"/>
            <Route element={<OrderConfigurationsComponent />} path="/salesorders/*"/>            

            <Route element={<QuotationConfigurationsComponent />} path="/quotationconfigurations/*"/>
            <Route element={<RedirectSalesItemComponent targetBasePath="salesquotations/quotationconfiguration/create" />} path="/salesquotation/*" />
            <Route element={<RedirectSalesItemComponent targetBasePath="salesquotations/quotationconfiguration/create" />} path="/quotation/*" /> 
            <Route element={<QuotationConfigurationsComponent />} path="/salesquotations/*"/>

            <Route element={<NotFoundComponent />} index />
            
        </Routes>
    );
};
export default AppRouterComponent;
