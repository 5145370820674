import {DocumentModel} from "../../../models/documentModel";
import {ProductionInstructionModel} from "../../../models/productionInstructionModel";
import {AdditionModel} from "../../../models/additionModel";

const previewableFiletypes = [".jpg", ".jpeg", ".gif", ".pdf", ".png"];

export const isPreviewableFiletype = (filename: string): boolean => {
    for (const filetype of previewableFiletypes) {
        if (filename.endsWith(filetype)) {
            return true;
        }
    }
    return false;
};

export const getPreviewableProductionInstructionsDocuments = (productionInstructions: ProductionInstructionModel[]) => {
    return productionInstructions.reduce<DocumentModel[]>((acc, instruction) => {
        const previewableDocuments = instruction.documents.filter((document) => document.isPreviewable);
        return acc.concat(previewableDocuments);
    }, []);
};

export const getPreviewableAdditionsDocuments = (additions: AdditionModel[]) => {
    return additions.reduce<DocumentModel[]>((acc, currentAddition) => {
        const previewableDocuments = currentAddition.positions.reduce<DocumentModel[]>((accPositions, currentPosition) => {
            const previewableDocs = currentPosition.documents.filter((document) => document.isPreviewable);
            return accPositions.concat(previewableDocs);
        }, []);
        return acc.concat(previewableDocuments);
    }, []);
};

