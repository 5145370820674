import * as React from "react";
import { useEffect } from "react";
import * as styleBreadcrumbs from "../../../../shared/breadcrumbs/breadcrumbs_root.style";
import {Container} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../../../../app/hook";
import {useTranslation} from "react-i18next";
import {BackToEventsOverviewButton, ConfigurationEventJsonContentContainer} from "./configuration_eventJson.style";
import {StringParam, useQueryParam} from "use-query-params";
import {AppDispatch} from "../../../../app/redux_store";
import {setConfigurationId, setEventId} from "../redux/configurations_events.slice";
import {getEvent} from "../redux/configurations_events.thunks";
import { JsonViewer } from "@textea/json-viewer";
import { useNavigate } from "react-router-dom";

interface ConfigurationEventJsonComponent {
    breadcrumbComponent: React.ReactNode;
}

const ConfigurationEventJsonComponent: React.FC<ConfigurationEventJsonComponent> = ({breadcrumbComponent}) => {
    const state = useAppSelector((state) => state.configuration_events);
    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const {t} = useTranslation();
    const [orderConfigurationId] = useQueryParam("orderconfigurationid", StringParam);
    const [eventId] = useQueryParam("eventid", StringParam);

    function handleBackToEventOverviewButtonClick() {
        const windowReference = window.location.href;
        let currentUrl = windowReference.replace(window.location.origin, "")
            .replace("/eventdetails", "/events");

        currentUrl = currentUrl.substring(0, currentUrl.indexOf("&eventid")); //removes the eventId query parameter to navigate back to the event overview
        navigate(currentUrl);
    }

    useEffect(() => {
        if (state.actualData.configurationId === "" || state.actualData.eventId === "") {
            dispatch(setConfigurationId(orderConfigurationId));
            dispatch(setEventId(eventId));
        }
    }, []);

    useEffect(() => {
        if (state.actualData.configurationId !== "" && state.actualData.eventId !== "") {
            dispatch(getEvent());
        }
    }, [state.actualData.configurationId, state.actualData.eventId]);

    return (
        <>
            <styleBreadcrumbs.BreadcrumbArea>
                <Container>
                    {breadcrumbComponent}
                </Container>
            </styleBreadcrumbs.BreadcrumbArea>
            <ConfigurationEventJsonContentContainer>
                {
                    <>
                        <BackToEventsOverviewButton key="backToEventOverview"
                                                    onClick={handleBackToEventOverviewButtonClick}>
                            {t("ConfigurationEventOverviewBackButtonLabel")}
                        </BackToEventsOverviewButton>
                        {state.loadedData.selectedEvent &&
                            <JsonViewer displayDataTypes={false} value={state.loadedData.selectedEvent} />}
                    </>
                }
            </ConfigurationEventJsonContentContainer>
        </>
    );
};

export default ConfigurationEventJsonComponent;
