import colors from "./colors";
import {createTheme} from "@mui/material/styles";
import type { } from "@mui/x-date-pickers/themeAugmentation";

const theme = {
    form: {minWidth: 186},
};

export const customizedMaterialTheme = createTheme({
    typography: {
        fontFamily: "SkolarSansPeSemibold",
        fontSize: 14,
    },
    palette: {
        primary: {
            light: colors.mediLightGray,
            main: colors.mediMagenta,
            dark: colors.mediGray,
            contrastText: colors.white,
        }
    },
    components: {
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: `${colors.mediMagenta} !important`,
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginRight: 10,
                    "&.MuiDataGrid-filterFormOperatorInput": {
                        display: "none !important",
                    }
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    display: "flex",
                    padding: 0,
                    "&.MuiPaper-root": {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
        MuiStep: {
            styleOverrides: {
                horizontal: {
                    paddingLeft: 0,
                },
                root: {
                    "&:not(.Mui-completed)": {
                        color: colors.mediLightGray,
                    },
                    "&.Mui-completed:hover": {
                        color: `${colors.mediMagenta} !important`,
                        cursor: "pointer"
                    },
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    "&.Mui-completed": {
                        color: "inherit",
                    },
                    "&.Mui-completed:hover": {
                        color: "inherit",
                    },
                    width: "12px",
                    height: "12px",
                    text: {
                        display: "none",
                    },
                    color: "inherit",
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-completed": {
                        color: "inherit",
                    },
                    "&.Mui-completed:hover": {
                        color: "inherit",
                    },
                },
                label: {
                    "&.Mui-active": {
                        color: `${colors.mediMagenta} !important`,
                    },
                    "&.Mui-completed": {
                        color: "inherit",
                    },
                    "&.Mui-completed:hover": {
                        color: "inherit",
                    },
                },
                labelContainer: {
                    color: "inherit",
                }
            },
        },
        MuiStepConnector: {
            styleOverrides: {
                root: {
                    paddingRight: 8,
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                container: {
                    background: "white",
                    marginBottom: 8,
                },
                gutters: {
                    marginBottom: 8,
                    background: "white",
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: colors.mediMagenta,
                    color: "white",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.mediGray,
                    textTransform: "none",
                    "&.MuiButtonBase-root": {
                        color: "white",
                        opacity: 1,
                        borderBottomColor: colors.mediMagenta,
                        borderWidth: 0,
                        border: "solid",
                        borderBottomWidth: 2,
                    },
                    "&.Mui-selected": {
                        color: colors.mediGray,
                        backgroundColor: "transparent",
                        borderColor: colors.mediMagenta,
                        borderWidth: 2,
                        border: "solid",
                        borderBottomWidth: 0,
                    },
                    "&.MuiTab-textColorInherit.Mui-disabled": {
                        backgroundColor: "transparent",
                        opacity: 1,
                        minWidth: "100%",
                    },
                }
            }
        }
    }
});

export default theme;
