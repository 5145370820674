import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../app/hook";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {resetState, setConfiguration} from "../redux/salesitemdata.slice";
import {AppDispatch} from "../../../../app/redux_store";
import {LoadingIndicatorComponent} from "../../../../shared/components/loading_indicator/loading_indicator.component";
import ErrorDialog from "../../../../shared/components/error_dialog/error_dialog.component";
import {getConfiguration} from "../redux/salesitemdata.thunk";
import {ErrorDialogButtons} from "shared/components/error_dialog/error_dialog.helper";
import {useApiError} from "shared/provider/api_error/api_error.provider";
import {Grid} from "@mui/material";

const Label = styled.div`
  font-weight: bold;
  margin-right: 5px;
  display: inline;
`;

const GridWrapper = styled.div`
  float: left;
  min-width: 230px;
`;

interface SalesItemDataProps {
    orderConfigurationId: string
}

const SalesItemDataComponent: React.FC<SalesItemDataProps> = (props) => {
    const dispatch: AppDispatch = useAppDispatch();
    const salesItemData = useAppSelector((state) => state.configuration_salesitemdata);
    const {t} = useTranslation();
    const [displayErrorDialog, setDisplayErrorDialog] = useState({isVisible: false, errorMessage: ""});
    const [isLoading, setIsLoading] = useState(true);
    const {apiError} = useApiError();

    const showError = (message: string) => {
        setDisplayErrorDialog({
            isVisible: true,
            errorMessage: message
        });
    };

    const hideError = () => {
        setDisplayErrorDialog({
            isVisible: false,
            errorMessage: "",
        });
    };

    useEffect(() => {
        dispatch(setConfiguration(props.orderConfigurationId));
    }, [props.orderConfigurationId]);

    useEffect(() => {
        if (!props.orderConfigurationId)
            dispatch(resetState());
    }, [dispatch, props.orderConfigurationId]);

    useEffect(() => {
        if (salesItemData.query.get.status === "error") {
            showError(salesItemData.query.get.message);
            setIsLoading(false);
        } else if (salesItemData.query.get.status === "pending" || salesItemData.query.get.status === "idle") {
            setIsLoading(true);
        } else if (salesItemData.query.get.status === "success") {
            setIsLoading(false);
        }
    }, [salesItemData.query.get.status]);

    useEffect(() => {
        if (salesItemData.query.get.canExecute)
            dispatch(getConfiguration());
    }, [salesItemData.query.get.canExecute]);

    if (displayErrorDialog.isVisible) {
        return (
            <ErrorDialog
                message={displayErrorDialog.errorMessage}
                apiError={apiError}
                buttons={ErrorDialogButtons.getButtonsByError(apiError, () => {
                    dispatch(hideError);
                })}
            />
        );
    }

    if (isLoading) {
        return <LoadingIndicatorComponent/>;
    } else if (salesItemData === undefined || salesItemData.loadedData === undefined) {
        return <></>;
    } else {
        {
            return (
                <>
                {salesItemData.loadedData?.mtmNumber
                    ?   <GridWrapper>
                            <Grid container spacing={0.5}>
                                <Grid item xs={7}>
                                    <Label>
                                        {`${t("OverviewOrderNumberLabel")}:`}
                                    </Label>
                                </Grid>
                                <Grid item xs={5}>
                                    <div style={{display: "inline"}}>
                                        {` ${salesItemData.loadedData?.erpItemId}`}
                                        &ndash;
                                        {` ${salesItemData.loadedData?.erpItemPosition}`}
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={0.5}>
                                <Grid item xs={7}>
                                    {`${t("COMMON:MtmNumber")}:`}
                                </Grid>
                                <Grid item xs={5}>
                                    {` ${salesItemData.loadedData?.mtmNumber}`}
                                </Grid>
                            </Grid>
                        </GridWrapper>
                    :   <>
                            <Label>
                                {`${t("OverviewOrderNumberLabel")}:`}
                            </Label>
                            <div style={{display: "inline"}}>
                                {`${salesItemData.loadedData?.erpItemId} `}
                                &ndash;
                                {` ${salesItemData.loadedData?.erpItemPosition}`}
                            </div>
                        </>
                    }
                </>
            );
        }
    }
};

export default SalesItemDataComponent;
