import React, {useEffect} from "react";
import {QueryParamProvider} from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import {parse, stringify} from "query-string";
import {EditorComponent} from "../shared/configuration_editor/editor.component";
import {QuotationConfigurationsMenuComponent} from "./quotationconfiguration_menu/quotationconfigurationmenu.component";
import {setBasePath, setMode} from "../shared/configurationmodeSlice";
import {ConfigurationMode} from "../shared/configurationMode.model";
import {getBasePath} from "../shared/basePathGetter";
import {AppDispatch} from "../../app/redux_store";
import {useAppDispatch} from "../../app/hook";
import {
    QuotationConfigurationOverviewComponent
} from "./quotationconfiguration_overview/components/quotationconfiguration_overview.component";
import ConfigurationProductionOrderComponent
    from "../shared/configuration_productionorder/components/configuration_productionorder.component";
import {QuotationConfigurationsBreadcrumbs} from "./quotationconfigurations.breadcrumbs";
import ConfigurationEventsComponent
    from "configurations/shared/configuration_events/components/configuration_events.component";
import ConfigurationEventJsonComponent
    from "configurations/shared/configuration_events/components/configuration_eventJson.component";
import { Route, Routes } from "react-router-dom";


export const QuotationConfigurationsComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setMode(ConfigurationMode.QuotationConfiguration));
        dispatch(setBasePath(getBasePath()));
    }, []);

    return (
        <>
            <QueryParamProvider
                adapter={ReactRouter6Adapter}
                options={{
                    searchStringToObject: parse,
                    objectToSearchString: stringify,
                }}>
                <Routes>
                    <Route element={<QuotationConfigurationsMenuComponent/>} path="/"/>
                    <Route element={<QuotationConfigurationsMenuComponent />}/>
                    <Route element={<EditorComponent />} path="/quotationconfiguration/*" />
                    <Route element={<QuotationConfigurationOverviewComponent />} path="/quotationconfiguration/overview" />
                    <Route element={<ConfigurationProductionOrderComponent
                        breadcrumbComponent={<QuotationConfigurationsBreadcrumbs />} />}
                        path="/quotationconfiguration/productionorder"/>
                    <Route element={<ConfigurationEventsComponent
                        breadcrumbComponent={<QuotationConfigurationsBreadcrumbs />} />}
                        path="/quotationconfiguration/events"/>
                    <Route element={<ConfigurationEventJsonComponent
                        breadcrumbComponent={<QuotationConfigurationsBreadcrumbs />} />}
                        path="/quotationconfiguration/eventdetails"/>
                </Routes>
            </QueryParamProvider>
        </>
    );
};
