import {AccordionDetailsProps} from "@mui/material";
import React from "react";
import * as style from "./collapsable.details.style";

export const CollapsableDetails: React.FC<AccordionDetailsProps> = ({...props}) => {

    return (
        <>
            <style.AccordionDetailStyled {...props} />
        </>
    );
};