import React, {useEffect} from "react";
import {ThumbnailModel} from "../../../models/thumbnail.model";
import {ThumbnailComponent} from "../../../shared/components/buttons/thumbnail.component";
import quotationConfigurationImage from "./images/quotationconfigurations.jpg";
import {useTranslation} from "react-i18next";
import {ContentContainer} from "shared/components/container/ContentContainer";
import * as styleBreadcrumbs from "../../../shared/breadcrumbs/breadcrumbs_root.style";
import {Container} from "reactstrap";
import {ThumbnailWrapper} from "../../shared/configurationmenu.style";
import {QuotationConfigurationsBreadcrumbs} from "../quotationconfigurations.breadcrumbs";
import {AppDispatch} from "app/redux_store";
import {setBasePath, setMode} from "../../shared/configurationmodeSlice";
import {useAppDispatch} from "../../../app/hook";
import {ConfigurationMode} from "../../shared/configurationMode.model";
import {getBasePath} from "../../shared/basePathGetter";

export const QuotationConfigurationsMenuComponent = () => {
    const {t} = useTranslation();

    const thumbnails: ThumbnailModel[] = [
        new ThumbnailModel(t("QuotationOverview"), quotationConfigurationImage, "/quotationconfigurations/quotationconfiguration/overview"),
        new ThumbnailModel(t("QuotationCreate"), quotationConfigurationImage, "/quotationconfigurations/quotationconfiguration/create")
    ];

    const dispatch: AppDispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setMode(ConfigurationMode.QuotationConfiguration));
        dispatch(setBasePath(getBasePath()));
    }, []);

    return (
        <>
            <styleBreadcrumbs.BreadcrumbArea>
                <Container>
                    <QuotationConfigurationsBreadcrumbs/>
                </Container>
            </styleBreadcrumbs.BreadcrumbArea>
            <ContentContainer>
                <ThumbnailWrapper>
                    {thumbnails.map((thumb, i) => {
                        return (<ThumbnailComponent key={i} thumbnail={thumb}/>);
                    })}
                </ThumbnailWrapper>
            </ContentContainer>
        </>
    );
};
