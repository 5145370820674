import styled from "styled-components";
import colors from "../style/colors";

export const BreadcrumbArea = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
    color: ${colors.mediGray};
    font-size: small;
    border-bottom: 1px solid ${colors.mediLightGray};
    background: white;
    flex-shrink: 0;
    box-shadow: 0px 2px 3px rgb(174 188 197 / 50%);

    a {
        :hover,
        :focus,
        :active,
        :visited {
            text-decoration: none;
            color: ${colors.mediMagenta};
        }
    ;
        color: ${colors.mediMagenta};
    }
`;
