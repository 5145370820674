class URLHelper {
    private static _regExp = new RegExp("^(?:[a-z]+:)?//", "i");

    static getURL(path: string) {
        if (this._regExp.test(path))
            return new URL(path);
        else
            return new URL(path, window.location.origin);
    }
}

export {URLHelper};

declare global {
    interface URL {
        toStringRelative(): string;

        toStringRelativeLast(): string;
    }
}

URL.prototype.toStringRelative = function (): string {
    const absolute = this.toString();
    return absolute.replace(window.location.origin, "");
};

URL.prototype.toStringRelativeLast = function (): string {
    const absolute: string = this.toString();
    return absolute.substring(absolute.lastIndexOf("/") + 1);
};
