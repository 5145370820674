import React from "react";
import styled from "styled-components";
import {LoadingIndicatorComponent} from "../loading_indicator/loading_indicator.component";
import * as style from "./loading_overlay.style";

interface ILoadingOverlayProps {
    message?: string;
}

export const LoadingOverlayComponent: React.FC<ILoadingOverlayProps> = (props) => {
    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <style.OuterWrapper>
            <style.InnerWrapper>
                <LoadingIndicatorComponent/>{// eslint-disable-next-line no-irregular-whitespace
                props.message ? ` ${props.message} ` : ""}
            </style.InnerWrapper>
        </style.OuterWrapper>);
};

export const LoadingOverlayContainer = styled.div`
  position: relative;
  height: 100%;
`;
