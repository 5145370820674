import {StepDescriptor} from "./step_descriptor.model";

export enum ConfigurationStepEnum {
    Create = 0,
    ProductPreSelection = 1,
    Measurements = 2,
    AdditionsSelection = 3,
    ProductionInstructionsSelection = 4,
    Completion = 5
}

export const ConfigurationStepValues: Array<
    StepDescriptor
> = [
    {
        value: ConfigurationStepEnum.Create,
        text: "OrderStepCreate",
        url: "create"
    },
    {
        value: ConfigurationStepEnum.ProductPreSelection,
        text: "OrderStepPreselection",
        url: "productpreselection"
    },
    {
        value: ConfigurationStepEnum.Measurements,
        text: "OrderStepMeasurements",
        url: "measurements"
    },
    {
        value: ConfigurationStepEnum.AdditionsSelection,
        text: "OrderStepAdditions",
        url: "additionsselection"
    },
    {
        value: ConfigurationStepEnum.ProductionInstructionsSelection,
        text: "OrderStepProductionInstructions",
        url: "productinstructions"
    },
    {
        value: ConfigurationStepEnum.Completion,
        text: "OrderStepCompletion",
        url: "completion"
    }
];
