import React, {useEffect, useState} from "react";
import {Step, StepLabel, Stepper} from "@mui/material";
import {useTranslation} from "react-i18next";
import {IStepChange} from "../models/stepChange.model";
import {StepDescriptor} from "../models/step_descriptor.model";
import styled from "styled-components";
import {Container} from "reactstrap";
import {useAppSelector} from "../../../../app/hook";
import {ConfigurationStepEnum} from "../models/configuration_step.enum";
import {PassOrderConfigurationProvider, useReadonly} from "shared/provider/orderconfiguration/orderconfiguration.provider";
import {usePendingChanges} from "shared/provider/orderconfiguration/orderconfiguration.provider";

const StepperWrapper = styled.div`
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  z-index: 10;
  box-shadow: 0px 2px 3px rgb(174 188 197 / 50%);
`;

interface ConfigurationStepperComponentProps {
    onStepChangeExecute?: (stepChange: IStepChange) => void;
    orderConfigurationStateStep: ConfigurationStepEnum;
}

export const ConfigurationStepperComponent: React.FC<ConfigurationStepperComponentProps> = (props) => {

    const state = useAppSelector((state) => state.configuration_stepper);
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const {isReadonly} = useReadonly();
    const {t} = useTranslation();
    const {hasPendingChanges} = usePendingChanges();

    useEffect(() => {
        if (state.currentStep !== null && state.currentStep !== undefined) {
            const index = state.allSteps.findIndex(step => step.value === state.currentStep.value);
            setCurrentStepIndex(index < 0 ? 0 : index);
        }
    }, [state.currentStep]);

    const renderStep = (step: StepDescriptor, index: number) => {
        return props.onStepChangeExecute && (isReadonly || state.currentStep.value > step.value) ? (
            <Step completed={index < (isReadonly ? props.orderConfigurationStateStep : currentStepIndex)} key={step.value}
                  onClick={() => {
                    if (hasPendingChanges)
                    {
                        const leavePage = window.confirm(t("LeavePageUnsavedChangesMessage"));
                        if (!leavePage)   
                            return;
                        
                        PassOrderConfigurationProvider.setPendingChanges(false);
                    }
                    props.onStepChangeExecute({currentStep: state.currentStep.value, targetStep: step});
                    }}>
                <StepLabel>{t(step.text)}</StepLabel>
            </Step>
        ) : (
            <Step key={step.value}>
                <StepLabel>{t(step.text)}</StepLabel>
            </Step>
        );
    };

    if (!state.currentStep) {
        return null;
    }

    return (

        <StepperWrapper>
            <Container>
                <Stepper activeStep={currentStepIndex} nonLinear={true}>
                    {state.allSteps.map((step, index) => {
                        return renderStep(step, index);
                    })}
                </Stepper>
            </Container>
        </StepperWrapper>
    );
};
