import {ConfigurationState} from "models/configuration_state.enum";
import {StepDescriptor} from "../models/step_descriptor.model";
import {ConfigurationStepEnum, ConfigurationStepValues} from "../models/configuration_step.enum";
import {URLHelper} from "shared/extensions/URLHelper";
import {hasValue} from "services/validation.service";
import IConfigurationId from "models/configurations/configurationid";
import {ProductionType} from "models/production_type.enum";

export const determineTargetStep = (orderConfiguration: IConfigurationId, sku?: string): StepDescriptor => {
    let targetStep: StepDescriptor;

    // determine step to open
    switch (orderConfiguration.state) {
        case ConfigurationState.New: {
            targetStep = ConfigurationStepValues[1];

            const relativeTargetUrl = URLHelper.getURL(targetStep.url);
            relativeTargetUrl.searchParams.appendIf(hasValue(sku), "sku", sku);

            targetStep = {...targetStep, url: relativeTargetUrl.toStringRelativeLast()};
            }
            break;

        case ConfigurationState.InProgressProductPreselected:
            targetStep = ConfigurationStepValues[1];
            break;

        case ConfigurationState.InProgressMeasured:
            if (orderConfiguration.productionType == null || orderConfiguration.productionType == ProductionType.MadeToMeasure)
                targetStep = ConfigurationStepValues[2];
            else
                targetStep = ConfigurationStepValues[3];
            break;

        case ConfigurationState.InProgressProductLineSelected:
            break;

        case ConfigurationState.InProgressProductSelected:
            break;

        case ConfigurationState.InProgressAdditionsSelected:
            targetStep = ConfigurationStepValues[3];
            break;

        case ConfigurationState.InProgressProductionInstructionsSelected:
            targetStep = ConfigurationStepValues[4];
            break;

        case ConfigurationState.Completed:
            targetStep = ConfigurationStepValues[5];
            break;

        case ConfigurationState.Finalized:
            targetStep = ConfigurationStepValues[5];
            break;
    }

    return targetStep;
};

export const determineNextStep = (currentStep: ConfigurationStepEnum, productionType: ProductionType): StepDescriptor => {
    let targetStep: StepDescriptor;

    // determine next step
    switch (currentStep) {
        case ConfigurationStepEnum.Create:
            targetStep = ConfigurationStepValues[1];
            break;

        case ConfigurationStepEnum.ProductPreSelection:
            if(productionType == ProductionType.MadeToMeasure)
                targetStep = ConfigurationStepValues[2];
            else
                targetStep = ConfigurationStepValues[3];
            break;

        case ConfigurationStepEnum.Measurements:
            targetStep = ConfigurationStepValues[3];
            break;

        case ConfigurationStepEnum.AdditionsSelection:
            targetStep = ConfigurationStepValues[4];
            break;

        case ConfigurationStepEnum.ProductionInstructionsSelection:
            targetStep = ConfigurationStepValues[5];
            break;

        case ConfigurationStepEnum.Completion:
            targetStep = null;
            break;
    }

    return targetStep;
};
