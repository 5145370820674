import {DocumentModel} from "./documentModel";

export class ProductionInstructionModel {
    constructor(
        public instruction: string,
        public symbol: string,
        public freeText: string,
        public documents: DocumentModel[]
    ) {
    }
}