import {ConfigurationCreateState} from "./create.model";
import {hasValue} from "../../../../../../services/validation.service";
import IConfigurationCreateModel from "models/configurations/configuration_create";

export const validateState = (state: ConfigurationCreateState) => {
    // validate input data
    return (hasValue(state.actualData.baseData.erpTenant)
        &&
        state.actualData.baseData.erpGroupCompanyNumber > 0
        &&
        hasValue(state.actualData.baseData.customerNumber)
        &&
        (state.actualData.baseData.deliveryAddressNumber == null || state.actualData.baseData.deliveryAddressNumber >= 0)
        &&
        hasValue(state.actualData.baseData.erpItemId)
        &&
        state.actualData.baseData.erpItemPosition > 0);
};

export const toCreateModel = (state: ConfigurationCreateState): IConfigurationCreateModel => {
    return {
        erpTenant: state.actualData.baseData.erpTenant,
        erpGroupCompanyNumber: state.actualData.baseData.erpGroupCompanyNumber,
        customerNumber: state.actualData.baseData.customerNumber,
        deliveryAddressNumber: state.actualData.baseData.deliveryAddressNumber,
        erpItemId: state.actualData.baseData.erpItemId,
        erpItemPosition: state.actualData.baseData.erpItemPosition,
        orderConfigurationId: state.actualData.configurationId,
        commission: state.actualData.baseData.commission,
        mtmNumber: state.actualData.baseData.mtmNumber,
    };
};

export const mustCallApiCreate = (state: ConfigurationCreateState): boolean => {
    return (state.loadedData.stepState.configurationId === null);
};