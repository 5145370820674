import {AccordionSummary, styled} from "@mui/material";
import colors from "shared/style/colors";

export const AccordionSummaryStyled = styled(AccordionSummary)({
    color: colors.mediGray,
    borderBottom: `1px solid ${colors.mediGray}`,

    "&.Mui-expanded .MuiAccordionSummary-expandIconWrapper": {
        color: colors.mediMagenta
    },
    "&.Mui-expanded": {
        minHeight: "48px",
        borderBottom: `1px solid ${colors.mediMagenta}`
    },
    "&.MuiAccordionSummary-root > .MuiAccordionSummary-content": {
        fontFamily: "SkolarSansPeSemibold",
        fontSize: "18px",
    },
    "&.MuiAccordionSummary-root > .MuiAccordionSummary-content.Mui-expanded": {
        margin: "0px",
        color: colors.mediMagenta,
    },
    "&.MuiAccordionSummary-root": {
        padding: "0",
    },
});
