import {createSlice} from "@reduxjs/toolkit";
import {CustomerDataState} from "../models/customerdata.model";
import {loadCustomerData} from "./customerdata.thunk";

const initialState: CustomerDataState = {
    loadedData: undefined,
    query: {
        get: {
            status: "success",
            canExecute: false
        }
    }
};

export const ConfigurationCustomerDataSlice = createSlice({
    name: "customerdata",
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadCustomerData.pending, (state) => {
                state.query.get.status = "pending";
                state.query.get.canExecute = false;
            })
            .addCase(loadCustomerData.rejected, (state, action) => {
                state.query.get.status = "error";
                if (action.error.code === "404") {
                    state.loadedData = undefined;
                } else {
                    state.query.get.message = action.error.message;
                }
            })
            .addCase(loadCustomerData.fulfilled, (state, action) => {
                state.query.get.status = "success";
                const data = action.payload.getData();
                state.loadedData = data;
            });
    }
});

export const {
    resetState
} = ConfigurationCustomerDataSlice.actions;
export default ConfigurationCustomerDataSlice.reducer;
