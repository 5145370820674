import styled from "styled-components";
import colors from "../../../style/colors";

export const RadioBoxHeader = styled.div`
    margin-top: 20px;
    font-weight: bold;
    font-size: medium;
    color: ${colors.mediGray};
`;

export const Description = styled.div`
    padding-top: 10px;
    color: ${colors.mediGray};
`;
