import {CustomerData} from "configurations/shared/configuration_editor/models/customerdata.model";
import {ApiResult} from "../shared/networking/api_result";
import HttpService from "../shared/networking/http_service";
import {getConfig} from "./config.service";

class CustomerDataServiceAPI extends HttpService {

    public async getCustomerData(tentant: number, customerNumber: number, deliveryAddressNumber?: number): Promise<ApiResult<CustomerData>> {
        const path = `/api/customerdata/${tentant}/${customerNumber}${deliveryAddressNumber ? "/" + deliveryAddressNumber : ""}`;
        return this.get<CustomerData>(path);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const CustomerDataService = new CustomerDataServiceAPI(TimeoutMilliseconds);

export default CustomerDataService;
