import React, {DOMAttributes} from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./App.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "reactjs-popup/dist/index.css";
import {publicClientApplication} from "./services/aad.service";
import {MsalProvider} from "@azure/msal-react";
import DefaultGuard from "./shared/guards/default.guard";
import "./i18n/i18n";
import {Additions, Massmask, ProductionInstruction} from "@medi-mtm/components";
import {LicenseInfo} from "@mui/x-license";

LicenseInfo.setLicenseKey("fb628b87ff14bf63497770ead67af1ccTz04NTg1MCxFPTE3NDEzMjc3NjAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <MsalProvider instance={publicClientApplication}>
        <DefaultGuard requiredRole="ors.user">
            <App/> 
        </DefaultGuard>
    </MsalProvider>
);

reportWebVitals();

type CustomEvents<K extends string> = { [key in K]: (event: CustomEvent) => void };
// eslint-disable-next-line
type CustomElement<T, K extends string> = Partial<T & DOMAttributes<T> & { children: any } & CustomEvents<`on${K}`>> & {
    // eslint-disable-next-line
    ref?: any,
    // eslint-disable-next-line
    key?: any
};

declare global {
    namespace JSX {
        interface IntrinsicElements {
            ["x-massmask"]: CustomElement<Massmask, "">;
            ["x-additions"]: CustomElement<Additions, "">;
            ["x-productioninstruction"]: CustomElement<ProductionInstruction, "">;
        }
    }
}
