export const isNotEmpty = (value: string) => {
    return (value !== "");
};

// eslint-disable-next-line
export const isNotUndefinied = (value: any) => {
    return (value !== undefined);
};

// eslint-disable-next-line
export const doesExist = (value: any): boolean => {
    return (value !== null && value !== undefined);
};

// eslint-disable-next-line
export const hasValue = (value: any): boolean => {
    return (doesExist(value) && isNotEmpty(value));
};

export const doesNotStartOrEndWithWhitespace = (content: string): boolean => {
    return /^[^\s]+(\s+[^\s]+)*$/.test(content);
};

export const isNumeric = (value: string) => {
    const result = parseFloat(value);
    return !Number.isNaN(result);
};