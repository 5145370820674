import React from "react";
import styled from "styled-components";
import colors from "../../style/colors";

const ContentArea = styled.div`
  color: ${colors.mediGray};
  flex-grow: 1;
  flex-shrink: 0;
  background: whitesmoke;
`;

const ContentHolder = styled.div`
  margin-bottom: 10px;
`;

interface ExtRouteComponentProps {
    children?: React.ReactNode
}

export const ContentComponent = (props: ExtRouteComponentProps) => {
    return (
        <ContentArea>
            <ContentHolder>{props.children}</ContentHolder>
        </ContentArea>
    );
};
