import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "app/redux_store";
import ConfigurationService from "services/configuration.service";
import {handleResponseErrors} from "shared/networking/error_handling";
import {toCreateModel, toCreateModelForUpdateSKU} from "./productpreselection.reducer";
import {determineNextStep} from "configurations/shared/configuration_editor/redux/editor.reducer";
import {ConfigurationStepEnum} from "configurations/shared/configuration_editor/models/configuration_step.enum";
import {navigateTo} from "configurations/shared/configuration_editor/redux/editor.thunks";

export const preselectProductPostSkU = createAsyncThunk(
    "configuration/productpreselection/postSKU",
    async (_, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.postSKU(
            state.configuration_productpreselection.loadedData.configuration.id,
            toCreateModelForUpdateSKU(state.configuration_productpreselection),
            mode);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);

export const preselectProduct = createAsyncThunk(
    "configuration/productpreselection",
    async (_, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.preselectProduct(
            state.configuration_productpreselection.loadedData.configuration.id,
            toCreateModel(state.configuration_productpreselection),
            mode);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);

export const getPreselectionConfigurationOptions = createAsyncThunk(
    "configuration/productpreselectionconfiguration/get",
    async (id: string, {getState, rejectWithValue}) => {
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.getPreselectedProductConfigurationOptions(id, mode);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);

export const getConfiguration = createAsyncThunk(
    "configuration/productpreselection/get",
    async (id: string, {getState, rejectWithValue}) => {
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.getPreselectProduct(id, mode);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);

export const skipPreselectProduct = createAsyncThunk(
    "configuration/productpreselection/skipGet",
    // eslint-disable-next-line
    async () => {
    }
);

export const cloneMtmNumber = createAsyncThunk(
    "configuration/productpreselection/cloneMtmNumber",
    async ({ mtmNumber, targetId }: { mtmNumber: string, targetId: string }, {getState, rejectWithValue}) => {
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.cloneMtmNumber(mtmNumber, targetId, mode);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);

export const gotoNext = createAsyncThunk(
    "configuration/productpreselection/gotonext",
    async (_, {getState, dispatch}) => {
        const state = getState() as RootState;

        if(state.configuration_editor.loadedData.orderConfigurationState.readonly) {
            dispatch(navigateTo({
                targetStep: determineNextStep(ConfigurationStepEnum.ProductPreSelection, state.configuration_productpreselection.loadedData.configuration.productionType),
                configurationId: state.configuration_editor.loadedData.configurationId,
                productConfigurationId: state.configuration_editor.loadedData.productConfigurationId,
                productConfigurationRunId: state.configuration_editor.loadedData.productConfigurationRunId
            }));
            return;
        }
        await dispatch(preselectProduct());
    }
);
