import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ProductionType} from "models/production_type.enum";
import {ConfigurationStepEnum} from "configurations/shared/configuration_editor/models/configuration_step.enum";
import {IStepChange} from "configurations/shared/configuration_editor/models/stepChange.model";
import {ConfigurationAdditionsState} from "./additions.model";
import {evaluateAdditions, getAdditionsSelection, gotoNext, setAdditions} from "./additions.thunks";
import {StepDescriptor} from "../../../models/step_descriptor.model";
import {setErrorInState} from "shared/networking/error_handling";
import { PassOrderConfigurationProvider } from "shared/provider/orderconfiguration/orderconfiguration.provider";
import { AdditionsEvaluatedEvent, AdditionsLoadedEvent, ComponentDataEvent } from "@medi-mtm/components";
import { calculateEvaluateCanExecute, updateComponentData } from "./additions.reducer";

const initialState: ConfigurationAdditionsState = {
    loadedData: {
        stepState: {
            currentStep: ConfigurationStepEnum.AdditionsSelection,
            configurationId: null,
        },
        configuration: null,
        isNavigating: false,
    },
    actualData: {
        productConfigurationId: null,
        productConfigurationRunId: null,
        doSetAdditions: false,
        handleStepChange: false,
        isRestoreCycle: false,
        isValid: false,
    },
    command: {
        setAdditions: {
            status: "idle",
            canExecute: false
        },
        evaluateAdditions: {
            status: "idle",
            canExecute: true
        },
        goBackToProductSelect: {
            status: "idle",
            canExecute: false
        }
    },
    query: {
        get: {
            status: "idle",
            canExecute: false
        }
    }
};

export const configurationAdditionsSlice = createSlice({
    name: "configuration/additions",
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            PassOrderConfigurationProvider.setPendingChanges(false);
        },
        resetSetAdditions: (state) => {
            state.command.setAdditions.status = "idle";
            state.loadedData.stepState.targetStep = null;
        },
        resetGotoProductSelection: (state) => {
            state.command.goBackToProductSelect.status = "idle";
            state.loadedData.stepState.targetStep = null;
        },
        resetStepChange: (state) => {
            state.actualData.handleStepChange = false;
            state.loadedData.stepState.targetStep = null;
        },
        setIsNavigate: (state) => {
            state.loadedData.isNavigating = true;
        },
        resetRestoreAdditions: (state) => {
            state.actualData.isRestoreCycle = false;
        },
        updateCanEvaluate: (state, action: PayloadAction<ComponentDataEvent>) => {
            const component = action.payload;
            updateComponentData(state, component);
            state.command.evaluateAdditions.canExecute = calculateEvaluateCanExecute(state);
        },
        updateOnAdditionsLoaded: (state, action: PayloadAction<AdditionsLoadedEvent>) => {
            const loadedEvent = action.payload;
            updateComponentData(state, loadedEvent.componentData);
            state.command.evaluateAdditions.status = "success";
            state.command.evaluateAdditions.canExecute = calculateEvaluateCanExecute(state);
        },
        updateOnAdditionsEvaluated: (state, action: PayloadAction<AdditionsEvaluatedEvent>) => {
            const evaluatedEvent = action.payload;
            updateComponentData(state, evaluatedEvent.componentData);
            state.command.evaluateAdditions.status = "success";
            state.command.evaluateAdditions.canExecute = calculateEvaluateCanExecute(state);
            state.actualData.doSetAdditions = true;
        },        
        changeStep: (state, action: PayloadAction<IStepChange>) => {
            if (!(action.payload.targetStep.value === ConfigurationStepEnum.Measurements
                && state.loadedData.configuration.productionType === ProductionType.Serial)) {
                state.loadedData.stepState.targetStep = action.payload.targetStep;
                state.actualData.handleStepChange = true;
            }
        },
        goBack: (state, action: PayloadAction<StepDescriptor>) => {
            state.loadedData.stepState.targetStep = action.payload;
            state.actualData.handleStepChange = true;
        },
    }, extraReducers: (builder) => {
        builder
            // getAdditionsSelection
            .addCase(getAdditionsSelection.pending, (state) => {
                state.query.get.status = "pending";
                state.query.get.canExecute = false;
            })
            .addCase(getAdditionsSelection.rejected, (state, action) => {
                setErrorInState(state.query.get, action);
            })
            .addCase(getAdditionsSelection.fulfilled, (state, action) => {
                state.query.get.status = "success";
                const data = action.payload.getData();
                state.actualData.productConfigurationId = data.productConfigurationId;
                state.actualData.productConfigurationRunId = data.productConfigurationRunId;
                state.loadedData.configuration = data;
                state.actualData.isRestoreCycle = data.state >= 6;
            })

            // evaluateAdditions
            .addCase(evaluateAdditions.pending, (state) => {
                state.command.evaluateAdditions.status = "pending";
            })
            .addCase(evaluateAdditions.fulfilled, () => {
                // "success" state is set in "updateEvaluationResult"
            })

            // setAdditions
            .addCase(setAdditions.pending, (state) => {
                state.command.setAdditions.status = "pending";
                state.command.setAdditions.canExecute = false;
            })
            .addCase(setAdditions.rejected, (state, action) => {
                setErrorInState(state.command.setAdditions, action);
            })
            .addCase(setAdditions.fulfilled, (state) => {
                state.command.setAdditions.status = "success";
            })

            // gotoNext
            .addCase(gotoNext.pending, () => {
                //state.actualData.canGoNext = false;
            })
            .addCase(gotoNext.fulfilled, () => {
                //state.actualData.canGoNext = true;
            });
    }
});

export const {
    resetState,
    resetGotoProductSelection,
    resetSetAdditions,
    resetStepChange,
    resetRestoreAdditions,
    setIsNavigate,
    updateCanEvaluate,
    updateOnAdditionsLoaded,
    updateOnAdditionsEvaluated,
    changeStep,
    goBack
} = configurationAdditionsSlice.actions;

export default configurationAdditionsSlice.reducer;
