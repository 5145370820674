import {createAsyncThunk} from "@reduxjs/toolkit";
import {handleResponseErrors} from "../../../../shared/networking/error_handling";
import Configurations_overviewService from "../../../../services/configurations_overview_service";
import {RootState} from "../../../../app/redux_store";

export const getProductionOrder = createAsyncThunk(
    "configuration/configuration_productionorder/getProductionOrder",
    async (_, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const response = await Configurations_overviewService.getProductionOrder(state.configuration_productionorder.actualData.configurationId);
        return handleResponseErrors(response, "ProductionOrder", rejectWithValue);
    }
);
