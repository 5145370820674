import {ConfigurationsEventsState} from "./configurations_events.model";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GridFilterModel, GridPaginationModel} from "@mui/x-data-grid-pro";
import {setErrorInState} from "../../../../shared/networking/error_handling";
import {getEvent, getEvents, updateEvents} from "./configurations_events.thunks";

const initialState: ConfigurationsEventsState = {
    loadedData: {
        events: null,
        selectedEvent: null,
    },
    actualData: {
        configurationId: "",
        eventId: "",
        pagination: {
            page: 1,
            pageSize: 20
        },
        filter: null
    },
    query: {
        getEvents: {status: "idle", canExecute: true},
        getEvent: {status: "idle", canExecute: true}
    }
};


export const configurationEventsSlice = createSlice({
    name: "configuration/configuration_events",
    initialState,
    reducers: {
        setConfigurationId: (state, action) => {
            state.actualData.configurationId = action.payload;
        },
        setEventId: (state, action) => {
            state.actualData.eventId = action.payload;
        },
        setPagination: (state, action: PayloadAction<GridPaginationModel>) => {
            state.actualData.pagination = action.payload;
        },
        setFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.filter = action.payload;
        },
        resetEventsLoadedData: (state) => {
            state.loadedData = {...initialState.loadedData};
        }
    },
    extraReducers: (builder) => {
        builder
            // Get Events with pagination
            .addCase(getEvents.pending, (state) => {
                state.query.getEvents.status = "pending";
                state.query.getEvents.canExecute = false;
            })
            .addCase(getEvents.rejected, (state, action) => {
                setErrorInState(state.query.getEvents, action);
            })
            .addCase(getEvents.fulfilled, (state, action) => {
                state.query.getEvents.status = "success";
                state.loadedData.events = action.payload.getData();
            })

            // UpdateEvents
            .addCase(updateEvents.pending, (state) => {
                state.query.getEvents.canExecute = false;
            })
            .addCase(updateEvents.rejected, (state, action) => {
                state.query.getEvents.message = action.error.message;
            })
            .addCase(updateEvents.fulfilled, (state, action) => {
                state.loadedData.events = action.payload.getData();
            })

            //Get Single Event
            .addCase(getEvent.pending, (state) => {
                state.query.getEvent.status = "pending";
                state.query.getEvent.canExecute = false;
            })
            .addCase(getEvent.rejected, (state, action) => {
                setErrorInState(state.query.getEvents, action);
            })
            .addCase(getEvent.fulfilled, (state, action) => {
                state.query.getEvent.status = "success";
                state.loadedData.selectedEvent = action.payload.getData();
            });
    }
});

export const {
    setEventId,
    setConfigurationId,
    setPagination,
    setFilters,
    resetEventsLoadedData
} = configurationEventsSlice.actions;

export default configurationEventsSlice.reducer;
