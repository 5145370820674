import React, {useEffect, useState} from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import styled from "styled-components";
import theme from "../../style/theme";
import colors from "shared/style/colors";
import Placeholder from "../../images/placeholder.svg";

interface RadioGroupProps {
    name: string;
    // eslint-disable-next-line
    data: any[];
    displayedProperties: string[];
    valueProperty: string;
    isRequired?: boolean;
    // eslint-disable-next-line
    selectedItem?: any;
    // eslint-disable-next-line
    onSelect: (selectedItem: any) => void;
    showImage?: boolean;
    displayPattern?: string;
}

interface CustomFormControlLabelProps {
    checked: boolean;
}

const Image = styled.img`
    width: 174px;
`;


const CustomRadio = styled(Radio)`
    visibility: hidden;
    position: absolute;
`;

const CustomFormControlLabel = styled(FormControlLabel)<CustomFormControlLabelProps>`
    && {
        width: 178px;
        margin-right: 10px;
        margin-left: 0px;
        word-break: break-word;
        border: 2px solid ${(props) => (props.checked ? colors.mediMagenta : "darkgrey")};
        display: flex;
        align-items: center;
        background: #cccccc;

        &:hover {
            border-color: ${colors.mediMagenta};
        }

        & > .MuiTypography-root {
            font-size: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${(props) => (props.checked ? colors.mediMagenta : "#354e60")};
            text-align: center;
            color: white;
            width: 176px;
            height: 100%;
            padding: 5px;
        }

        & > .MuiButtonBase-root {
            position: absolute;
        }
    }
`;


const RadioBoxComponent: React.FC<RadioGroupProps> = ({
                                                          data,
                                                          displayedProperties,
                                                          valueProperty,
                                                          name,
                                                          onSelect,
                                                          isRequired,
                                                          selectedItem,
                                                          showImage = true,
                                                          displayPattern = undefined
                                                      }) => {
    const [selectedIndex, setSelectedIndex] = useState(
        data.indexOf(selectedItem)
    );


    useEffect(() => {
        const index = data.findIndex(item => item[valueProperty] === selectedItem);
        setSelectedIndex(index);
    }, [selectedItem, data]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const index = Number((e.target as HTMLInputElement).value);
        setSelectedIndex(index);
        onSelect(data[index][valueProperty]);
    };

    function formatString(pattern, displayedProperties) {
        return pattern.replace(/{(\d+)}/g, (match, index) => displayedProperties[index] || "");
    }

    return (
        <FormControl
            variant="standard"
            component="fieldset"
            style={{minWidth: theme.form.minWidth}}
            required={isRequired}>
            <FormLabel>{name}</FormLabel>
            <RadioGroup
                aria-describedby={`${name}_description`}
                row={true}
                aria-label={name}
                name={name}
                value={`${selectedIndex}`}
                onChange={handleChange}
            >
                {data.map((entry, index) => (
                    <CustomFormControlLabel
                        key={index}
                        labelPlacement={"bottom"}
                        value={`${index}`}
                        checked={selectedIndex === index}
                        control={
                            <>
                                <CustomRadio
                                    checked={selectedIndex === index}
                                    value={`${index}`}
                                />
                                {showImage === true && <Image src={Placeholder}/>}
                            </>
                        }
                        label={displayPattern !== undefined ? formatString(displayPattern, displayedProperties.map((property) => entry[property])) : displayedProperties.map((property) => entry[property])}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioBoxComponent;
