import React from "react";
import {PrimaryButtonComponent} from "../../shared/shared";
import {publicClientApplication} from "./../../services/aad.service";
import * as style from "./account_area.style";
import {useTranslation} from "react-i18next";
import LogoutIcon from "@mui/icons-material/Logout";
import {HideOnExclusiveMode} from "sales/hideonexclusivemode.component";


const AccountAreaComponent = () => {
    const accounts = publicClientApplication.getAllAccounts();
    const account = accounts[0];
    const {t} = useTranslation();
    const inIframe = (window.location !== window.parent.location);

    return (
        <style.AccountAreaBox>
            <style.AccountInfoBox>
                <style.AccountNameBox>
                    <style.AccountName>{account.name}</style.AccountName>
                    <style.AccountUserName>{account.username}</style.AccountUserName>
                </style.AccountNameBox>
            </style.AccountInfoBox>
            <HideOnExclusiveMode>
                <PrimaryButtonComponent
                    onClick={() => inIframe ? publicClientApplication.logoutPopup() : publicClientApplication.logoutRedirect({
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        onRedirectNavigate: (url) => {
                            // navigation after local logout
                            return true;
                        }
                    })}>
                    <style.LogoutButtonContent>
                        <LogoutIcon/> {t("AccountAreaButtonLogout")}
                    </style.LogoutButtonContent>
                </PrimaryButtonComponent>
            </HideOnExclusiveMode>
        </style.AccountAreaBox>
    );
};

export default AccountAreaComponent;
