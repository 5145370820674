import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "app/redux_store";
import React from "react";
import ConfigurationService from "services/configuration.service";
import {Additions} from "@medi-mtm/components";
import {handleResponseErrors} from "shared/networking/error_handling";
import {mustCallApi} from "./additions.reducer";
import {determineNextStep} from "configurations/shared/configuration_editor/redux/editor.reducer";
import {ConfigurationStepEnum} from "configurations/shared/configuration_editor/models/configuration_step.enum";
import {navigateTo} from "configurations/shared/configuration_editor/redux/editor.thunks";
import { PassOrderConfigurationProvider } from "shared/provider/orderconfiguration/orderconfiguration.provider";


export const setAdditions = createAsyncThunk(
    "configuration/additions/set",
    async (_, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const mode = (getState() as RootState).configuration_mode.mode;
        if (!mustCallApi(state.configuration_additions))
            return {}; // skip api call

        const response = await ConfigurationService.setAdditions(state.configuration_additions.loadedData.configuration.id, {
                id: state.configuration_additions.actualData.productConfigurationId,
                runId: state.configuration_additions.actualData.productConfigurationRunId ?? 1
            },
            mode);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);

export const getAdditionsSelection = createAsyncThunk(
    "configuration/additions/get",
    async (id: string, {getState, rejectWithValue}) => {
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.getAdditionsSelection(id, mode);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);

export const evaluateAdditions = createAsyncThunk(
    "configuration/additions/evaluate",
    async (additions: React.MutableRefObject<Additions>) => {
        await additions.current.evaluateAdditions();
    }
);

export const gotoNext = createAsyncThunk(
    "configuration/additions/gotonext",
    async (additions: React.MutableRefObject<Additions>, {getState, dispatch}) => {
        const state = getState() as RootState;

        if(state.configuration_editor.loadedData.orderConfigurationState.readonly) {
            dispatch(navigateTo({
                targetStep: determineNextStep(ConfigurationStepEnum.AdditionsSelection, state.configuration_additions.loadedData.configuration.productionType),
                configurationId: state.configuration_editor.loadedData.configurationId,
                productConfigurationId: state.configuration_editor.loadedData.productConfigurationId,
                productConfigurationRunId: state.configuration_editor.loadedData.productConfigurationRunId
            }));
            return;
        }

        if (state.configuration_additions.command.evaluateAdditions.canExecute && PassOrderConfigurationProvider.hasPendingChanges) {
            await dispatch(evaluateAdditions(additions));
            return;
        }
        await dispatch(setAdditions());
    }
);
