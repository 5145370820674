import styled from "styled-components";
import colors from "../../style/colors";
import {Button} from "@mui/material";

export const IconButton = styled(Button)`
  min-width: 10px;
  color: ${colors.mediMagenta};

  &:disabled {
    color: ${colors.mediMagentaDisabled};
  }
`;
