import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../../../../../../../app/redux_store";
import {handleResponseErrors} from "../../../../../../../shared/networking/error_handling";
import FilterService from "../../../../../../../services/filter.service";
import {Filter} from "../../../measurements/models/responses";
import {ApiResult} from "../../../../../../../shared/networking/api_result";

const mapSelectedOptions = (filterGroups) => {
    let values = [];
    filterGroups.forEach((filterGroup) => {
        const mappedFilterGroupOptions = filterGroup.filterGroupOptions
            .filter((option) => option.selectedValue !== "")
            .map((selectedOption) => ({
                type: selectedOption.type,
                value: selectedOption.selectedValue,
            }));
        values = values.concat(mappedFilterGroupOptions);
    });
    return values;
};

export const getFilter = createAsyncThunk<ApiResult<Filter>, string>(
    "filter/getFilter",
    async (filterId: string) => {
        const response = await FilterService.getFilter(filterId);
        return handleResponseErrors(response, "Filter");
    }
);

export const getFilterGroup = createAsyncThunk(
    "filter/getFilterGroup",
    async (groupId: string, {getState}) => {
        const filterState = (getState() as RootState).filter;
        const filterId = filterState.filterId;
        const values = mapSelectedOptions(filterState.filterGroups);
        const data = {
            productMasterId: "101",
            values: values,
        };
        const response = await FilterService.getFilterGroup(filterId, data);
        handleResponseErrors(response, "Filter");
        return {
            filterGroupResponse: response,
            groupId: groupId,
        };
    }
);

export const restoreFilter = createAsyncThunk(
    "filter/restoreFilter",
    async (_, {getState}) => {
        const filterState = (getState() as RootState).filter;
        const preselectionState = (getState() as RootState).configuration_productpreselection;
        const filterId = filterState.filterId;
        const productMasterId = preselectionState.loadedData.configuration.erpGroupCompanyNumber;
        const sku = preselectionState.loadedData.configuration.sku;
        const selectedSide = preselectionState.actualData.selectedSide;
        const selectedFootStyle = preselectionState.actualData.selectedFootStyle;
        const response = await FilterService.restoreFilter(filterId, productMasterId, sku, selectedSide, selectedFootStyle);
        return handleResponseErrors(response, "Filter");
    }
);

export const getProduct = createAsyncThunk(
    "filter/getProduct",
    async (_, {getState}) => {
        const filterState = (getState() as RootState).filter;
        const values = mapSelectedOptions(filterState.filterGroups);
        const data = {
            productMasterId: "101",
            values: values,
        };
        const response = await FilterService.getProducts(data);
        return handleResponseErrors(response, "Filter");
    }
);
