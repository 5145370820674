import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ConfigurationsProductionOrderState} from "./configurations_productionorder.model";
import {getProductionOrder} from "./configurations_productionorder.thunks";
import {setErrorInState} from "shared/networking/error_handling";

const initialState: ConfigurationsProductionOrderState = {
    loadedData: {
        productionOrder: {}
    },
    actualData: {
        configurationId: ""
    },
    query: {
        getProductionOrder: {status: "idle", canExecute: true}
    }
};

export const configurationProductionOrderSlice = createSlice({
    name: "configuration/configuration_productionorder",
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
        },
        setConfigurationId: (state, action: PayloadAction<string>) => {
            state.actualData.configurationId = action.payload;
        },
    }, extraReducers: (builder) => {
        // fetchProductionOrder
        builder
            .addCase(getProductionOrder.pending, (state) => {
                state.query.getProductionOrder.status = "pending";
                state.query.getProductionOrder.canExecute = false;
            })
            .addCase(getProductionOrder.rejected, (state, action) => {
                setErrorInState(state.query.getProductionOrder, action);
            })
            .addCase(getProductionOrder.fulfilled, (state, action) => {
                state.query.getProductionOrder.status = "success";
                state.loadedData.productionOrder = action.payload.getData();
                // state.actualData.configurations = filterOrderConfiguration(state.loadedData.configurations, state.actualData.searchFilter);
            });
    }
});

export const {
    resetState,
    setConfigurationId
} = configurationProductionOrderSlice.actions;

export default configurationProductionOrderSlice.reducer;
