import {createAsyncThunk} from "@reduxjs/toolkit";
import CustomerDataService from "../../../../services/customerdata.service";
import {handleResponseErrors} from "../../../../shared/networking/error_handling";

export const loadCustomerData = createAsyncThunk(
    "customerdata/get",
    async (params: {
        erpGroupCompanyNumber: number,
        customerNumber: number,
        deliveryAddressNumber?: number
    }, {rejectWithValue}) => {
        const {erpGroupCompanyNumber, customerNumber, deliveryAddressNumber} = params;
        const response = await CustomerDataService.getCustomerData(erpGroupCompanyNumber, customerNumber, deliveryAddressNumber);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);
