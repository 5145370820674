import {EnumDescriptor} from "models/enum_descriptor";

export enum AttributeCategoryEnum {
    CompressionClass,
    Foot,
    ProductionType,
    AdditionalDesign,
    Side,
    ExtraWideCalf,
    Size,
    CompressivePantyTop,
    Shortened,
    FullyKnitted,
    GussetDesign,
}

export const AttributeCategoryValues: Array<
    EnumDescriptor<AttributeCategoryEnum>> = [
    {value: AttributeCategoryEnum.CompressionClass, text: "AKKL"},
    {value: AttributeCategoryEnum.Foot, text: "ARFU"},
    {value: AttributeCategoryEnum.ProductionType, text: "ARTY"},
    {value: AttributeCategoryEnum.AdditionalDesign, text: "ARZA"},
    {value: AttributeCategoryEnum.Side, text: "ASEI"},
    {value: AttributeCategoryEnum.ExtraWideCalf, text: "EXWW"},
    {value: AttributeCategoryEnum.Size, text: "GRNR"},
    {value: AttributeCategoryEnum.CompressivePantyTop, text: "KPRL"},
    {value: AttributeCategoryEnum.Shortened, text: "VKRZ"},
    {value: AttributeCategoryEnum.FullyKnitted, text: "VOLG"},
    {value: AttributeCategoryEnum.GussetDesign, text: "ZWAF"},
];