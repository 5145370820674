import {ConfigurationAdditionsState} from "./additions.model";
import {doesExist, hasValue} from "../../../../../../services/validation.service";
import {AttributeCategory} from "../models/request";
import {AttributeCategoryEnum, AttributeCategoryValues} from "../models/common";
import {IConfigurationAdditionsSelectionModel} from "models/configurations/configuration_additionsselection";
import {ProductionType} from "../../../../../../models/production_type.enum";
import { ComponentDataEvent } from "@medi-mtm/components";
import { ConfigurationState } from "models/configuration_state.enum";

export const validateState = (state: ConfigurationAdditionsState) => {
    // validate input data
    return (doesExist(state.loadedData.configuration)
        && hasValue(state.actualData.productConfigurationId)
        && state.actualData.productConfigurationRunId != null);
};

export const mustCallApi = (state: ConfigurationAdditionsState): boolean => {
    return (state.loadedData.configuration?.productConfigurationId !== state.actualData.productConfigurationId
        || state.loadedData.configuration?.productConfigurationRunId !== state.actualData.productConfigurationRunId)
        || state.loadedData.stepState.currentStep < ConfigurationState.InProgressAdditionsSelected;
};

export const canGotoNext = (state: ConfigurationAdditionsState): boolean => {
    if (state.loadedData.configuration.productionType === ProductionType.MadeToMeasure) {
        return (state.command.evaluateAdditions.canExecute || state.command.setAdditions.canExecute);
    } else {
        return state.actualData.isValid;
    }
};

export const updateComponentData = (state: ConfigurationAdditionsState, data: ComponentDataEvent) => {
    state.actualData.isValid = data.isValid;
    state.actualData.productConfigurationId = data.productConfigurationId;
    state.actualData.productConfigurationRunId = data.productConfigurationRunId;
};

export const calculateEvaluateCanExecute = (state: ConfigurationAdditionsState): boolean => {
    return state.actualData.isValid;
};

export const createAttributeCategories = (attributeData: IConfigurationAdditionsSelectionModel): AttributeCategory[] => {
    const attributeCategories = [];

    if (attributeData.compressionClass) {
        attributeCategories.push({
            categoryErpId: AttributeCategoryValues[AttributeCategoryEnum.CompressionClass].text,
            attributeId: attributeData.compressionClass.toString(),
        });
    }

    if (attributeData.mtmFoot) {
        attributeCategories.push({
            categoryErpId: AttributeCategoryValues[AttributeCategoryEnum.Foot].text,
            attributeId: attributeData.mtmFoot.toString(),
        });
    }

    if (attributeData.productionType) {
        attributeCategories.push({
            categoryErpId: AttributeCategoryValues[AttributeCategoryEnum.ProductionType].text,
            attributeId: attributeData.productionType.toString(),
        });
    }

    if (attributeData.additionalDesign) {
        attributeCategories.push({
            categoryErpId: AttributeCategoryValues[AttributeCategoryEnum.AdditionalDesign].text,
            attributeId: attributeData.additionalDesign.toString(),
        });
    }

    if (attributeData.side) {
        attributeCategories.push({
            categoryErpId: AttributeCategoryValues[AttributeCategoryEnum.Side].text,
            attributeId: attributeData.side.toString(),
        });
    }

    if (attributeData.extraWideCalf) {
        attributeCategories.push({
            categoryErpId: AttributeCategoryValues[AttributeCategoryEnum.ExtraWideCalf].text,
            attributeId: attributeData.extraWideCalf.toString(),
        });
    }


    if (attributeData.size) {
        attributeCategories.push({
            categoryErpId: AttributeCategoryValues[AttributeCategoryEnum.Size].text,
            attributeId: attributeData.size.toString(),
        });
    }

    if (attributeData.compressivePantyTop) {
        attributeCategories.push({
            categoryErpId: AttributeCategoryValues[AttributeCategoryEnum.CompressivePantyTop].text,
            attributeId: attributeData.compressivePantyTop.toString(),
        });
    }

    if (attributeData.shortened) {
        attributeCategories.push({
            categoryErpId: AttributeCategoryValues[AttributeCategoryEnum.Shortened].text,
            attributeId: attributeData.shortened.toString(),
        });
    }

    if (attributeData.fullyKnitted) {
        attributeCategories.push({
            categoryErpId: AttributeCategoryValues[AttributeCategoryEnum.FullyKnitted].text,
            attributeId: attributeData.fullyKnitted.toString(),
        });
    }

    if (attributeData.gussetDesign) {
        attributeCategories.push({
            categoryErpId: AttributeCategoryValues[AttributeCategoryEnum.GussetDesign].text,
            attributeId: attributeData.gussetDesign.toString(),
        });
    }
    return attributeCategories;
};
