import React, {useEffect} from "react";
import {useAppDispatch} from "../../../../app/hook";
import {useTranslation} from "react-i18next";
import {AppDispatch} from "../../../../app/redux_store";
import {getMeasurementDataSheet, sendMeasurementDataSheet} from "../redux/actionsmenu.thunk";
import {Dialog, DialogContent, DialogActions, Button} from "@mui/material";
import {SwitchComponent} from "shared/components/switchs/switch.component";
import {MultiEmailComponent} from "shared/components/form_controls/multi_email.component";

interface MeasurementDataSheetDialogProps {
    isOpen: boolean,
    onClose: () => void;
}

const MeasurementDataSheetDialogComponent: React.FC<MeasurementDataSheetDialogProps> = (props) => {
    const dispatch: AppDispatch = useAppDispatch();
    const {t} = useTranslation();

    const [isValid, setIsValid] = React.useState(false);
    const [isTabOptionChecked, setIsTabOptionChecked] = React.useState(false);
    const [isMailOptionChecked, setIsMailOptionChecked] = React.useState(true);
    const [recipients, setRecipients] = React.useState([]);

    useEffect(() => {
        validate();
    }, [recipients, isMailOptionChecked, isTabOptionChecked]);

    const validate = () =>
    {
        setIsValid(isMailOptionChecked ? recipients.length > 0 : true);
    };

    const handlePrimaryDialogButtonClick = () =>
    {
        props.onClose();
        if(isValid)
        {
            if(isTabOptionChecked)
                dispatch(getMeasurementDataSheet());
            if(isMailOptionChecked)
                dispatch(sendMeasurementDataSheet(recipients));
        }
    };

    return (
        <Dialog open={props.isOpen} disableEscapeKeyDown={true} fullWidth maxWidth="sm">
          <DialogContent>
            <div>{t("MeasurementDataSheetDialogHeader")}</div><br/>
            <SwitchComponent
                rightText={t("MeasurementDataSheetDialogSwitchTab")}
                checked={isTabOptionChecked}
                onChange={(event, checked) => {
                    setIsTabOptionChecked(checked);
                }}
            />
            <SwitchComponent
                rightText={t("MeasurementDataSheetDialogSwitchMail")}
                checked={isMailOptionChecked}
                onChange={(event, checked) => {
                    setIsMailOptionChecked(checked);
                }}
            />
            {isMailOptionChecked &&
                <MultiEmailComponent
                    label={t("MeasurementDataSheetDialogRecipients")}
                    recipients={recipients}
                    onChange= { (r) => { setRecipients(r); } }
                />
            }
          </DialogContent>
          <DialogActions>
            <Button disabled={!isValid} onClick={handlePrimaryDialogButtonClick} color="primary" autoFocus>
                {t("MeasurementDataSheetDialogButton")}
            </Button>
            <Button onClick={() => props.onClose()} color="secondary">
                {t("CancelButton")}
            </Button>
          </DialogActions>
        </Dialog>
    );
};

export default MeasurementDataSheetDialogComponent;
