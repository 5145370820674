import {AccordionProps} from "@mui/material";
import React from "react";
import * as style from "./collapsable.style";

interface CollapsableProps extends AccordionProps {
    expanded: boolean;
    handleChange: (event: React.SyntheticEvent, expanded: boolean) => void;
}


export const Collapsable: React.FC<CollapsableProps> = ({
                                                            expanded = true,
                                                            handleChange,
                                                            ...props
                                                        }) => {
    return <style.AccordionStyled {...props} onChange={handleChange} expanded={expanded}/>;
};
