import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import {languages, namespaces} from "./i18n.constants";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {getConfig} from "services/config.service";

i18next
    .use(initReactI18next)
    .use(ChainedBackend)
    .use(LanguageDetector)
    .init({
        backend: {
            backends: [LocalStorageBackend, HttpBackend],
            backendOptions: [
                {
                    defaultVersion: "1.0",
                    expirationTime: getConfig().name === "dev" ? 0 : 7 * 24 * 60 * 60 * 1000 // 7 days
                },
                {
                    loadPath: "/api/localization/{{ns}}?language={{lng}}"
                }
            ]
        },
        react: {
            useSuspense: true // Suspense added to App.tsx
        },
        detection: {
            caches: [],
            excludeCacheFor: [],
            order: ["navigator"],
        },
        ns: [namespaces.ORS, namespaces.COMMON, namespaces.ERROR],
        defaultNS: namespaces.ORS,
        supportedLngs: [languages.de, languages.en],
        fallbackLng: languages.de,
        nonExplicitSupportedLngs: true
    });

export default i18next;
