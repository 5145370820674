import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getConfiguration} from "./salesitemdata.thunk";
import IConfiguration from "models/configurations/configuration";
import {hasValue} from "services/validation.service";
import {setErrorInState} from "shared/networking/error_handling";
import {LoadingState} from "models/loading_state";

export interface SalesItemState {
    loadedData: IConfiguration,
    configurationId: string,
    query: {
        get: LoadingState
    }
}

const initialState: SalesItemState = {
    loadedData: undefined,
    configurationId: null,
    query: {
        get: {
            status: "success",
            message: "",
            canExecute: false
        }
    }
};

export const SalesItemDataSlice = createSlice({
    name: "salesitemdata",
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
        },
        setConfiguration: (state, action: PayloadAction<string>) => {
            state.configurationId = action.payload;
            state.query.get.canExecute = hasValue(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getConfiguration.pending, (state) => {
                state.query.get.status = "pending";
                state.query.get.canExecute = false;
            })
            .addCase(getConfiguration.rejected, (state, action) => {
                setErrorInState(state.query.get, action);
            })
            .addCase(getConfiguration.fulfilled, (state, action) => {
                state.query.get.status = "success";
                const data = action.payload.getData();
                state.loadedData = data;
            });
    }
});

export const {
    resetState,
    setConfiguration
} = SalesItemDataSlice.actions;
export default SalesItemDataSlice.reducer;
