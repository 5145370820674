import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "app/redux_store";
import React from "react";
import ConfigurationService from "services/configuration.service";
import {ProductionInstruction} from "@medi-mtm/components";
import {handleResponseErrors} from "shared/networking/error_handling";
import {mustCallApi} from "./productinstructions.reducer";
import {determineNextStep} from "configurations/shared/configuration_editor/redux/editor.reducer";
import {ConfigurationStepEnum} from "configurations/shared/configuration_editor/models/configuration_step.enum";
import {navigateTo} from "configurations/shared/configuration_editor/redux/editor.thunks";
import { PassOrderConfigurationProvider } from "shared/provider/orderconfiguration/orderconfiguration.provider";

export const setProductInstructions = createAsyncThunk(
    "configuration/productinstructions/set",
    async (_, {getState, rejectWithValue}) => {
        const state = (getState() as RootState).configuration_productinstructions;
        const mode = (getState() as RootState).configuration_mode.mode;
        if (!mustCallApi(state))
            return {}; // skip api call

        const response = await ConfigurationService.setProductInstructions(state.loadedData.configuration.id,
            {
                id: state.actualData.productConfigurationId,
                runId: state.actualData.productConfigurationRunId ?? 1
            },
            mode);
        return handleResponseErrors(response, "OrderConfiguration", rejectWithValue);
    }
);

export const getProductInstructions = createAsyncThunk(
    "configuration/productinstructions/get",
    async (id: string, {getState, rejectWithValue}) => {
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.getProductInstructions(id, mode);
        return handleResponseErrors(response, "OrderConfiguration", rejectWithValue);
    }
);

export const evaluateProductInstructions = createAsyncThunk(
    "configuration/productinstructions/evaluate",
    async (productInstructions: React.MutableRefObject<ProductionInstruction>) => {
        await productInstructions.current.evaluate();
    }
);

export const gotoNext = createAsyncThunk(
    "configuration/productinstructions/gotonext",
    async (productinstructions: React.MutableRefObject<ProductionInstruction>, {getState, dispatch}) => {
        const state = getState() as RootState;

        if(state.configuration_editor.loadedData.orderConfigurationState.readonly) {
            dispatch(navigateTo({
                targetStep: determineNextStep(ConfigurationStepEnum.ProductionInstructionsSelection, state.configuration_productinstructions.loadedData.configuration.productionType),
                configurationId: state.configuration_editor.loadedData.configurationId,
                productConfigurationId: state.configuration_editor.loadedData.productConfigurationId,
                productConfigurationRunId: state.configuration_editor.loadedData.productConfigurationRunId
            }));
            return;
        }

        if (state.configuration_productinstructions.command.evaluateProductInstructions.canExecute && PassOrderConfigurationProvider.hasPendingChanges) {
            await dispatch(evaluateProductInstructions(productinstructions));
            return;
        }
        await dispatch(setProductInstructions());
    }
);
