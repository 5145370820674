import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "app/redux_store";
import ConfigurationService from "services/configuration.service";
import {handleResponseErrors} from "shared/networking/error_handling";
import {mustCallApiCreate, toCreateModel} from "./create.reducer";
import { hasValue } from "services/validation.service";
import { ApiResult } from "shared/networking/api_result";
import IConfigurationId from "models/configurations/configurationid";

export const createConfiguration = createAsyncThunk(
    "configuration/create",
    async (_, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const createModel = toCreateModel(state.configuration_create);
        let response : ApiResult<IConfigurationId> = null;

        if (hasValue(createModel.mtmNumber))
            response = await ConfigurationService.createByMtmNumber(createModel, state.configuration_mode.mode);
        else
            response = await ConfigurationService.getOrCreate(createModel, state.configuration_mode.mode);

        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);

export const skipCreateConfiguration = createAsyncThunk(
    "configuration/skipCreate",
    // eslint-disable-next-line
    async () => {
    }
);

export const gotoNext = createAsyncThunk(
    "configuration/create/gotonext",
    async (_, {getState, dispatch}) => {
        const state = getState() as RootState;
        if (state.configuration_create.loadedData.stepState.configurationId === null) {
            if (mustCallApiCreate(state.configuration_create)) {
                await dispatch(createConfiguration());
            } else {
                dispatch(skipCreateConfiguration());
            }
        }
    }
);

export const getConfiguration = createAsyncThunk(
    "configuration/get",
    async (_, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const response = await ConfigurationService.getOrCreate(toCreateModel(state.configuration_create), state.configuration_mode.mode);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);
