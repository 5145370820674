import {CommentModel, CommentsState} from "./configurations_comments.model";

export const toCommentModel = (state: CommentsState, commentId?: string): CommentModel => {
    const model: CommentModel = {
        comment: state.actualData.commentText,
        scope: state.actualData.commentScope
    };
    if (commentId) {
        model.commentId = commentId;
    }
    return model;
};
