export const languages = {
    de: "de",
    en: "en",
};

export const namespaces = {
    ORS: "ORS",
    COMMON: "COMMON",
    ERROR: "ERROR",
};
