export enum ConfigurationMode {
    OrderConfiguration,
    QuotationConfiguration,
    NotSet,
}

export interface ConfigurationModeState {
    mode: ConfigurationMode,
    basePath: string,
    exclusive: boolean,
}
