import HttpService from "../shared/networking/http_service";
import {getConfig} from "./config.service";
import {Filter} from "../configurations/shared/configuration_editor/steps/measurements/models/responses";

class FilterServiceAPI extends HttpService {
    constructor(timeoutMilliseconds) {
        super(timeoutMilliseconds);
    }

    async getFilter(filterId) {
        const query = `/api/filterconfiguration/filters/${filterId}`;
        return this.get<Filter>(query);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async getFilterGroup(filterId: string, data: any, nextOnly = true) {
        const query = `/api/filter/${filterId}?nextOnly=${nextOnly}`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.post<any>(query, data);
    }

    async restoreFilter(filterId: string, productMasterId: number, sku: string, selectedSide: number | null, selectedFootStyle: number | null) {
        let query = `/api/filter/${filterId}/${productMasterId}/${sku}`;

        if (selectedSide !== null) {
            query += `?selectedSide=${selectedSide}`;
        }

        if (selectedFootStyle !== null) {
            if (query.includes("?")) {
                query += `&selectedFootStyle=${selectedFootStyle}`;
            } else {
                query += `?selectedFootStyle=${selectedFootStyle}`;
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.get<any>(query);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async getProducts(data: any, page = 1, pageSize = 10) {
        const query = `/api/filter/products?page=${page}&pageSize=${pageSize}`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.post<any>(query, data);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const FilterService = new FilterServiceAPI(TimeoutMilliseconds);

export default FilterService;
