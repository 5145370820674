import React from "react";
import {BreadcrumbsComponent} from "../../shared/breadcrumbs/components/breadcrumbs";
import {BreadcrumbRoute} from "../../shared/breadcrumbs/models/breadcrumb_route";

export const OrderConfigurationsBreadcrumbs: React.FC = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderconfigurationid = urlParams.get("orderconfigurationid");

    const routes: BreadcrumbRoute[] = [
        {
            route: "/orderconfigurations",
            parts: [{title: "Orders"}]
        },
        {
            route: "/orderconfigurations/orderconfiguration/overview",
            parts:
                [
                    {title: "Orders", url: "/orderconfigurations"},
                    {title: "OrderOverview"}
                ]
        },
        {
            route: "/orderconfigurations/orderconfiguration/productionorder",
            parts: [
                {title: "Orders", url: "/orderconfigurations"},
                {title: "OrderOverview", url: "/orderconfigurations/orderconfiguration/overview"},
                {title: "ProductionOrder"}
            ]
        },
        {
            route: "/orderconfigurations/orderconfiguration/events",
            parts:
                [
                    {title: "Orders", url: "/orderconfigurations"},
                    {title: "OrderOverview", url: "/orderconfigurations/orderconfiguration/overview"},
                    {title: "OrderEventOverview"}
                ]
        },
        {
            route: "/orderconfigurations/orderconfiguration/eventdetails",
            parts:
                [
                    {title: "Orders", url: "/orderconfigurations"},
                    {title: "OrderOverview", url: "/orderconfigurations/orderconfiguration/overview"},
                    {
                        title: "OrderEventOverview",
                        url: `/orderconfigurations/orderconfiguration/events?orderconfigurationid=${orderconfigurationid}`
                    },
                    {title: "Event"}
                ]
        }
    ];

    return <BreadcrumbsComponent breadcrumbs={routes}/>;
};


