import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "app/redux_store";
import ConfigurationService from "services/configuration.service";
import {handleResponseErrors} from "shared/networking/error_handling";
import {INavigateTo} from "./editor.model";

export const getConfiguration = createAsyncThunk(
    "configuration/editor/get",
    async (id: string, {getState, rejectWithValue}) => {
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.getConfiguration(
            id,
            mode
        );
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);

export const navigateTo = createAsyncThunk(
    "configuration/editor/navigateTo",
    (navigateTo: INavigateTo) => {
        return navigateTo;
    }
);

export const getOrderConfigurationValidationState = createAsyncThunk(
    "configuration/editor/validate",
    async (_, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.getOrderConfigurationValidationState(
            state.configuration_editor.loadedData.configurationId,
            mode
        );
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);
