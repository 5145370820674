import {ApiResult} from "../shared/networking/api_result";
import HttpService from "../shared/networking/http_service";
import {getConfig} from "./config.service";
import "../shared/extensions/URLQueryParams";
import {IConfigurationOverview} from "../models/configurations/configuration_overview";
import {ConfigurationMode} from "../configurations/shared/configurationMode.model";


interface GetConfigurationsFilterParams {
    id?: string;
    erpTenant?: string,
    erpGroupCompanyNumber?: number;
    erpItemIdAndPosition?: string;
    createdBy?: string;
    createdAt?: Date;
    changedBy?: string;
    changedAt?: Date;
    page?: number;
    size?: number;
}

class ConfigurationsOverviewServiceAPI extends HttpService {

    public async getConfigurations(filters: GetConfigurationsFilterParams, configurationType: ConfigurationMode): Promise<ApiResult<IConfigurationOverview>> {

        const query = new URLSearchParams();

        for (const [key, value] of Object.entries(filters)) {
            if (value != null) {
                if (value instanceof Date) {
                    query.append(key, value.toISOString().slice(0, 10));
                } else {
                    query.append(key, value.toString());
                }
            }
        }
        return this.get<IConfigurationOverview>(`/api/overview?configurationType=${configurationType}&${query.toString()}`);
    }

    public async getProductionOrder(id: string): Promise<ApiResult<object>> {
        return this.get<object>(`/api/overview/${id}/productionorder`);
    }

    public async cloneOrderConfiguration(id: string): Promise<ApiResult<string>> {
        return this.postForData<string>(`/api/orderconfiguration/${id}/clone`);
    }

    public async cloneQuotationConfiguration(id: string): Promise<ApiResult<string>> {
        return this.postForData<string>(`/api/quotationconfiguration/${id}/clone`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const Configurations_overviewService = new ConfigurationsOverviewServiceAPI(TimeoutMilliseconds);

export default Configurations_overviewService;
