import React from "react";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";


interface MultiEmailProps {
    label: string;
    recipients: string[];
    onChange: (recipients: string[]) => void;
}

export const MultiEmailComponent: React.FC<MultiEmailProps> = (props) => {

    return (
        <ReactMultiEmail
            placeholder={props.label}
            emails={props.recipients}
            onChange={(_recipients: string[]) => {
                props.onChange(_recipients);
            }}
            validateEmail={(email: string) => { return isEmail(email); } }
            getLabel={(
                email: string,
                index: number,
                removeEmail: (index: number) => void
            ) => {
                return (
                <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                    </span>
                </div>
                );
            }}
        />
    );
};
