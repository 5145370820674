import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ConfigurationMode, ConfigurationModeState} from "./configurationMode.model";


const initialState: ConfigurationModeState = {
    mode: ConfigurationMode.NotSet,
    basePath: "",
    exclusive: false
};

export const configurationmodeSlice = createSlice({
    name: "configurationmode",
    initialState,
    reducers: {
        setMode: (state, action: PayloadAction<ConfigurationMode>) => {
            state.mode = action.payload;
        },
        setBasePath: (state, action: PayloadAction<string>) => {
            state.basePath = action.payload;
        },
        setExclusive: (state) => {
            state.exclusive = true;
        }
    }
});

export const {
    setMode,
    setBasePath,
    setExclusive
} = configurationmodeSlice.actions;

export default configurationmodeSlice.reducer;
