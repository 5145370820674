import dayjs from "dayjs";

const germanDateTimeFormatString = "DD.MM.YYYY HH:mm";
const englishDateTimeFormatString = "MM/DD/YYYY hh:mm A";
const germanDateFormatString = "DD.MM.YYYY";
const englishDateFormatString = "MM/DD/YYYY";

export const formatDate = (isoDate: string | Date, language: string) : string => {
    const parsedDate = dayjs(isoDate).local();            
    if (!parsedDate.isValid)
        return isoDate.toString();

    const formatString = language.startsWith("de") ? germanDateFormatString : englishDateFormatString;
    return parsedDate.format(formatString);
};

export const formatDateTime = (isoDate: string | Date, language: string) : string => {
    const parsedDate = dayjs(isoDate).local();            
    if (!parsedDate.isValid)
        return isoDate.toString();
    
    const formatString = language.startsWith("de") ? germanDateTimeFormatString : englishDateTimeFormatString;
    return parsedDate.format(formatString);
};