import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import React, {useState} from "react";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/de";
import "dayjs/locale/en-gb";
import i18next from "i18next";
import dayjs from "dayjs";
import {StyledDatePicker} from "./datepicker.style";

interface DatePickerComponentProps {
    currentlySelectedDate?: Date;
    minDate?: Date;
    maxDate?: Date;
    onSelect: (date: Date) => void;
    disabled: boolean;
}

export const DatePickerComponent: React.FC<DatePickerComponentProps> = (props) => {


    const [selectedDate, setSelectedDate] = useState(props.currentlySelectedDate || null);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18next.language.startsWith("de") ? "de" : i18next.language}>
            <StyledDatePicker
                slotProps={
                    {
                        textField: {
                            size: "small",
                            variant: "standard"
                        }
                    }
                }
                value={dayjs(selectedDate)}
                minDate={dayjs(props.minDate)}
                maxDate={dayjs(props.maxDate)}
                onChange={
                    newSelectedDate => {
                        setSelectedDate(newSelectedDate.toDate());
                        props.onSelect(newSelectedDate.toDate());
                    }
                }
                disabled={props.disabled}
            />
        </LocalizationProvider>
    );
};
