import React, {useEffect} from "react";

import {useAppSelector} from "app/hook";

export interface HideOnExclusiveModeProps {
    children?: React.ReactNode
}

export const HideOnExclusiveMode: React.FC<HideOnExclusiveModeProps> = (props) => {

    const exclusive = useAppSelector((state) => state.configuration_mode).exclusive;

    useEffect(() => {
        // trigger render
    }, [exclusive]);

    return exclusive ? (<></>) : <div>{props.children}</div>;
};
