import React, {useState} from "react";
import styled from "styled-components";
import {Grid, TextField} from "@mui/material";
import {Close, DeleteForever, Edit, Save} from "@mui/icons-material";
import i18next from "i18next";
import {formatDateTime} from "../../../../shared/date/formatting";
import {AppDispatch} from "../../../../app/redux_store";
import {useAppDispatch} from "../../../../app/hook";
import {setCommentText} from "../redux/configurations_comments.slice";
import {IconButton} from "shared/components/icons/colored_icon.component";

const CommentWrapper = styled.div`
  margin-bottom: 20px;
`;

const CommentMetadata = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

const GridWithContentCentered = styled(Grid)`
  display: flex;
  justify-content: end;
  align-items: center;
`;

interface CommentProps {
    id: string;
    createdBy: string;
    createdAt: string;
    changedAt: string;
    changedBy: string;
    scope: number;
    comment: string;
    handleDeleteComment: (commentId: string) => void
    handleEditComment: (commentId: string) => void
}

export const Comment: React.FC<CommentProps> = ({...props}) => {

    const commentDate = props.changedAt !== null ? props.changedAt : props.createdAt;
    const commentAuthor = props.changedBy !== null ? props.changedBy : props.createdBy;
    const dispatch: AppDispatch = useAppDispatch();
    const [inEditMode, setInEditMode] = useState(false);
    const [editCommentText, setEditCommentText] = useState(props.comment);
    const [previousEditCommentText, setPreviousEditCommentText] = useState("");

    const renderEditMode = () => {
        return (
            <Grid
                container>
                <Grid
                    item
                    xs={10}>
                    <TextField
                        id="comment_input"
                        inputProps={{
                            maxLength: 1000
                        }}
                        fullWidth={true}
                        multiline
                        rows={5}
                        value={editCommentText}
                        onChange={
                            (event) => {
                                setEditCommentText(event.target.value);

                            }}
                    />
                </Grid>
                <GridWithContentCentered
                    item
                    xs={2}>
                    <IconButton
                        onClick={
                            () => {
                                dispatch(setCommentText(editCommentText));
                                props.handleEditComment(props.id);
                                setInEditMode(false);
                            }}
                        disabled={editCommentText === ""}
                    >
                        <Save/>
                    </IconButton>
                    <IconButton
                        onClick={
                            () => {
                                setInEditMode(false);
                                setEditCommentText(previousEditCommentText);
                            }
                        }>
                        <Close/>
                    </IconButton>
                </GridWithContentCentered>
            </Grid>
        );
    };

    const renderDisplayMode = () => {
        return (
            <Grid
                container>
                <Grid
                    item
                    xs={10}>
                    <div>{props.comment}</div>
                </Grid>
                <GridWithContentCentered
                    item
                    xs={2}>
                    <IconButton
                        onClick={
                            () => {
                                setInEditMode(true);
                                setPreviousEditCommentText(editCommentText);
                            }
                        }>
                        <Edit/>
                    </IconButton>
                    <IconButton onClick={() => props.handleDeleteComment(props.id)}>
                        <DeleteForever/>
                    </IconButton>
                </GridWithContentCentered>
                <Grid
                    item
                    xs={12}>
                    <CommentMetadata>{commentAuthor} - {formatDateTime(commentDate, i18next.language)}</CommentMetadata>
                </Grid>
            </Grid>
        );
    };

    return (
        <CommentWrapper>
            {inEditMode ? renderEditMode() : renderDisplayMode()}
        </CommentWrapper>
    );
};
