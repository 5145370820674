import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import theme from "../../../style/theme";
import * as styledTextbox from "./text_box.style";

export interface TextBoxProps {
    name: string;
    placeholder?: string;
    isRequired?: boolean;
    isReadonly?: boolean;
    isNumeric?: boolean;
    maxLength?: number;
    min?: number | Date;
    max?: number | Date;
    pattern?: RegExp;
    isAutoFocus?: boolean,
    width?: string,
    onChange: (text: string) => void;
    onEnterKeyDown?: () => void;
    value?: string;
    errorText?: string;
    tabIndex?: number;
    style?: React.CSSProperties;
}

export const TextBoxComponent: React.FC<TextBoxProps> = ({
                                                             name,
                                                             placeholder,
                                                             isRequired,
                                                             isReadonly,
                                                             isNumeric,
                                                             maxLength,
                                                             min,
                                                             max,
                                                             pattern,
                                                             isAutoFocus,
                                                             width,
                                                             onChange,
                                                             onEnterKeyDown,
                                                             value,
                                                             errorText,
                                                             tabIndex,
                                                             style
                                                         }) => {
    const isErrorVisible = errorText !== "" && errorText != null;
    return (
        <FormControl
            variant="standard"
            component="fieldset"
            style={{minWidth: theme.form.minWidth, width: width, ...style}}
            required={isRequired}>
            <InputLabel htmlFor={name}>{name}</InputLabel>
            <Input
                aria-describedby={`${name}_description`}
                id={name}
                autoFocus={isAutoFocus}
                placeholder={placeholder}
                required={isRequired}
                disabled={isReadonly}
                inputProps={{maxLength: maxLength ?? 512, min: min, max: max, pattern: pattern}}
                onChange={(e) => onChange(e.currentTarget.value)}
                value={value ?? ""}
                error={isErrorVisible}
                type={isNumeric ? "number" : ""}
                onKeyDown={(e) => {
                    if (e.key === "Enter" && onEnterKeyDown) onEnterKeyDown();
                }}
                tabIndex={tabIndex}
            />
            {isErrorVisible ? <styledTextbox.ErrorWrapper>{errorText}</styledTextbox.ErrorWrapper> : <></>}
        </FormControl>
    );
};
