export enum FootTypeEnum {
    OpenToe = 0,
    ClosedToe = 1,
    OpenToeObliqueFoot = 2,
    ClosedToeObliqueFoot = 3,
    OpenSeamlessToeCapWithLittleToe = 4,
    OpenSeamlessToeCapWithoutLittleToe = 5,
    ClosedSeamlessToeCapWithLittleToe = 6,
    ClosedSeamlessToeCapWithoutLittleToe = 7
}

export default FootTypeEnum;
