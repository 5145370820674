import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {deleteComment, editComment, getComments, saveNewComment} from "./configurations_comments.thunk";
import {CommentsState} from "./configurations_comments.model";
import {setErrorInState} from "shared/networking/error_handling";


const initialState: CommentsState = {
    loadedData: {
        comments: []
    },
    actualData: {
        configurationId: "",
        commentScope: 0,
        commentText: "",
    },
    query: {
        commentsQuery: {status: "idle", canExecute: true}
    }
};

export const configurationCommentsSlice = createSlice({
    name: "configuration/comments",
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
        },
        setConfigurationId: (state, action: PayloadAction<string>) => {
            state.actualData.configurationId = action.payload;
        },
        setCommentText: (state, action: PayloadAction<string>) => {
            state.actualData.commentText = action.payload;
        },
        setCommentScope: (state, action: PayloadAction<number>) => {
            state.actualData.commentScope = action.payload;
        },
    }, extraReducers: (builder) => {
        builder
            // getComments
            .addCase(getComments.pending, (state) => {
                state.query.commentsQuery.status = "pending";
            })
            .addCase(getComments.rejected, (state, action) => {
                setErrorInState(state.query.commentsQuery, action);
            })
            .addCase(getComments.fulfilled, (state, action) => {
                state.query.commentsQuery.status = "success";
                const data = action.payload.getData();
                state.loadedData.comments = data;
            })

            // saveNewComment
            .addCase(saveNewComment.pending, (state) => {
                state.query.commentsQuery.status = "pending";
            })
            .addCase(saveNewComment.rejected, (state, action) => {
                setErrorInState(state.query.commentsQuery, action);
            })
            .addCase(saveNewComment.fulfilled, (state) => {
                state.query.commentsQuery.status = "success";
                state.actualData.commentText = "";
            })

            // editComment
            .addCase(editComment.pending, (state) => {
                state.query.commentsQuery.status = "pending";
            })
            .addCase(editComment.rejected, (state, action) => {
                setErrorInState(state.query.commentsQuery, action);
            })
            .addCase(editComment.fulfilled, (state) => {
                state.query.commentsQuery.status = "success";
                state.actualData.commentText = "";
            })

            // deleteComment
            .addCase(deleteComment.pending, (state) => {
                state.query.commentsQuery.status = "pending";
            })
            .addCase(deleteComment.rejected, (state, action) => {
                setErrorInState(state.query.commentsQuery, action);
            })
            .addCase(deleteComment.fulfilled, (state) => {
                state.query.commentsQuery.status = "success";
            });
    }
});

export const {
    resetState,
    setConfigurationId,
    setCommentText,
    setCommentScope
} = configurationCommentsSlice.actions;

export default configurationCommentsSlice.reducer;
