import {createContext, Dispatch, SetStateAction, useContext, useState} from "react";
import {CommonApiResultError} from "shared/networking/common_api_result";
import PropTypes from "prop-types";

export type ApiErrorContextType = {
    apiError: CommonApiResultError,
    setApiError: Dispatch<SetStateAction<CommonApiResultError>>
};

const ApiErrorContext = createContext<ApiErrorContextType>(null);

const PassApiErrorProvider: ApiErrorContextType = {
    apiError: null,
    setApiError: null
};

const ApiErrorProvider = ({children}) => {
    const [apiError, setApiError] = useState<CommonApiResultError>();
    PassApiErrorProvider.apiError = apiError;
    PassApiErrorProvider.setApiError = setApiError;

    return (
        <ApiErrorContext.Provider value={{apiError, setApiError}}>
            {children}
        </ApiErrorContext.Provider>
    );
};

const useApiError = () => {
    const {apiError, setApiError} = useContext(ApiErrorContext);
    return {apiError, setApiError};
};

ApiErrorProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export {ApiErrorContext, ApiErrorProvider, PassApiErrorProvider, useApiError};
