import {createAsyncThunk} from "@reduxjs/toolkit";
import ConfigurationService from "../../../../services/configuration.service";
import {handleResponseErrors} from "../../../../shared/networking/error_handling";
import {RootState} from "../../../../app/redux_store";
import {toCommentModel} from "./configuration_comments.reducer";

export const getComments = createAsyncThunk(
    "configuration/comments/get",
    async (_, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.getComments(
            state.configuration_comments.actualData.configurationId, {
                scope: state.configuration_comments.actualData.commentScope,
                withDeleted: false
            },
            mode);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);

export const deleteComment = createAsyncThunk(
    "configuration/comments/delete",
    async (commentId: string, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.deleteComment(
            state.configuration_comments.actualData.configurationId,
            {commentId: commentId},
            mode);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);

export const saveNewComment = createAsyncThunk(
    "configuration/comments/postNew",
    async (_, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.postComment(
            state.configuration_comments.actualData.configurationId,
            toCommentModel(state.configuration_comments),
            mode);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);

export const editComment = createAsyncThunk(
    "configuration/comments/postEdit",
    async (commentId: string, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.postComment(
            state.configuration_comments.actualData.configurationId,
            toCommentModel(state.configuration_comments, commentId),
            mode);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);
