import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ConfigurationsOverviewState} from "./configurations_overview.model";
import {getConfigurations, updateConfigurations} from "./configurations_overview.thunks";
import {GridFilterModel, GridPaginationModel} from "@mui/x-data-grid-pro";
import {setErrorInState} from "shared/networking/error_handling";

const initialState: ConfigurationsOverviewState = {
    loadedData: {
        configuration: null
    },
    actualData: {
        pagination: {
            page: 1,
            pageSize: 20
        },
        filter: null
    },
    query: {
        getConfigurations: {status: "idle", canExecute: true}
    }
};

export const configurationOverviewSlice = createSlice({
    name: "configuration/configuration_overview",
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.query = initialState.query;
        },
        setPagination: (state, action: PayloadAction<GridPaginationModel>) => {
            state.actualData.pagination = action.payload;
        },
        setFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.filter = action.payload;
        }
    }, extraReducers: (builder) => {
        // fetchConfigurations
        builder
            .addCase(getConfigurations.pending, (state) => {
                state.query.getConfigurations.status = "pending";
                state.query.getConfigurations.canExecute = false;
            })
            .addCase(getConfigurations.rejected, (state, action) => {
                setErrorInState(state.query.getConfigurations, action);

            })
            .addCase(getConfigurations.fulfilled, (state, action) => {
                state.query.getConfigurations.status = "success";
                state.loadedData.configuration = action.payload.getData();
            })
            .addCase(updateConfigurations.pending, (state) => {
                state.query.getConfigurations.canExecute = false;
            })
            .addCase(updateConfigurations.rejected, (state, action) => {
                state.query.getConfigurations.message = action.error.message;
            })
            .addCase(updateConfigurations.fulfilled, (state, action) => {
                state.loadedData.configuration = action.payload.getData();
            });
    }
});

export const {
    resetState,
    setPagination,
    setFilters
} = configurationOverviewSlice.actions;

export default configurationOverviewSlice.reducer;
