import React from "react";
import {Grid, Switch, Typography} from "@mui/material";
import colors from "../../style/colors";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {customizedMaterialTheme} from "../../style/theme";


interface SwitchProps {
    leftText?: string;
    rightText: string;
    disabled?: boolean;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const SwitchComponent: React.FC<SwitchProps> = (props) => {

    const mergedTheme = createTheme({
        ...customizedMaterialTheme,
        components: {
            ...(customizedMaterialTheme.components || {}),
            MuiSwitch: {
                styleOverrides: {
                    switchBase: {
                        color: colors.mediMagenta,
                    },
                    track: {
                        opacity: 0.5,
                        backgroundColor: colors.mediMagenta,
                    },
                },
                ...(customizedMaterialTheme.components?.MuiSwitch || {}).styleOverrides,
            },
        },
    });

    return (
        <ThemeProvider theme={props.leftText ? mergedTheme : customizedMaterialTheme}>
            <Grid container alignItems="center" className="mb-4">
                {props.leftText &&
                    <Grid item>
                        <Typography>
                            {props.leftText}
                        </Typography>
                    </Grid>
                }
                <Grid item>
                    <Switch
                        sx={{
                            switchBase: {
                                color: colors.mediMagenta,
                            },
                            track: {
                                opacity: 0.5,
                                backgroundColor: colors.mediMagenta,
                            }
                        }}
                        disabled={props.disabled}
                        checked={props.checked}
                        onChange={(event) => {
                            props.onChange(event, event.target.checked);
                        }}
                    />
                </Grid>
                <Grid item>
                    <Typography>
                        {props.rightText}
                    </Typography>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};
