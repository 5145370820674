export {};

declare global {
    interface URLSearchParams {
        appendIf(condition: boolean, name: string, value: unknown): URLSearchParams;
    }
}

URLSearchParams.prototype.appendIf = function (condition: boolean, name: string, value: unknown): URLSearchParams {
    if (condition) {
        if (typeof (value) === "function")
            value = value();

        this.append(name, value);
    }

    return this;
};
