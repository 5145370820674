import { Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import {EditorComponent} from "../shared/configuration_editor/editor.component";
import {OrderConfigurationsMenuComponent} from "./orderconfiguration_menu/orderconfigurationmenu.component";
import {QueryParamProvider} from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import {parse, stringify} from "query-string";
import {
    OrderConfigurationOverviewComponent
} from "./orderconfiguration_overview/components/orderconfiguration_overview.component";
import ConfigurationProductionOrderComponent
    from "../shared/configuration_productionorder/components/configuration_productionorder.component";
import {AppDispatch} from "../../app/redux_store";
import {useAppDispatch} from "../../app/hook";
import {setBasePath, setMode} from "../shared/configurationmodeSlice";
import {ConfigurationMode} from "../shared/configurationMode.model";
import {getBasePath} from "../shared/basePathGetter";
import {OrderConfigurationsBreadcrumbs} from "./orderconfigurations.breadcrumbs";
import ConfigurationEventsComponent from "../shared/configuration_events/components/configuration_events.component";
import ConfigurationEventJsonComponent
    from "../shared/configuration_events/components/configuration_eventJson.component";

export const OrderConfigurationsComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setMode(ConfigurationMode.OrderConfiguration));
        dispatch(setBasePath(getBasePath()));
    }, []);
    return (
        <>
            <QueryParamProvider
                adapter={ReactRouter6Adapter}
                options={{
                    searchStringToObject: parse,
                    objectToSearchString: stringify,
                }}>
                <Routes>
                    <Route element={<OrderConfigurationsMenuComponent/>} path="/"/>
                    <Route element={<EditorComponent/>} path="/orderconfiguration/*"/>
                    <Route element={<OrderConfigurationOverviewComponent/>} path="/orderconfiguration/overview"/>
                    <Route element={<ConfigurationProductionOrderComponent
                        breadcrumbComponent={<OrderConfigurationsBreadcrumbs />} />}
                        path="/orderconfiguration/productionorder"/>
                    <Route element={<ConfigurationEventsComponent
                        breadcrumbComponent={<OrderConfigurationsBreadcrumbs/>} />}
                        path="/orderconfiguration/events"/>
                    <Route element={<ConfigurationEventJsonComponent
                        breadcrumbComponent={<OrderConfigurationsBreadcrumbs />} />}
                        path="/orderconfiguration/eventdetails"/>
                </Routes>
            </QueryParamProvider>
        </>
    );
};
