export enum ConfigurationState {
    New,

    InProgress,

    InProgressProductPreselected,

    InProgressMeasured,

    InProgressProductLineSelected,

    InProgressProductSelected,

    InProgressAdditionsSelected,

    InProgressProductionInstructionsSelected,

    Completed,

    Finalized
}
