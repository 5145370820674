import {LoadingStatus} from "../../models/loading_state";
import {CommonApiResult, CommonApiResultError} from "./common_api_result";

export class ApiResult<T> {
    private statusCode: number;
    private isRequestTimeout: boolean;
    private data: T;

    public withTimeout(): ApiResult<T> {
        this.isRequestTimeout = true;
        return this;
    }

    public withStatusCode(statusCode: number): ApiResult<T> {
        this.statusCode = statusCode;
        return this;
    }

    public withData(data: T): ApiResult<T> {
        this.data = data;
        return this;
    }

    public isSuccessfull() {
        if (!(!this.isRequestTimeout && this.statusCode >= 200 && this.statusCode < 300))
            return false;

        const commonApiResult = this.getCommonApiResult();
        if (commonApiResult) {
            if (commonApiResult.success)
                this.statusCode = 200;
            else
                this.statusCode = commonApiResult.error.errorCode;
        }

        return (this.statusCode >= 200 && this.statusCode < 300);
    }

    public isConflict() {
        return this.statusCode === 409;
    }

    public isUnprocessableEntity() {
        return this.statusCode === 422;
    }

    public isNotFound() {
        return this.statusCode === 404;
    }

    public isForbidden() {
        return this.statusCode === 403;
    }

    public isUnauthorized() {
        return this.statusCode === 401;
    }

    public isBadRequest() {
        return this.statusCode === 400;
    }

    public isServerError() {
        return this.statusCode === 500;
    }

    public isTimeout() {
        return this.isRequestTimeout;
    }

    public getData() {
        const commonApiResult = this.getCommonApiResult();
        if (commonApiResult) {
            return commonApiResult.content;
        }

        return this.data;
    }

    public getErrorCode(): string {
        if (this && this.statusCode) {
            return this.statusCode.toString();
        }
        return "undefined error code";
    }

    public isCustomError(): boolean {
        const commonApiResult = this.getCommonApiResult();
        return (commonApiResult && !commonApiResult.success);
    }

    public getCustomError(): CommonApiResultError {
        if (this.isCustomError())
            return this.getCommonApiResult().error;

        return null;
    }

    public state(): LoadingStatus {
        if (this.isTimeout()
            || this.isCustomError()
            || this.isServerError()
            || this.isBadRequest()
            || this.isNotFound()
            || this.isUnprocessableEntity()
            || this.isConflict()) {

            return "error";
        }
        return "success";
    }

    private getCommonApiResult(): CommonApiResult {
        const commonApiResult = this.data as CommonApiResult;
        if (commonApiResult && typeof (commonApiResult.success) !== "undefined") {
            // replace error with correct Error instance
            if (commonApiResult.error)
                commonApiResult.error = new CommonApiResultError(commonApiResult.error);

            return commonApiResult;
        }

        return null;
    }
}
