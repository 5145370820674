import React from "react";
import {ContentContainer} from "../../shared/components/container/ContentContainer";

export const AdminOverviewComponent = () => {
    return (
        <ContentContainer>
            <div>AdminOverviewComponent</div>
        </ContentContainer>
    );
};
