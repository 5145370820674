import {createContext, Dispatch, SetStateAction, useContext, useState} from "react";
import PropTypes from "prop-types";

export type OrderConfigurationContextType = {
    hasPendingChanges: boolean,
    setPendingChanges: Dispatch<SetStateAction<boolean>>
    isReadonly: boolean,
    setReadonly: Dispatch<SetStateAction<boolean>>
};

const OrderConfigurationContext = createContext<OrderConfigurationContextType>(null);

const PassOrderConfigurationProvider: OrderConfigurationContextType = {
    hasPendingChanges: false,
    setPendingChanges: null,
    isReadonly: false,
    setReadonly: null
};

const OrderConfigurationProvider = ({children}) => {
    const [hasPendingChanges, setPendingChanges] = useState<boolean>(false);
    const [isReadonly, setReadonly] = useState<boolean>(false);

    PassOrderConfigurationProvider.hasPendingChanges = hasPendingChanges;
    PassOrderConfigurationProvider.setPendingChanges = setPendingChanges;
    PassOrderConfigurationProvider.isReadonly = isReadonly;
    PassOrderConfigurationProvider.setReadonly = setReadonly;

    return (
        <OrderConfigurationContext.Provider value={{hasPendingChanges, setPendingChanges, isReadonly, setReadonly}}>
            {children}
        </OrderConfigurationContext.Provider>
    );
};

const usePendingChanges = () => {
    const {hasPendingChanges, setPendingChanges} = useContext(OrderConfigurationContext);
    return {hasPendingChanges, setPendingChanges};
};

const useReadonly = () => {
    const {isReadonly, setReadonly} = useContext(OrderConfigurationContext);
    return {isReadonly, setReadonly};
};

OrderConfigurationProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export {OrderConfigurationContext, OrderConfigurationProvider, PassOrderConfigurationProvider, usePendingChanges, useReadonly};
