import {RootState} from "../../../../app/redux_store";
import {handleResponseErrors} from "../../../../shared/networking/error_handling";
import {createAsyncThunk} from "@reduxjs/toolkit";
import Configurations_eventsService from "../../../../services/configurations_events_service";

export const getEventsLogic = async (getState, rejectWithValue) => {
    const state = getState() as RootState;
    const paginationProps = state.configuration_events.actualData.pagination;
    const response = await Configurations_eventsService.getEvents({
        id: state.configuration_events.actualData.configurationId,
        size: paginationProps.pageSize,
        page: paginationProps.page
    });
    return handleResponseErrors(response, "Configurations", rejectWithValue);
};

export const getEvents = createAsyncThunk(
    "configuration/configuration_events/getEvents",
    async (_, {getState, rejectWithValue}) => {
        return await getEventsLogic(getState, rejectWithValue);
    }
);

export const updateEvents = createAsyncThunk(
    "configuration/configuration_events/updateEvents",
    async (_, {getState, rejectWithValue}) => {
        return await getEventsLogic(getState, rejectWithValue);
    }
);

export const getEvent = createAsyncThunk(
    "configuration/configuration_events/getEvent",
    async (_, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const response = await Configurations_eventsService.getEvent(state.configuration_events.actualData.eventId);
        return handleResponseErrors(response, "Configurations", rejectWithValue);
    }
);



