import React from "react";
import {BreadcrumbsComponent} from "../shared/breadcrumbs/components/breadcrumbs";
import {BreadcrumbRoute} from "../shared/breadcrumbs/models/breadcrumb_route";

export const AdminBreadcrumbs: React.FC = () => {
    return <BreadcrumbsComponent breadcrumbs={routes}/>;
};

const routes: BreadcrumbRoute[] = [
    {
        route: "/admin",
        parts: [{title: "Administration"}]
    }];