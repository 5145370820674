import * as React from "react";
import {Container} from "reactstrap";
import * as styleBreadcrumbs from "../../../../shared/breadcrumbs/breadcrumbs_root.style";
import {ContentContainer} from "../../../../shared/components/container/ContentContainer";
import ConfigurationEventsGridComponent from "./configuration_events_grid.component";
import {EventGridColumns} from "../columndefinitions/configuration_eventColumns";

interface ConfigurationEventsComponentProps {
    breadcrumbComponent: React.ReactNode;
}

const ConfigurationEventsComponent: React.FC<ConfigurationEventsComponentProps> = ({breadcrumbComponent}) => {

    return (
        <>
            <styleBreadcrumbs.BreadcrumbArea>
                <Container>
                    {breadcrumbComponent}
                </Container>
            </styleBreadcrumbs.BreadcrumbArea>
            <ContentContainer>
                <ConfigurationEventsGridComponent
                    columnDefinitions={EventGridColumns}></ConfigurationEventsGridComponent>
            </ContentContainer>
        </>
    );
};

export default ConfigurationEventsComponent;
