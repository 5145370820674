import {AccordionSummaryProps} from "@mui/material";
import React from "react";
import * as style from "./collapsable.summary.style";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const CollapsableSummary: React.FC<AccordionSummaryProps> = ({...props}) => {

    return (
        <>
            <style.AccordionSummaryStyled
                {...props}
                expandIcon={<KeyboardArrowDownIcon/>}
            />
        </>
    );
};