import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {CommonApiResultError} from "shared/networking/common_api_result";
import {DialogButtons} from "./error_dialog.helper";

export type ErrorDialogProps = {
    header?: string,
    message?: string,
    handleOnClose?: () => void,
    apiError?: CommonApiResultError,
    buttons?: DialogButtons,
};

const ErrorDialog = (props: ErrorDialogProps) => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = React.useState(true);

    const handlePrimaryClick = (event?, reason?) => {
        if (reason === "backdropClick")
            return;

        setIsOpen(false);

        if (props.buttons && typeof props.buttons.primary.onClick !== "undefined")
            props.buttons.primary.onClick();
        else
            props.handleOnClose();
    };

    const handleSecondaryClick = (event?, reason?) => {
        if (reason === "backdropClick")
            return;

        setIsOpen(false);

        if (props.buttons && props.buttons.secondary && typeof props.buttons.secondary.onClick !== "undefined")
            props.buttons.secondary.onClick();
        else
            props.handleOnClose();
    };

    let buttons = props.buttons;

    if (!buttons) {
        buttons = {
            primary:
                {
                    labelKey: "CloseButton"
                }
        };
    }

    let errorMessage = (props.apiError?.message ?? props.message);
    if (!errorMessage)
        errorMessage = t("UnknownErrorOccurred");

    // validation errors
    if (props.apiError && props.apiError.errorCode == 400 && props.apiError.errorSubCode == "00000008")
    {
        for (const key in props.apiError.messageParameter)
        {
            errorMessage += `\n${props.apiError.messageParameter[key]}`;
        }
    }

    const errorLines = errorMessage.split("\n");

    return (
        <Dialog open={isOpen} onClose={handlePrimaryClick} disableEscapeKeyDown={true}>
            <DialogTitle>{props.header ?? t("Error")}</DialogTitle>
            <DialogContent>
                {errorLines.map((line, index) => (
                    <div key={index}>{line}</div>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={handlePrimaryClick} color="primary" autoFocus>
                    {t(buttons.primary.labelKey)}
                </Button>
                {buttons && buttons.secondary ? (
                    <Button onClick={handleSecondaryClick} color="secondary">
                        {t(buttons.secondary.labelKey)}
                    </Button>
                ) : <></>}
            </DialogActions>
        </Dialog>
    );
};

ErrorDialog.propTypes = {
    message: PropTypes.string.isRequired,
};

export default ErrorDialog;
