import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {TextField} from "@mui/material";
import {SecondaryButtonComponent} from "../../../../shared/components/buttons/secondary_button_component";
import styled from "styled-components";
import {useAppDispatch} from "../../../../app/hook";
import {AppDispatch} from "../../../../app/redux_store";
import {setCommentText} from "../redux/configurations_comments.slice";

const SecondaryButtonWithMarginTop = styled(SecondaryButtonComponent)`
  margin-top: 10px
`;

interface CommentInputProps {
    handleSaveNewComment: () => void;
}

export const CommentInput: React.FC<CommentInputProps> = ({handleSaveNewComment}) => {
    const {t} = useTranslation();
    const dispatch: AppDispatch = useAppDispatch();
    const [newCommentText, setNewCommentText] = useState("");

    return (
        <>
            <TextField
                id="comment_input"
                inputProps={{
                    maxLength: 1000
                }}
                fullWidth={true}
                multiline
                rows={5}
                value={newCommentText}
                onChange={(event) => setNewCommentText(event.target.value)}
            />
            <SecondaryButtonWithMarginTop
                onClick={
                    () => {
                        dispatch(setCommentText(newCommentText));
                        setNewCommentText("");
                        handleSaveNewComment();
                    }}
                disabled={newCommentText === ""}
            >
                {t("SaveCommentButtonText")}
            </SecondaryButtonWithMarginTop>
        </>
    );
};
