import {useAppDispatch, useAppSelector} from "../../../../../../app/hook";
import React, {useEffect, useState} from "react";
import {CollapsableSummary} from "../../../../../../shared/components/collapsable/collapsable.summary";
import {CollapsableDetails} from "../../../../../../shared/components/collapsable/collapsable.details";
import {Collapsable} from "../../../../../../shared/components/collapsable/Collapsable";
import {
    resetProductSKUs,
    resetSelectionsOnChangeAfterRestore,
    setIsFilteringSuccessful,
    setSelectedValue
} from "./redux/filter.slice";
import Image_radio_buttons from "../../../../../../shared/components/form_controls/image_radio_buttons";
import {getFilterGroup, getProduct, restoreFilter} from "./redux/filter.thunk";
import {
    CollapsibleSummarySelection
} from "../../../../../../shared/components/collapsable/collapsibleSummarySelection.style";
import SideSelectionEnum from "../../../../../../models/side_selection_enum.enum";
import FootTypeEnum from "../../../../../../models/foot_type_enum.enum";
import {resetSideAndFootStyle, setFootStyle, setSide, setSku} from "../redux/productpreselection.slice";
import {preselectProductPostSkU} from "../redux/productpreselection.thunks";

export interface FilterComponentProps {
    filterGroupId: string;
    // The first FilterComponent (and only the first - no other) triggers the initialization depending on the state (either restoreFilter or getFilterGroup).
    // Doing this for every filter component results in an incorrect state and faulty loading
    isFirstFilterComponent: boolean;
    disabled: boolean;
}

export const FilterComponent: React.FC<FilterComponentProps> = (props) => {
    const state = useAppSelector((state) => state.filter);
    const preselectionState = useAppSelector((state) => state.configuration_productpreselection);
    const dispatch = useAppDispatch();
    const [collapsibleStates, setCollapsibleStates] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        if (props.isFirstFilterComponent && !preselectionState.actualData.isReset && preselectionState.loadedData?.configuration?.sku !== null && preselectionState.loadedData.configuration.erpGroupCompanyNumber !== null) {
            dispatch(restoreFilter());
        } else if (props.isFirstFilterComponent) {
            dispatch(getFilterGroup(props.filterGroupId));
        }
    }, []);

    useEffect(() => {
        if (!state.isFilteringFinished) {
            dispatch(resetSideAndFootStyle());
        }
        if (state.isFilteringFinished && props.filterGroupId === state.filterGroups[state.filterGroups.length - 1].filterGroupId) {
            dispatch(getProduct());
        } else {
            dispatch(resetProductSKUs());
        }
    }, [state.isFilteringFinished]);

    useEffect(() => {
        if ((state.isSelectionUpdated || state.isSelectionAutomaticallyUpdated) && state.filterGroupResponsibleForUpdate === props.filterGroupId) {
            dispatch(getFilterGroup(props.filterGroupId));
        }
    }, [state.isSelectionUpdated, state.isSelectionAutomaticallyUpdated]);

    useEffect(() => {
        if (state.isFilteringFinished && state.filterGroupResponsibleForUpdate === props.filterGroupId) {
            if (state.productSKUs.length === 1 && state.filterGroupResponsibleForUpdate === props.filterGroupId) {
                const selection = getSelectedSideAndFootStyle();
                dispatch(setSku(state.productSKUs[0]));
                dispatch(setSide(selection.sideSelectedValue));
                dispatch(setFootStyle(selection.footStyleSelectedValue));
                dispatch(preselectProductPostSkU());
                dispatch(setIsFilteringSuccessful(true));
            }
        }
    }, [state.productSKUs]);

    const getSelectedSideAndFootStyle = () : { sideSelectedValue: number, footStyleSelectedValue: number} => {
        let sideSelectedValue: number = null;
            state.filterGroups.forEach((group) => {
                const sideOption = group.filterGroupOptions.find((option) => option.type === "SideSupport");
                if (sideOption?.selectedValue) {
                    sideSelectedValue = SideSelectionEnum[sideOption.selectedValue];
                }
            });

            let footStyleSelectedValue: number = null;
            state.filterGroups.forEach((group) => {
                const footStyle = group.filterGroupOptions.find((option) => option.type === "FootStyle");
                if (footStyle?.selectedValue) {
                    footStyleSelectedValue = FootTypeEnum[footStyle.selectedValue];
                }
            });

            return { sideSelectedValue, footStyleSelectedValue };
    };

    const filterElements = state.filterGroups.find(group => group.filterGroupId === props.filterGroupId).filterGroupOptions
        .filter(options => options.filterValues.length > 0 && options.filterValues[0].displayName !== null)
        .map((collapsible) => {
            return (
                <Collapsable
                    key={collapsible.type}
                    handleChange={(event, expanded) => {
                        setCollapsibleStates((prevState) => ({
                            ...prevState,
                            [collapsible.type]: expanded,
                        }));

                        if (state.isRestored) {
                            dispatch(resetSelectionsOnChangeAfterRestore({
                                filterGroupId: props.filterGroupId,
                                type: collapsible.type
                            }));
                        }
                    }}
                    expanded={collapsibleStates[collapsible.type] || collapsible.selectedValue === ""}
                    disabled={props.disabled}
                    style={{
                        display:
                            collapsible.filterValues.length === 1 &&
                            collapsible.filterValues[0].value === null
                                ? "none"
                                : "block"
                    }}
                >
                    <CollapsableSummary>
                        <div>
                            {collapsible.displayName}
                        </div>
                        <CollapsibleSummarySelection>
                            {collapsible.selectedValue !== "" ? collapsible.filterValues.find(filterValue => filterValue.value === collapsible.selectedValue)?.displayName : ""}
                        </CollapsibleSummarySelection>
                    </CollapsableSummary>
                    <CollapsableDetails>
                        <Image_radio_buttons
                            name={""}
                            data={collapsible.filterValues}
                            displayedProperties={["displayName", "value"]}
                            onSelect={(selectedValue) => {
                                dispatch(setSelectedValue({
                                    filterGroupId: props.filterGroupId,
                                    type: collapsible.type,
                                    selectedValue: selectedValue
                                }));
                                setCollapsibleStates((prevState) => ({
                                    ...prevState,
                                    [collapsible.type]: false,
                                }));
                            }}
                            valueProperty={"value"}
                            selectedItem={collapsible.selectedValue !== "" ? collapsible.filterValues.find(filterValue => filterValue.value === collapsible.selectedValue)?.value : ""}
                            showImage={false}
                            displayPattern={"{0} [{1}]"}
                        ></Image_radio_buttons>
                    </CollapsableDetails>
                </Collapsable>
            );
        });

    if (!props.filterGroupId) {
        return null;
    }

    return (
        <>
            {filterElements}
        </>

    );
};
