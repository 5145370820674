import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {Container} from "reactstrap";

const ProductInformationWrapper = styled.div`
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  z-index: 20;
  box-shadow: 0px 2px 3px rgb(174 188 197 / 50%);
`;

const Label = styled.div`
  font-weight: bold;
  margin-right: 5px;
  display: inline-block;
`;

interface ProductInformationProps {
    sku: string;
    skuLongIdentifier: string
}


export const ProductInformation: React.FC<ProductInformationProps> = ({sku, skuLongIdentifier}) => {
    const {t} = useTranslation();
    return (
        <ProductInformationWrapper>
            <Container>
                <div>
                    <Label>
                        {`${t("COMMON:Sku")}:`}
                    </Label>
                    {sku}
                </div>
                <div>
                    <Label>
                        {`${t("COMMON:SkuLongIdentifier")}:`}
                    </Label>
                    {skuLongIdentifier}
                </div>
            </Container>
        </ProductInformationWrapper>
    );
};
