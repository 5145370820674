interface IMenuRoute {
    name: string;
    target: string;
}

const MenuRoutes: Array<IMenuRoute> = [
    {name: "Home", target: "/home"},
    {name: "Orders", target: "/orderconfigurations"},
    {name: "Quotations", target: "/quotationconfigurations"},
    {name: "Administration", target: "/admin"},
];

export default MenuRoutes;
