import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import React from "react";

const GridTextAlignRight = styled(Grid)`
  text-align: right;
`;

export interface LabeledDataProps {
    labelPlaceholder?: string
    children: React.ReactNode
}

export const LabeledData: React.FC<LabeledDataProps> = ({labelPlaceholder, children}) => {
    const {t} = useTranslation();
    return (
        <>
            <GridTextAlignRight item xs={8} sm={6} md={5} lg={4}>
                <b>{t(labelPlaceholder)}</b>
            </GridTextAlignRight>
            <Grid item xs={4} sm={6} md={7} lg={8}>
                {children}
            </Grid>
        </>
    );
};
