import {Accordion, styled} from "@mui/material";

export const AccordionStyled = styled(Accordion)({
    boxShadow: "none",
    backgroundColor: "unset",


    "&.Mui-expanded": {
        margin: "0",
        borderBottom: "none",
    }
});
