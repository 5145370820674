import React from "react";
import {ErrorPanelComponent} from "./error_panel.component";

export const NotFoundComponent = () => {
    return (
        <ErrorPanelComponent title="Nicht Verfügbar">
            Die angeforderte Seite steht nicht zur Verfügung.
        </ErrorPanelComponent>
    );
};
