import React, { useEffect } from "react";
import {ThumbnailModel} from "../../../models/thumbnail.model";
import {ThumbnailComponent} from "../../../shared/components/buttons/thumbnail.component";
import orderConfigurationImage from "./images/orderconfigurations.jpg";
import * as style from "../../shared/configurationmenu.style";
import {useTranslation} from "react-i18next";
import {ContentContainer} from "shared/components/container/ContentContainer";
import * as styleBreadcrumbs from "../../../shared/breadcrumbs/breadcrumbs_root.style";
import {Container} from "reactstrap";
import {OrderConfigurationsBreadcrumbs} from "../orderconfigurations.breadcrumbs";
import {AppDispatch} from "../../../app/redux_store";
import {useAppDispatch} from "../../../app/hook";
import {ConfigurationMode} from "../../shared/configurationMode.model";
import {setBasePath, setMode} from "../../shared/configurationmodeSlice";
import {getBasePath} from "../../shared/basePathGetter";

export const OrderConfigurationsMenuComponent = () => {

    const {t} = useTranslation();

    const thumbnails: ThumbnailModel[] = [
        new ThumbnailModel(t("OrderOverview"), orderConfigurationImage, "/orderconfigurations/orderconfiguration/overview"),
        new ThumbnailModel(t("OrderCreate"), orderConfigurationImage, "/orderconfigurations/orderconfiguration/create")
    ];

    const dispatch: AppDispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setMode(ConfigurationMode.OrderConfiguration));
        dispatch(setBasePath(getBasePath()));
    }, []);

    return (
        <>
            <styleBreadcrumbs.BreadcrumbArea>
                <Container>
                    <OrderConfigurationsBreadcrumbs/>
                </Container>
            </styleBreadcrumbs.BreadcrumbArea>
            <ContentContainer>
                <style.ThumbnailWrapper>
                    {thumbnails.map((thumb, i) => {
                        return (<ThumbnailComponent key={i} thumbnail={thumb}/>);
                    })}
                </style.ThumbnailWrapper>
            </ContentContainer>
        </>
    );
};
