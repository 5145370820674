import {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import {DocumentModel} from "../../../models/documentModel";

interface DocumentPreviewDialogProps {
    documents: DocumentModel[];
    isOpenProp: boolean;
    handleClose: () => void;
    firstDocumentIndex: number;
}

export const DocumentPreviewDialog: React.FC<DocumentPreviewDialogProps> = ({
                                                                                documents,
                                                                                isOpenProp,
                                                                                handleClose,
                                                                                firstDocumentIndex
                                                                            }: DocumentPreviewDialogProps) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(isOpenProp);
    }, [isOpenProp]);

    const docs = documents.map((doc) => ({
        uri: URL.createObjectURL(doc.document),
        fileName: doc.name
    }));

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogContent>
                <DocViewer
                    documents={docs}
                    initialActiveDocument={docs[firstDocumentIndex]}
                    pluginRenderers={DocViewerRenderers}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
