import {createAsyncThunk} from "@reduxjs/toolkit";
import {handleResponseErrors} from "../../../../shared/networking/error_handling";
import Configurations_overviewService from "../../../../services/configurations_overview_service";
import {RootState} from "../../../../app/redux_store";

const createConfigurationParameters = (state: RootState) => {
    const page = state.configuration_overview.actualData.pagination.page;
    const pageSize = state.configuration_overview.actualData.pagination.pageSize;
    const filter = state.configuration_overview.actualData.filter;
    let parameters = {page: page, size: pageSize};
    if (filter !== null && filter.items && filter.items.length > 0) {
        parameters = {...parameters, [filter.items[0].field]: filter.items[0].value};
    }
    return parameters;
};

const getConfigurationsLogic = async (getState, rejectWithValue) => {
    const state = getState() as RootState;
    const mode = state.configuration_mode.mode;
    const parameters = createConfigurationParameters(state);
    const response = await Configurations_overviewService.getConfigurations(parameters, mode);
    return handleResponseErrors(response, "Configurations", rejectWithValue);
};

export const getConfigurations = createAsyncThunk(
    "configuration/configuration_overview/getConfigurations",
    async (_, {getState, rejectWithValue}) => {
        return await getConfigurationsLogic(getState, rejectWithValue);
    }
);

export const updateConfigurations = createAsyncThunk(
    "configuration/configuration_overview/updateConfigurations",
    async (_, {getState, rejectWithValue}) => {
        return await getConfigurationsLogic(getState, rejectWithValue);
    }
);
