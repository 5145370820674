import React from "react";
import {ErrorBoundary} from "react-error-boundary";
import {ErrorPanelComponent} from "./error_panel.component";

// eslint-disable-next-line
function FallbackComponent({error}) {
    return (
        <ErrorPanelComponent>
            <p>Es trat ein unerwarter Fehler auf.</p>
            {
                // eslint-disable-next-line
                <pre>Fehlertext: {error.message}</pre>
            }
            <em>
                Sollte der Fehler bestehen bleiben, melden Sie sich bitte bei Ihrem
                Administrator.
            </em>
        </ErrorPanelComponent>
    );
}

export interface ExtRouteComponentProps {
    children?: React.ReactNode
}

export const ErrorHandlerComponent: React.FC<ExtRouteComponentProps> = (props) => {
    return (
        <ErrorBoundary FallbackComponent={FallbackComponent}>
            {props.children}
        </ErrorBoundary>
    );
};
