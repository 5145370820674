import {ConfigurationCompletionState} from "./completion.model";
import {doesExist, hasValue} from "../../../../../../services/validation.service";

export const validateState = (state: ConfigurationCompletionState) => {
    // validate input data
    return (doesExist(state.loadedData.configuration) && hasValue(state.actualData.amount) && hasValue(state.actualData.commission) && hasValue(state.actualData.shipmentDate));
};

export const mustCallApi = (state: ConfigurationCompletionState): boolean => {
    // Todo what to prove
    if (state.loadedData.configuration) {
        return true;
    }
    return true;
};
