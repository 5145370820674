import {createAsyncThunk} from "@reduxjs/toolkit";
import {handleResponseErrors} from "../../../../shared/networking/error_handling";
import {RootState} from "app/redux_store";
import ConfigurationService from "services/configuration.service";

export const getMeasurementDataSheet = createAsyncThunk(
    "configuration/actions/getMeasurementDataSheet",
    async (_, {getState}) => {
        const state = getState() as RootState;

        const tokenResponse = await ConfigurationService.getMeasurementDataSheetToken(state.configuration_actionsmenu.loadedData.configurationId);
        const token = tokenResponse.getData().token;
        window.open(`/api/document/measurementdatasheet/${token}`);

        return null;
    }
);

export const sendMeasurementDataSheet = createAsyncThunk(
    "configuration/actions/sendMeasurementDataSheet",
    async (recipients: string[], {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const response = await ConfigurationService.sendMeasurementDataSheet(state.configuration_actionsmenu.loadedData.configurationId, recipients);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);

export const getOrderConfigurationState = createAsyncThunk(
    "configuration/actions/getOrderConfigurationState",
    async (_, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.getOrderConfigurationValidationState(state.configuration_actionsmenu.loadedData.configurationId, mode);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);

export const resetReadonly = createAsyncThunk(
    "configuration/actions/resetReadonly",
    async (_, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.resetReadonly(state.configuration_actionsmenu.loadedData.configurationId, mode);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);

export const verify = createAsyncThunk(
    "configuration/actions/verify",
    async (_, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const response = await ConfigurationService.verify(state.configuration_actionsmenu.loadedData.configurationId);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);
