export class CommonApiResult {
    success: boolean;
    error: CommonApiResultError;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content: any | null;
}

export class CommonApiResultError extends Error {
    errorCode: number;
    errorSubCode: string;
    message: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    messageParameter: any[];
    system: string;

    constructor(error: CommonApiResultError) {
        super(null);

        this.errorCode = error.errorCode;
        this.errorSubCode = error.errorSubCode;
        this.message = error.message;
        this.messageParameter = error.messageParameter;
        this.system = error.system;

        // 👇️ because we are extending a built-in class
        Object.setPrototypeOf(this, CommonApiResultError.prototype);
    }
}
