import {LoadingState} from "models/loading_state";
import {ApiResult} from "./api_result";
import {CommonApiResultError} from "./common_api_result";
import HttpError from "./http_error";
import {PayloadAction, SerializedError} from "@reduxjs/toolkit";
import {PassApiErrorProvider} from "shared/provider/api_error/api_error.provider";
import i18next from "i18next";
import {PassOrderConfigurationProvider} from "shared/provider/orderconfiguration/orderconfiguration.provider";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleResponseErrors = <T extends ApiResult<T> | any>(
    response: ApiResult<T>,
    entityName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rejectWithValue?: (value: unknown) => any,
    errorMessage?: string): ApiResult<T> => {
    if (!response.isSuccessfull()) {
        if (typeof (errorMessage) !== "undefined" && errorMessage !== null) {
            throw new HttpError(errorMessage, response.getErrorCode());
        }

        if (response.isCustomError()) {
            const apiError = response.getCustomError();

            if (rejectWithValue)
                return rejectWithValue(apiError);

            throw apiError;
        }

        if (response.isServerError()) {
            throw new HttpError(errorMessages.serverError(), response.getErrorCode());
        }

        if (response.isBadRequest()) {
            throw new HttpError(errorMessages.badRequest(), response.getErrorCode());
        }

        if (response.isConflict()) {
            throw new HttpError(errorMessages.dataExists(entityName), response.getErrorCode());
        }

        if (response.isTimeout()) {
            throw new HttpError(errorMessages.timeout(), response.getErrorCode());
        }

        if (response.isUnprocessableEntity()) {
            throw new HttpError(errorMessages.badRequest(), response.getErrorCode());
        }

        if (response.isNotFound()) {
            throw new HttpError(errorMessages.notFound(), response.getErrorCode());
        }

        if (response.isForbidden()) {
            throw new HttpError(errorMessages.forbidden(), response.getErrorCode());
        }

        if (response.isUnauthorized()) {
            throw new HttpError(errorMessages.unauthorized(), response.getErrorCode());
        }

        if (errorMessage === null) {
            throw new HttpError(errorMessages.errorResponse(response.getErrorCode()), response.getErrorCode());
        }
    }

    return response;
};

export const errorMessages = {

    //ToDo Add translation
    errorResponse: (statusCode: string) => i18next.t("ERROR:HTTP_Error")?.replace("${statusCode}", statusCode),
    serverError: () => i18next.t("ERROR:HTTP_500"),
    badRequest: () => i18next.t("ERROR:HTTP_400"),
    unauthorized: () => i18next.t("ERROR:HTTP_401"),
    forbidden: () => i18next.t("ERROR:HTTP_403"),    
    notFound: () => i18next.t("ERROR:HTTP_404"),
    timeout: () => i18next.t("ERROR:HTTP_Timeout"),
    dataExists: (entity: string) => i18next.t("ERROR:HTTP_409")?.replace("${entity}", entity),
};

export const setErrorInState = (loadingState: LoadingState, action: PayloadAction<unknown, string, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    arg: any;
    requestId: string;
    requestStatus: "rejected";
    aborted: boolean;
    condition: boolean;
} & ({
    rejectedWithValue: true;
} | ({
    rejectedWithValue: false;
} & Record<string, unknown>)), SerializedError>): void => {
    loadingState.status = "error";
    loadingState.message = action.error.message;
    const apiError = action.meta.rejectedWithValue ? action.payload as CommonApiResultError : null;
    PassApiErrorProvider.setApiError(apiError);

    if (apiError?.errorCode === 409 && apiError?.errorSubCode === "00000005") // locked
        PassOrderConfigurationProvider.setPendingChanges(false);
};
