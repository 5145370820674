import styled from "styled-components";
import {PrimaryButtonComponent} from "../../../../shared/components/buttons/primary_button.component";
import {ContentContainer} from "../../../../shared/components/container/ContentContainer";

export const BackToConfigurationOverviewButton = styled(PrimaryButtonComponent)`
  position: absolute;
  right: 0;
  z-index: 1;
`;

export const ConfigurationProductionOrderContentContainer = styled(ContentContainer)`
  position: relative;
`;
