import React, {useEffect, useState} from "react";
import Tooltip, {TooltipProps} from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import {useAppDispatch, useAppSelector} from "../../../../app/hook";
import {AppDispatch} from "../../../../app/redux_store";
import {styled, tooltipClasses} from "@mui/material";
import i18next, {t} from "i18next";
import colors from "../../../../shared/style/colors";
import {resetEventsLoadedData, setConfigurationId} from "../../configuration_events/redux/configurations_events.slice";
import {getEvents} from "../../configuration_events/redux/configurations_events.thunks";
import {formatDateTime} from "../../../../shared/date/formatting";

const StyledTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "none",
        fontSize: "14px",
        backgroundColor: colors.mediDarkGray
    },
});

const StyledInfoIcon = styled(InfoIcon)({
    color: colors.mediMagenta,
    marginLeft: "15px",
    marginBottom: "4px"
});

const StyledRow = styled("div")({
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr", // Three equal columns
    gap: "16px", // Space between grid items
    alignItems: "center", // Align items vertically in the center
    marginBottom: "8px", // Add some space between rows
});


interface EventsInfoIconProps {
    orderConfigurationId: string
}

const EventsInfoIconComponent: React.FC<EventsInfoIconProps> = (props) => {
    const state = useAppSelector((state) => state.configuration_events);
    const [tooltipContent, setTooltipContent] = useState<JSX.Element[]>([]);
    const dispatch: AppDispatch = useAppDispatch();

    const tooltipHeader =
        <h5 key="header">
            <>{t("EventsIconHeader")}</>
        </h5>;

    useEffect(() => {
        dispatch(setConfigurationId(props.orderConfigurationId));
    }, [props.orderConfigurationId]);

    useEffect(() => {
        if (state.actualData?.configurationId) {
            dispatch(getEvents());
        }
        if (state.actualData.configurationId === null) {
            dispatch(resetEventsLoadedData());
        }
    }, [state.actualData.configurationId]);

    useEffect(() => {
        if (state.loadedData.events !== null && state.loadedData.events.data.length > 0) {
            const events = state.loadedData.events.data.map((event, index) => (
                <StyledRow key={index}>
                    <div>{formatDateTime(event.startedAt, i18next.language)}</div>
                    <div>{event.eventTypeText}</div>
                    <div>{event.triggeredBy}</div>
                </StyledRow>
            ));
            setTooltipContent([tooltipHeader, ...events]);
        } else {
            setTooltipContent([]);
        }
    }, [state.loadedData.events]);

    return (
        tooltipContent.length > 0 && (
            <StyledTooltip title={tooltipContent} onOpen={() => { dispatch(getEvents()); }} arrow>
                <StyledInfoIcon/>
            </StyledTooltip>
        )
    );
};

export default EventsInfoIconComponent;
