import {ConfigurationMode} from "../configurations/shared/configurationMode.model";

export const getEndpoint = (mode: ConfigurationMode): string => {
    let endpoint = "";
    if (mode === ConfigurationMode.OrderConfiguration) {
        endpoint = "orderconfiguration";
    } else if (mode === ConfigurationMode.QuotationConfiguration) {
        endpoint = "quotationconfiguration";
    }
    return endpoint;
};
