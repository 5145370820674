export const getConfig = () => {

    const name = process.env.REACT_APP_ENVIRONMENT || "dev";
    const authTenantId = process.env.REACT_APP_AUTH_TENANT_ID || "c5546184-c827-45e1-bdca-78353c329c48";
    const authClientAppId = process.env.REACT_APP_AUTH_CLIENT_APPID || "e0f7b85c-d983-4b1b-9c7e-b84d57563e78";
    const authServerAppId = process.env.REACT_APP_AUTH_SERVER_APPID || "d84171aa-fe9d-40ae-9cc6-80b03128f829";
    const showSwaggerLinks = (process.env.REACT_APP_ENVIRONMENT || "dev") !== "prod";
    const mtmBaseUrl = process.env.REACT_APP_MTM_BASE_URL || "https://dev.mtm.app.medi.de/api";
    const mtmAuthServerAppId = process.env.REACT_APP_MTM_AUTH_SERVER_APPID || "f46a5923-4048-4efc-9bcb-96b2fcb2bbbf";

    return {
        name: name,
        authTenantId: authTenantId,
        authClientAppId: authClientAppId,
        authServerAppId: authServerAppId,
        httpTimeoutMs: 300000,
        showSwaggerLinks: showSwaggerLinks,
        calinga: {
            baseUrl: "https://manage.calinga.io",
            organization: "medi",
            team: "ORS-Service",
            project: {
                checkTypeErrorWarnMessages: "check_type.error_warn_messages",
                additionDescriptions: "addition.description",
            },
            referenceLanguage: "de-DE",
        },
        mtmService:
            {
                baseUrl: mtmBaseUrl,
                authServerAppId: mtmAuthServerAppId
            }
    };
};

export const getConfigName = (): "dev" | "stage" | "prod" => {
    switch (process.env.REACT_APP_ENVIRONMENT) {
        case "prod":
            return "prod";
        case "stage":
            return "stage";
        case "dev":
        default:
            return "dev";
    }
};
