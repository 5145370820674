import styled from "styled-components";
import colors from "../../style/colors";

export const PrimaryButtonComponent = styled.button`
    border-radius: 0;
    border: 1px solid ${colors.mediLightGray};
    color: ${(props) =>
            props.disabled ? colors.mediLightGray : colors.mediGray};
    font-size: 0.9rem;
    text-align: center;
    vertical-align: center;
    background-color: white;
    padding: 10px 20px 10px 20px;

    :focus,
    :hover,
    :active {
        border-radius: 0;
        border: 1px solid ${(props) =>
                props.disabled ? colors.mediLightGray : colors.mediMagenta};
        outline-color: rgb(72, 88, 106);
        outline-offset: 0px;
        outline-style: none;
        outline-width: 0px;
    }
`;
