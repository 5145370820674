import {forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import IConfiguration from "models/configurations/configuration";
import {mTMAuthorisationService} from "services/authenticationmtm.service";
import "@medi-mtm/components";
import {getConfigName} from "../../../../../../services/config.service";
import {AttributeCategory} from "../models/request";
import { AdditionsEvaluatedEvent, AdditionsLoadedEvent, ComponentDataEvent, FailedDataEvent } from "@medi-mtm/components";

export type AdditionsWrapperComponentProps = {
    mtmApiEndpointBase: string;
    configuration: IConfiguration;
    resetRestoreAdditions: (canExecute: boolean) => void;
    updateAdditionsEvaluating: () => void;
    updateAdditionsEvaluationFailed: (details: CustomEvent<FailedDataEvent>) => void;
    updateAdditionsEvaluated: (details: CustomEvent<AdditionsEvaluatedEvent>) => void;
    updateAdditionsLoading: () => void;
    updateAdditionsLoadingFailed: (details: CustomEvent<FailedDataEvent>) => void;
    updateAdditionsLoaded: (details: CustomEvent<AdditionsLoadedEvent>) => void;
    changeEvaluationStateByEvaluationReceivedAdditions: () => void;
    updateGetComponentState: (details: CustomEvent<ComponentDataEvent>) => void;
};

// eslint-disable-next-line
export const AdditionsWrapperComponent = forwardRef<any, AdditionsWrapperComponentProps>((props, ref) => {

    // eslint-disable-next-line
    const additionsRef = useRef() as any;

    useImperativeHandle(ref, () => ({
        evaluateAdditions() {
            if (additionsRef.current !== null) {
                // eslint-disable-next-line
                const component = (additionsRef.current as any);
                component.evaluateAdditions();
            }
        }
    }));

    useEffect(() => {
        // eslint-disable-next-line
        const component = (additionsRef.current as any);
        if (component) {
            props.resetRestoreAdditions(true);
            const initComponent = async () => {
                const token = await mTMAuthorisationService.getAccessTokenMTMHeader();

                component.init({
                    AuthMode: "token",
                    Token: token,
                    endpoints: {
                        getAdditions: `${props.mtmApiEndpointBase}/productConfiguration/$1/additions`,
                        evaluateAdditions: `${props.mtmApiEndpointBase}/productConfiguration/additions`,
                        downloadPositionDocument: `${props.mtmApiEndpointBase}/document/positiondocument/$1/$2`,
                        translations: `${props.mtmApiEndpointBase}/localization/{{ns}}?language={{lng}}`
                    },
                    Environment: getConfigName()
                });
                component.tokenRefreshOptions = { callback: () => { return mTMAuthorisationService.getAccessTokenMTMHeader(); } };
                component.setProductConfigurationInformation(props.configuration.productConfigurationId, props.configuration.productConfigurationRunId);
                component.setCountryId("DE");
                component.setQualityId(props.configuration.productLine);

                const attributes = props.configuration.productProperties.map((prop) => {
                    return {
                        categoryErpId: prop.displayName,
                        attributeId: prop.data
                    } as AttributeCategory;
                });

                component.setAttributeCategories(attributes);
                component.load();
            };
            initComponent();
        }
    }, [props.configuration.productConfigurationId]);

    useEffect(() => {
        // eslint-disable-next-line
        const component = (additionsRef.current as any);

        const additionsEvaluatingChangedHandler = () => {
            props.updateAdditionsEvaluating();
        };

        const additionsEvaluationFailedChangedHandler = (details) => {
            props.updateAdditionsEvaluationFailed(details);
        };

        const additionsEvaluatedChangedHandler = (details: CustomEvent<AdditionsEvaluatedEvent>) => {
            props.updateAdditionsEvaluated(details);
        };

        const additionsLoadingChangedHandler = () => {
            props.updateAdditionsLoading();
        };

        const additionsLoadingFailedChangedHandler = (details) => {
            props.updateAdditionsLoadingFailed(details);
        };

        const additionsLoadedChangedHandler = (details) => {
            props.updateAdditionsLoaded(details);
        };

        const getComponentStateHandler = (details) => {
            props.updateGetComponentState(details);
        };

        if (component !== null) {
            component.addEventListener("additionsEvaluating", additionsEvaluatingChangedHandler);
            component.addEventListener("additionsEvaluationFailed", additionsEvaluationFailedChangedHandler);
            component.addEventListener("additionsEvaluated", additionsEvaluatedChangedHandler);
            component.addEventListener("additionsLoading", additionsLoadingChangedHandler);
            component.addEventListener("additionsLoadingFailed", additionsLoadingFailedChangedHandler);
            component.addEventListener("additionsLoaded", additionsLoadedChangedHandler);
            component.addEventListener("getComponentState", getComponentStateHandler);
        }
        return () => {
            if (component !== null) {
                component.removeEventListener("additionsEvaluating", additionsEvaluatingChangedHandler);
                component.removeEventListener("additionsEvaluationFailed", additionsEvaluationFailedChangedHandler);
                component.removeEventListener("additionsEvaluated", additionsEvaluatedChangedHandler);
                component.removeEventListener("additionsLoading", additionsLoadingChangedHandler);
                component.removeEventListener("additionsLoadingFailed", additionsLoadingFailedChangedHandler);
                component.removeEventListener("additionsLoaded", additionsLoadedChangedHandler);
                component.removeEventListener("getComponentState", getComponentStateHandler);
            }
        };
    }, []);

    return (<div>
        <x-additions
            ref={additionsRef}
        ></x-additions>
    </div>);
});

AdditionsWrapperComponent.displayName = "AdditionsWrapper";
