import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {ComponentDataEvent, FailedDataEvent, MassMaskEvaluatedEvent, MassMaskLoadedEvent, Massmask} from "@medi-mtm/components";
import {mTMAuthorisationService} from "services/authenticationmtm.service";
import {SideSelectionEnum} from "models/side_selection_enum.enum";
import {getConfig, getConfigName} from "services/config.service";
import {ConfigurationMode} from "../../../../configurationMode.model";

export type MassmaskWrapperComponentProps = {
    mainProductLineErpId: string;
    bodyAreaErpId: string;
    articleTypeErpId: string;
    qualityErpId?: string;
    footOptionErpId?: number;
    productConfigurationId?: string;
    productConfigurationRunId?: number;
    selectedSide: SideSelectionEnum;
    processType: ConfigurationMode;
    updateShowEvaluationResultInMassmask: boolean;
    updateMassMaskEvaluating: () => void;
    updateMassMaskEvaluationFailed: (details: CustomEvent<FailedDataEvent>) => void;
    updateMassMaskEvaluated: (details: CustomEvent<MassMaskEvaluatedEvent>) => void;
    updateMassMaskLoading: () => void;
    updateMassMaskLoadingFailed: (details: CustomEvent<FailedDataEvent>) => void;
    updateMassMaskLoaded: (details: CustomEvent<MassMaskLoadedEvent>) => void;
    updateGetComponentState: (details: CustomEvent<ComponentDataEvent>) => void;
    mtmApiEndpointBase: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MassmaskWrapperComponent = forwardRef<any, MassmaskWrapperComponentProps>((props, ref) => {
    const massmaskRef = useRef();
    const [displayEvaluationResult, setDisplayEvaluationResult] = useState(false);
    useImperativeHandle(ref, () => ({
        evaluate() {
            if (massmaskRef.current !== null) {
                const massmask = (massmaskRef.current as Massmask);
                massmask.evaluate();
            }
        }
    }));

    useEffect(() => {
        if (massmaskRef.current !== null) {
            const massmask = (massmaskRef.current as Massmask);
            if (props.updateShowEvaluationResultInMassmask) {
                massmask.showEvaluationResult();
            } else {
                massmask.hideEvaluationResult();
            }
        }
    }, [props.qualityErpId, displayEvaluationResult]);

    useEffect(() => {
        const massmask = (massmaskRef.current as Massmask);

        const massMaskEvaluatingChangedHandler = () => {
            props.updateMassMaskEvaluating();
        };

        const massMaskEvaluationFailedChangedHandler = (details) => {
            props.updateMassMaskEvaluationFailed(details);
        };

        const massMaskEvaluatedChangedHandler = (details) => {
            setDisplayEvaluationResult(true);
            props.updateMassMaskEvaluated(details);
        };
        const massMaskLoadingChangedHandler = () => {
            props.updateMassMaskLoading();
        };

        const massMaskLoadingFailedChangedHandler = (details) => {
            props.updateMassMaskLoadingFailed(details);
        };

        const massMaskLoadedChangedHandler = (details) => {
            props.updateMassMaskLoaded(details);
        };

        const getComponentStateChangedHandler = (details) => {
            props.updateGetComponentState(details);
        };


        if (massmask !== null) {                       
            massmask.addEventListener("massMaskEvaluating", massMaskEvaluatingChangedHandler);
            massmask.addEventListener("massMaskEvaluationFailed", massMaskEvaluationFailedChangedHandler);
            massmask.addEventListener("massMaskEvaluated", massMaskEvaluatedChangedHandler);
            massmask.addEventListener("massMaskLoading", massMaskLoadingChangedHandler);
            massmask.addEventListener("massMaskLoadingFailed", massMaskLoadingFailedChangedHandler);
            massmask.addEventListener("massMaskLoaded", massMaskLoadedChangedHandler);
            massmask.addEventListener("getComponentState", getComponentStateChangedHandler);
        }
        return () => {
            if (massmask !== null) {               
                massmask.removeEventListener("massMaskEvaluating", massMaskEvaluatingChangedHandler);
                massmask.removeEventListener("massMaskEvaluationFailed", massMaskEvaluationFailedChangedHandler);
                massmask.removeEventListener("massMaskEvaluated", massMaskEvaluatedChangedHandler);
                massmask.removeEventListener("massMaskLoading", massMaskLoadingChangedHandler);
                massmask.removeEventListener("massMaskLoadingFailed", massMaskLoadingFailedChangedHandler);
                massmask.removeEventListener("massMaskLoaded", massMaskLoadedChangedHandler);
                massmask.removeEventListener("getComponentState", getComponentStateChangedHandler);
            }
        };
    }, []);

    useEffect(() => {
        const massmask = (massmaskRef.current as Massmask);

        if (massmask !== null) {
            const initComponent = async () => {
                const token = await mTMAuthorisationService.getAccessTokenMTMHeader();

                massmask.init({
                    AuthMode: "token",
                    Token: token,
                    Environment: getConfigName(),
                    endpoints: {
                        getMassmask: `${props.mtmApiEndpointBase}/productconfiguration/massmask`,
                        evaluateMassmask: `${props.mtmApiEndpointBase}/productconfiguration`,
                        translations: `${props.mtmApiEndpointBase}/localization/{{ns}}?language={{lng}}`
                    }
                });
                massmask.tokenRefreshOptions = { callback: () => { return mTMAuthorisationService.getAccessTokenMTMHeader(); } };
                massmask.setDiagnoseModeEnabled(true);
                if (props.productConfigurationId)
                    massmask.setProductConfigurationId(props.productConfigurationId);

                if (props.productConfigurationRunId)
                    massmask.setProductConfigurationRunId(props.productConfigurationRunId);

                massmask.setMainProductLineErpId(props.mainProductLineErpId);
                massmask.setFootType(props.footOptionErpId);
                massmask.setBodyAreaErpId(props.bodyAreaErpId);
                massmask.setSelectedSide(getStringofEnum(props.selectedSide));
                massmask.setArticleTypeErpId(props.articleTypeErpId);
                if (props.qualityErpId) {
                    massmask.setQualityErpId(props.qualityErpId);
                }
                massmask.setProcessType(getStringofProcessTypeEnum(props.processType));
                massmask.setSystem(`ORS ${getConfig().name}`);
                massmask.enableMeasurementAuthors();
                massmask.load();
            };

            initComponent();
        }
    }, [props.articleTypeErpId, props.bodyAreaErpId, props.footOptionErpId, props.mainProductLineErpId, props.qualityErpId, props.selectedSide, props.productConfigurationId, props.productConfigurationRunId]);

    useEffect(() => {
        const massmask = (massmaskRef.current as Massmask);
        if (props.productConfigurationRunId) {
            massmask.setProductConfigurationRunId(props.productConfigurationRunId);
        } else {
            massmask.setProductConfigurationRunId(-1);
        }
    }, [props.productConfigurationRunId]);

    const getStringofEnum = (enumValue: SideSelectionEnum) => {
        if (enumValue === SideSelectionEnum.LeftRight) {
            return "all";
        } else if (enumValue === SideSelectionEnum.Left) {
            return "left";
        } else if (enumValue === SideSelectionEnum.Right) {
            return "right";
        }

        return "undefined";
    };

    const getStringofProcessTypeEnum = (enumValue: ConfigurationMode) => {
        if (enumValue === ConfigurationMode.QuotationConfiguration) {
            return "quotation";
        } else {
            return "order";
        }
    };

    return (
        <>
            <x-massmask
                ref={massmaskRef}
            ></x-massmask>
        </>
    );

});

MassmaskWrapperComponent.displayName = "MassmaskWrapperComponent";
