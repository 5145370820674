import React from "react";
import { Route, Routes } from "react-router-dom";
import {AdminBreadcrumbs} from "./admin.breadcrumbs";
import * as style from "../shared/breadcrumbs/breadcrumbs_root.style";
import {Container} from "reactstrap";
import {AdminOverviewComponent} from "./overview/AdminOverviewComponent";

export const AdminComponent = () => {
    return (
        <>
            <style.BreadcrumbArea>
                <Container>
                    <AdminBreadcrumbs/>
                </Container>
            </style.BreadcrumbArea>
            <Routes>
                <Route element={<AdminOverviewComponent/>} path="/" />
            </Routes>
        </>
    );
};

