import React from "react";
import WarningIcon from "@mui/icons-material/Warning";
import styled from "styled-components";
import {PrimaryButtonComponent} from "../buttons/primary_button.component";
import {FlexBox, Right} from "../../style/alignment";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

interface IErrorPanelProps {
    title?: string;
    message?: string;
    children: React.ReactNode
}

const IconWrapper = styled.span`
  margin-left: auto;
`;

export const ErrorPanelComponent: React.FC<IErrorPanelProps> = ({title, children}) => {
    const refreshPage = () => {
        window.location.reload();
    };

    return (
        <Card>
            {title ? (
                <CardHeader avatar={<IconWrapper>
                    <WarningIcon/>
                </IconWrapper>}
                            title="Achtung"
                            subheader={title}
                />
            ) : (
                ""
            )}{" "}
            <CardContent>
                {children}

            </CardContent>
            <CardActions>
                <FlexBox>
                    <Right>
                        <PrimaryButtonComponent onClick={refreshPage}>Seite neuladen</PrimaryButtonComponent>
                    </Right>
                </FlexBox>
            </CardActions>
        </Card>
    );
};
