import React, { useEffect } from "react";
import {ContentContainer} from "../../../../shared/components/container/ContentContainer";
import ConfigurationOverviewComponent
    from "../../../shared/configuration_overview/components/configuration_overview.component";
import * as styleBreadcrumbs from "../../../../shared/breadcrumbs/breadcrumbs_root.style";
import {Container} from "reactstrap";
import {AppDispatch} from "../../../../app/redux_store";
import {useAppDispatch} from "../../../../app/hook";
import {setBasePath, setMode} from "../../../shared/configurationmodeSlice";
import {ConfigurationMode} from "../../../shared/configurationMode.model";
import {getBasePath} from "../../../shared/basePathGetter";
import {quotationconfiguration_overviewColumns} from "./columndefinitions/quotationconfiguration_overview.columns";
import {QuotationConfigurationsBreadcrumbs} from "../../quotationconfigurations.breadcrumbs";

export const QuotationConfigurationOverviewComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setMode(ConfigurationMode.QuotationConfiguration));
        dispatch(setBasePath(getBasePath()));
    }, []);

    return (
        <>
            <styleBreadcrumbs.BreadcrumbArea>
                <Container>
                    <QuotationConfigurationsBreadcrumbs/>
                </Container>
            </styleBreadcrumbs.BreadcrumbArea>
            <ContentContainer>
                <ConfigurationOverviewComponent columnDefinitions={quotationconfiguration_overviewColumns}/>
            </ContentContainer>
        </>
    );
};
