import React, {useEffect, useState} from "react";
import {Button, Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {CommentInput} from "./comment_input.component";
import {useAppDispatch, useAppSelector} from "../../../../app/hook";
import {Comment} from "./comment.component";
import {deleteComment, editComment, getComments, saveNewComment} from "../redux/configurations_comments.thunk";
import {AppDispatch} from "../../../../app/redux_store";
import {setCommentScope, setConfigurationId,} from "../redux/configurations_comments.slice";
import {CommentScope} from "../comment_scope_enum";
import {CommentDateModel} from "../redux/configurations_comments.model";
import ErrorDialog from "../../../../shared/components/error_dialog/error_dialog.component";
import {ErrorDialogButtons} from "shared/components/error_dialog/error_dialog.helper";
import {useApiError} from "shared/provider/api_error/api_error.provider";

interface CommentSectionProps {
    configurationId: string
    commentScope: CommentScope
    marginTop
}

export const CommentSection: React.FC<CommentSectionProps> = ({configurationId, commentScope, marginTop}) => {
    const {t} = useTranslation();
    const [comments, setComments] = useState<CommentDateModel[]>([]);
    const [isCommentsHistoryExpanded, setIsCommentsHistoryExpanded] = useState(false);
    const state = useAppSelector((state) => state.configuration_comments);
    const [displayErrorDialog, setDisplayErrorDialog] = useState({isVisible: false, errorMessage: ""});
    const dispatch: AppDispatch = useAppDispatch();
    const {apiError} = useApiError();

    const showError = (message: string) => {
        setDisplayErrorDialog({
            isVisible: true,
            errorMessage: message
        });
    };

    const hideError = () => {
        setDisplayErrorDialog({
            isVisible: false,
            errorMessage: "",
        });
    };

    useEffect(() => {
        dispatch(setConfigurationId(configurationId));
        dispatch(setCommentScope(commentScope));
    }, []);

    useEffect(() => {
        setComments(state.loadedData.comments);
    }, [state.loadedData.comments]);

    useEffect(() => {
        if (configurationId !== undefined && configurationId !== null) {
            dispatch(getComments());
        }
    }, [configurationId]);

    useEffect(() => {
        if (state.query.commentsQuery.status === "error") {
            showError(state.query.commentsQuery.message);
        }
    }, [state.query.commentsQuery.status]);


    const handleDeleteComment = (commentId: string) => {
        const updatedComments = comments.filter(comment => comment.id !== commentId);
        setComments(updatedComments);
        dispatch(deleteComment(commentId));
    };

    const handleSaveComment = () => {
        dispatch(saveNewComment()).then(() => {
            setTimeout(() => {
                dispatch(getComments());
            }, 100);
        });
    };

    const handleEditComment = (commentId: string) => {
        dispatch(editComment(commentId)).then(() => {
            setTimeout(() => {
                dispatch(getComments());
            }, 100);
        });
    };

    if (displayErrorDialog.isVisible) {
        return (
            <ErrorDialog
                message={displayErrorDialog.errorMessage}
                apiError={apiError}
                buttons={ErrorDialogButtons.getButtonsByError(apiError, () => {
                    dispatch(hideError);
                })}
            />
        );
    } else {
        return (
            <>
                <Grid container columnSpacing={5}>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        style={{marginTop: marginTop}}>
                        <h3>{t("CommentsHeaderInputLabel")}</h3>
                        <CommentInput handleSaveNewComment={handleSaveComment}/>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                        style={{marginTop: marginTop}}>
                        <h3>{t("CommentsHeaderHistoryLabel")}</h3>
                        {
                            comments.map((comment, index) => {
                                if (!comment.deleted && (isCommentsHistoryExpanded || index === 0)) {
                                    return (
                                        <Comment
                                            key={comment.id} {...comment} handleDeleteComment={handleDeleteComment}
                                            handleEditComment={handleEditComment}/>
                                    );
                                }
                            })
                        }
                        <Grid container justifyContent={"center"}>
                            <Button onClick={() => setIsCommentsHistoryExpanded(!isCommentsHistoryExpanded)}>
                                {isCommentsHistoryExpanded ? t("CommentHistoryCollapseButton") : t("CommentHistoryExpandButton")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
};
