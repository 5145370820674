import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../app/hook";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {resetState} from "../redux/customerdata.slice";
import {AppDispatch} from "../../../../app/redux_store";
import {LoadingIndicatorComponent} from "../../../../shared/components/loading_indicator/loading_indicator.component";
import ErrorDialog from "../../../../shared/components/error_dialog/error_dialog.component";

const Label = styled.div`
  font-weight: bold;
  margin-right: 5px;
  display: inline;
`;

const CustomerDataComponent: React.FC = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const customerData = useAppSelector((state) => state.configuration_customerData);
    const editorStep = useAppSelector((state) => state.configuration_editor).loadedData.step;
    const {t} = useTranslation();
    const [displayErrorDialog, setDisplayErrorDialog] = useState({isErrorDialogDisplayed: false, errorMessage: ""});
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        if (editorStep === undefined || editorStep === null || editorStep.value <= 0)
            dispatch(resetState());
    }, [dispatch, editorStep]);

    useEffect(() => {
        if (customerData.query.get.status === "error") {
            //setDisplayErrorDialog({isErrorDialogDisplayed: true, errorMessage: customerData.query.get.message});
            setIsLoading(false);
        } else if (customerData.query.get.status === "pending" || customerData.query.get.status === "idle") {
            setIsLoading(true);
        } else if (customerData.query.get.status === "success") {
            setIsLoading(false);
        }
    }, [customerData.query.get.status]);


    if (displayErrorDialog.isErrorDialogDisplayed) {
        return (
            <ErrorDialog
                message={displayErrorDialog.errorMessage}
                handleOnClose={() => setDisplayErrorDialog({isErrorDialogDisplayed: false, errorMessage: ""})}
            />
        );
    }

    if (isLoading) {
        return <LoadingIndicatorComponent/>;
    } else if (customerData === undefined || customerData.loadedData === undefined) {
        return (
            <div>
                <Label>
                    {`${t("OrderConfigurationHeaderCustomerData")}:`}
                </Label>
                <div style={{display: "inline"}}>
                    {`${t("Unavailable")}`}
                </div>
            </div>
        );
    } else {
        {
            return (
                <div>
                    <Label>
                        {`${t("OrderConfigurationHeaderCustomerData")}:`}
                    </Label>
                    <div style={{display: "inline"}}>
                        {`${customerData.loadedData.groupCompanyNumber} `}
                        &ndash;
                        {` ${customerData.loadedData.customerNumber} `}
                        {customerData.loadedData.deliveryAddressNumber ? `/ ${customerData.loadedData.deliveryAddressNumber} ` : " "}
                        {`${customerData.loadedData.companyName} `}
                        {`${customerData.loadedData.familyName}, `}
                        {`${customerData.loadedData.addressLocality}`}
                    </div>
                </div>
            );
        }
    }
};

export default CustomerDataComponent;
