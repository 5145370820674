import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {isNotUndefinied} from "services/validation.service";
import {ConfigurationStepEnum, ConfigurationStepValues} from "../models/configuration_step.enum";
import {StepDescriptor} from "../models/step_descriptor.model";
import {EditorState} from "./editor.model";
import {getConfiguration, getOrderConfigurationValidationState, navigateTo} from "./editor.thunks";
import {setErrorInState} from "shared/networking/error_handling";
import {PassOrderConfigurationProvider} from "shared/provider/orderconfiguration/orderconfiguration.provider";

const initialState: EditorState = {
    loadedData: {
        configurationId: null,
        productConfigurationId: null,
        productConfigurationRunId: null,
        step: ConfigurationStepValues[ConfigurationStepEnum.Create],
        orderConfigurationState: null,
    },
    query: {
        get: {
            status: "idle",
            canExecute: false
        }
    },
    command: {
        navigateTo: {
            status: "idle",
            canExecute: false
        }
    },
    validateProductConfiguration: {
        get: {
            status: "idle",
            canExecute: false
        }
    },
};

export const editorSlice = createSlice({
    name: "configuration/editor",
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.query = initialState.query;
            state.command = initialState.command;
            state.validateProductConfiguration = initialState.validateProductConfiguration;
            PassOrderConfigurationProvider.setReadonly(false);
        },
        resetNavigateTo: (state) => {
            state.command.navigateTo.status = "idle";
        },
        setConfigurationId: (state, action: PayloadAction<string>) => {
            state.loadedData.configurationId = action.payload;
        },
        setProductOrderConfigurationId: (state, action: PayloadAction<string>) => {
            state.loadedData.productConfigurationId = action.payload;
        },
        setProductOrderConfigurationRunId: (state, action: PayloadAction<number>) => {
            state.loadedData.productConfigurationRunId = action.payload;
        },
        setStep: (state, action: PayloadAction<StepDescriptor>) => {
            state.loadedData.step = action.payload;
        },
        validateProductConfiguration: (state) => {
            state.validateProductConfiguration.get.canExecute = true;
        },
    }, extraReducers: (builder) => {
        // getConfiguration
        builder.addCase(getConfiguration.pending, (state) => {
            state.query.get.status = "pending";
            state.query.get.canExecute = false;
        }).addCase(getConfiguration.rejected, (state, action) => {
            setErrorInState(state.query.get, action);
        }).addCase(getConfiguration.fulfilled, (state, action) => {
            state.query.get.status = "success";
            const data = action.payload.getData();
            state.loadedData.configurationId = data.id;
            state.loadedData.productConfigurationId = data.productConfigurationId;
            state.loadedData.productConfigurationRunId = data.productConfigurationRunId;

            state.command.navigateTo.status = "pending";
            state.command.navigateTo.canExecute = false;
        })

            // navigateTo
            .addCase(navigateTo.pending, (state) => {
                state.command.navigateTo.status = "pending";
                state.command.navigateTo.canExecute = false;
            }).addCase(navigateTo.fulfilled, (state, action) => {
            const navigationInfo = action.payload;
            if (navigationInfo.configurationId !== state.loadedData.configurationId) {
                state.loadedData.configurationId = navigationInfo.configurationId;
            }
            if (isNotUndefinied(navigationInfo.productConfigurationId)) {
                state.loadedData.productConfigurationId = navigationInfo.productConfigurationId;
            }
            if (isNotUndefinied(navigationInfo.productConfigurationRunId)) {
                state.loadedData.productConfigurationRunId = navigationInfo.productConfigurationRunId;
            }
            state.loadedData.step = navigationInfo.targetStep;
            state.command.navigateTo.status = "success";
        })
        .addCase(getOrderConfigurationValidationState.pending, (state) => {
            state.validateProductConfiguration.get.status = "pending";
        })
        .addCase(getOrderConfigurationValidationState.rejected, (state, action) => {
            setErrorInState(state.validateProductConfiguration.get, action);
            state.loadedData.orderConfigurationState = null;
        })
        .addCase(getOrderConfigurationValidationState.fulfilled, (state, action) => {
            state.loadedData.orderConfigurationState = action.payload.getData();
            state.validateProductConfiguration.get.status = "success";
            PassOrderConfigurationProvider.setReadonly(state.loadedData.orderConfigurationState.readonly);
        });
    }
});

export const {
    resetState,
    resetNavigateTo,
    setConfigurationId,
    setProductOrderConfigurationId,
    setProductOrderConfigurationRunId,
    setStep,
    validateProductConfiguration
} = editorSlice.actions;

export default editorSlice.reducer;
