import React, {Fragment} from "react";
import {BreadcrumbRoute} from "../models/breadcrumb_route";
import {useTranslation} from "react-i18next";
import { Link, Params, useMatch, useParams } from "react-router-dom";


interface BreadcrumbsComponentProps {
    breadcrumbs: BreadcrumbRoute[];
}

export const BreadcrumbsComponent =({breadcrumbs}: BreadcrumbsComponentProps) => {
    const {t} = useTranslation();

    // const renderUrl= <T,> (urlTemplate: string, matchProps: MatchRenderProps<T>): string => {
    const renderUrl= (urlTemplate: string, params: Readonly<Params<string>>): string => {
        //console.log("url start");
        const parameterValues = params ? urlTemplate
            .split("/")
            .filter(urlTemplatePart => urlTemplatePart.startsWith(":"))
            .map(parameter => parameter.substr(1))
            .map(parameter => {
                return {parameter, value: params[parameter]};
                // todo remove
                //return {parameter, value: matchProps.match[parameter]};
            }): [];

        let url = urlTemplate;
        for (let i = 0; i < parameterValues.length; i++) {
            url = url.replace(`:${parameterValues[i].parameter}`,parameterValues[i].value); //parameterValues[i].value);
        }
        //console.log("url ", url);
        return url;
    }

    const match = (breadcrumb: BreadcrumbRoute,index: number) => {
        const match = useMatch(breadcrumb.route);
        const params = useParams();

        return (<Fragment key={index}>
            {match ? <Fragment key={`match_${index}`}>
                {breadcrumb.parts.map((part, index) => {
                    return <Fragment key={index}>
                        {<>
                            {part.url ?
                                <Link key={index} 
                                    to={renderUrl(part.url, params)}>{t(part.title)}
                                </Link> :
                                <>{t(part.title)}
                        </>}
                        {(index < breadcrumb.parts.length - 1) ?
                            <> / </> : <></>}
                        </>}
                    </Fragment>;})}
            </Fragment> : <></>}
        </Fragment>);
    }

    return (
         <>{
            breadcrumbs.map((breadcrumb, index) => {
                return match(breadcrumb, index)
            })
                //     <Match<T> key={index} path={breadcrumb.route}>
                //     {props => (
                //         <Fragment key={index}>
                //         {
                //             // eslint-disable-next-line
                //             props.match ? (
                //             <Fragment key={`match_${index}`}>
                //                 {breadcrumb.parts.map((part, index) => {
                //                     return <Fragment key={index}>
                //                         {<>
                //                             {part.url ?
                //                                 <Link key={index} 
                //                                     to={renderUrl<T>(part.url, props)}>{t(part.title)}
                //                                 </Link> :
                //                                 <>{t(part.title)}
                //                         </>}
                //                         {(index < breadcrumb.parts.length - 1) ?
                //                             <> / </> : <></>}
                //                         </>}
                //                     </Fragment>;})}
                //                 </Fragment>) :
                //             <></>}
                //         </Fragment>
                //     )}
                // </Match>
        }</>
    );
};

// function match({ path, children }) {
//     let match = useMatch(path);
//     let location = useLocation();
//     let navigate = useNavigate();
//     return children({ match, location, navigate });
//   }


// function renderUrl<T>(urlTemplate: string, matchProps: MatchRenderProps<T>): string {
//     const parameterValues = urlTemplate
//         .split("/")
//         .filter(urlTemplatePart => urlTemplatePart.startsWith(":"))
//         .map(parameter => parameter.substr(1))
//         .map(parameter => {
//             return {parameter, value: matchPath(urlTemplate, parameter)};
//             // todo remove
//             //return {parameter, value: matchProps.match[parameter]};
//         });

//     let url = urlTemplate;
//     for (let i = 0; i < parameterValues.length; i++) {
//         url = url.replace(`:${parameterValues[i].parameter}`, parameterValues[i].value);
//     }
//     return url;
// }