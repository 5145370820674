import {ApiResult} from "../shared/networking/api_result";
import HttpService from "../shared/networking/http_service";
import {getConfig} from "./config.service";

class LockServiceAPI extends HttpService {

    public async release(id: string, force: boolean | null = false): Promise<ApiResult<boolean>> {
        if (id !== undefined)
            return this.postBeaconData(`/api/lock/release/${id}`, force);

        return new ApiResult<boolean>();
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const LockService = new LockServiceAPI(TimeoutMilliseconds);

export default LockService;