import {createAsyncThunk} from "@reduxjs/toolkit";
import {handleResponseErrors} from "../../../../shared/networking/error_handling";
import {RootState} from "app/redux_store";
import ConfigurationService from "services/configuration.service";

export const getConfiguration = createAsyncThunk(
    "configuration/salesitemdata/get",
    async (_, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const mode = (getState() as RootState).configuration_mode.mode;

        const response = await ConfigurationService.getConfiguration(
            state.configuration_salesitemdata.configurationId,
            mode
        );
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);
